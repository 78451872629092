const addUserRecord = async (db, data) => {
    const doc = await db.user.insert({
        userId: data.userId,
        userIdText: data.userIdText,
        email: data.email,
        password: data.password,
        mobile: data.mobile,
        firstName: data.firstName,
        lastName: data.lastName,
        productCode: data.productCode,
        userType: data.userType,
        status: data.status,
        subscriptionId: data.subscriptionId,
        subscriptionStartDate: data.subscriptionStartDate,
        subscriptionEndDate: data.subscriptionEndDate,
        subscriptionStatus: data.subscriptionStatus,
        isTrailSubscription: data.isTrailSubscription,
        image: data.image,
        createdOn: data.createdOn,
        updatedOn: data.updatedOn
    });
    return doc.toJSON();
};

const getUserRecordByEmail = async (db, email) => {
    const doc = await db.user.findOne().where('email').eq(email).exec();
    return doc ? doc.toJSON() : null;
};

const deleteUserRecord = async (db, userId) => {
    const doc = await db.user.findOne().where('userId').eq(userId).exec();
    if (doc) {
        await doc.remove();
        return true;
    }
    return false;
};

const updateUserRecord = async (db, userId, newData) => {
    const doc = await db.user.findOne().where('userId').eq(userId).exec();
    if (doc) {
        await doc.update({ $set: newData });
        return doc.toJSON();
    }
    return null;
};

export { addUserRecord, getUserRecordByEmail, deleteUserRecord, updateUserRecord }