import React, { useEffect, useState, useRef } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, isNull, isValidEmail, screenCaptureAnalytics
} from '../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../utils/AppConstants";
import {
    addRestaurantRequest, addRestaurantRequestStatus, countryListRequest,
    stateListRequest, cityListRequest, displaySideBarAct
} from '../../redux/user/user.action'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import CommonButton from "../../components/button/common-button/commonButton";
import CommonInputRow from "../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../components/input/common-input-half/commonInputHalf";
import CommonScreenContent from "../../components/screen/common-screen-content/commonScreenContent";
import ScreenLabel from "../../components/label/screen-label/screenLabel";
import { CurrencyLabel } from "../../utils/CurrencyLabel";
import FormFieldLabel from "../../components/label/form-field-label/formFieldLabel";
import CommonCheckbox from "../../components/common-checkbox/commonCheckbox";
import CommonInputFull from "../../components/input/common-input-full/commonInputFull";
import ButtonRow from "../../components/button/button-row/buttonRow";
import { config } from '../../config'
import Modal from 'react-bootstrap/Modal';

const UpdateProfile = props => {
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [brandName, setBrandName] = useState('')
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [imagePreview, setImagePreview] = useState(null);
    const inputFile = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const [isAddStripePaymentMode, setAddStripePaymentMode] = useState(false)
    const [isStripeAccountAvailable, setStripeAccountAvailable] = useState(false)
    const [stripeAccountId, setStripeAccountId] = useState('')
    const dispatch = useDispatch()
    const { registerSuccess, registerError, registerStatus,
        countryListSuccess, countryListError, stateListSuccess, stateListError,
        cityListSuccess, cityListError, ipLocationSuccess, ipLocationError,
        user, addRestaurantSuccess, addRestaurantError,
        addRestaurantStatus } = useSelector(state => state.user)
    let navigate = useNavigate();
    const [isAddOfflinePaymentMode, setAddOfflinePaymentMode] = useState(false)
    const [isAddPayAtEndPaymentMode, setAddPayAtEndPaymentMode] = useState(false)
    const [showStripeDialog, setShowStripDialog] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'UPDATE_PROFILE',
            screenClass: 'UPDATE_PROFILE'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        setLoading(true)
        dispatch(countryListRequest())
    }, [])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    const isStripeGateway = paymodes => {
        let busDet = paymodes.find(e => e.gatewayType === APP_CONSTANTS.ORDER_PAYMENT_MODE.ONLINE)
        if (!isNull(busDet)) {
            const stripeDet = busDet.optionType.find(otype => otype.type === APP_CONSTANTS.STRIPE)
            if (!isNull(stripeDet)) {
                return true
            } else return false
        } else return false
    }

    useEffect(() => {
        if (addRestaurantStatus === API_CONSTANTS.SUCCESS_STATUS) {
            setLoading(false)
            displaySuccessToast('Restaurant added successfully.')
            if (!isNull(addRestaurantSuccess) && !isNull(addRestaurantSuccess.data) && !isEmpty(addRestaurantSuccess.data.orderPaymentModes) && isStripeGateway(addRestaurantSuccess.data.orderPaymentModes) && !isEmpty(addRestaurantSuccess.data.stripeAccountLink)) {
                setShowStripDialog(true)
            } else {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.dashboard, { replace: true });
            }
            // navigate(ROUTES.dashboard, { replace: true });
            // if (!isNull(addRestaurantSuccess) && !isNull(addRestaurantSuccess.data) && !isNull(addRestaurantSuccess.data.businessDetails) && !isEmpty(addRestaurantSuccess.data.businessDetails.stripeAccountLink)) {
            //     window.open(addRestaurantSuccess.data.businessDetails.stripeAccountLink, '_self')
            // } else {
            //     navigate(ROUTES.signIn, { replace: true });
            // }
        } else if (addRestaurantStatus === API_CONSTANTS.ERROR_STATUS) {
            setLoading(false)
            displayErrorToast(addRestaurantError.message)
        }

        if (addRestaurantStatus.length) {
            dispatch(addRestaurantRequestStatus(''))
        }
    }, [addRestaurantSuccess, addRestaurantError, addRestaurantStatus])

    const handleBusinessNameChange = e => {
        const businessName = e.target.value
        setBusinessName(businessName)
        let businessNameUp = businessName.toUpperCase()
        businessNameUp = businessNameUp.replace(/[^a-zA-Z]/g, "")
        setBrandName(businessNameUp)
    }

    const handleImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
            setImagePreview(URL.createObjectURL(event.target.files[0]))
        } else {
            setImagePath(null)
            setImageName('')
            setImagePreview(null)
        }
    }

    const handleImageClick = () => {
        inputFile.current.click()
    }

    const handleBrandNameChange = e => {
        let brandN = e.target.value
        brandN = brandN.replace(/[^a-zA-Z]/g, "")
        setBrandName(brandN)
    }

    const openStripeLink = () => {
        setShowStripDialog(false)
        navigate(ROUTES.dashboard, { replace: true });
        if (!isNull(addRestaurantSuccess) && !isNull(addRestaurantSuccess.data) && !isEmpty(addRestaurantSuccess.data.stripeAccountLink))
            window.open(addRestaurantSuccess.data.stripeAccountLink, '_self')
    }

    const openDashboard = () => {
        setShowStripDialog(false)
        dispatch(displaySideBarAct(true))
        navigate(ROUTES.dashboard, { replace: true });
    }

    useEffect(() => {
        if (!isNull(ipLocationSuccess)) {
            let selCurrency = CurrencyLabel.find(e => e.code === ipLocationSuccess.currency)
            if (!isNull(selCurrency))
                setSelectedCurrency(selCurrency)
        }
    }, [ipLocationSuccess])

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selCountry = countryListSuccess.find(e => e.iso2 === ipLocationSuccess.country_code)
                if (!isNull(selCountry))
                    handleCountrySelectChange(selCountry)
            }
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selState = stateListSuccess.find(e => e.iso2 === ipLocationSuccess.region_code)
                if (!isNull(selState)) {
                    setStateDropdown(selState);
                    setCityDropdown(null)
                    fetchCityList(countryCode, selState.iso2)
                }
            }
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
            if (!isNull(ipLocationSuccess)) {
                let selCity = cityListSuccess.find(e => e.name === ipLocationSuccess.city)
                if (!isNull(selCity)) {
                    setCityDropdown(selCity);
                }
            }
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const handleSubmitClick = () => {
        if (isEmpty(businessName)) {
            displayErrorToast('Restaurant name cannot be blank', true)
        } else if (isNull(selectedCurrency)) {
            displayErrorToast('Price currency not selected')
        } else if (isAddStripePaymentMode === false && isAddOfflinePaymentMode === false && isAddPayAtEndPaymentMode === false) {
            displayErrorToast('Payment mode not selected', true)
        } else if (isAddStripePaymentMode === true && isStripeAccountAvailable === true && isEmpty(stripeAccountId)) {
            displayErrorToast('Stripe account id cannot be blank', true)
        } else if (isEmpty(brandName)) {
            displayErrorToast('Brand name cannot be blank', true)
        } else if (isNull(countryDropdown)) {
            displayErrorToast('Restaurant country is not selected', true)
        } else if (isNull(stateDropdown)) {
            displayErrorToast('Restaurant state is not selected', true)
        } else if (isNull(cityDropdown)) {
            displayErrorToast('Restaurant city is not selected', true)
        } else {
            setLoading(true)
            const fd = new FormData()
            fd.append('userId', user.data.userDetails.userId)
            fd.append('businessName', businessName)
            fd.append('productCode', user.data.userDetails.productCode)
            fd.append('email', user.data.userDetails.email)
            fd.append('businessCode', brandName)
            fd.append('addHomeLocation', 1)
            if (!isNull(imagePath) && !isEmpty(imageName)) {
                fd.append('image', imagePath, imageName)
            }
            fd.append('allowOnlineOrdering', APP_CONSTANTS.YES)
            fd.append('countryName', countryDropdown.name)
            fd.append('countryCode', countryDropdown.iso2)
            fd.append('stateName', stateDropdown.name)
            fd.append('stateCode', stateDropdown.iso2)
            fd.append('city', cityDropdown.name)
            // fd.append('returnUrl', `${config.loginUrl}`)
            fd.append('returnUrl', `${window.location.href}`)
            fd.append('currencyLabel', selectedCurrency.code)
            fd.append('currencySymbol', selectedCurrency.symbol)
            fd.append('currencyRegion', selectedCurrency.region)
            if (isAddOfflinePaymentMode === true) {
                fd.append('addOfflinePaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addOfflinePaymentMode', APP_CONSTANTS.NO)
            }
            if (isAddPayAtEndPaymentMode === true) {
                fd.append('addPayAtEndPaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addPayAtEndPaymentMode', APP_CONSTANTS.NO)
            }
            if (isAddStripePaymentMode === true) {
                fd.append('addStripePaymentMode', APP_CONSTANTS.YES)
            } else {
                fd.append('addStripePaymentMode', APP_CONSTANTS.NO)
            }
            if (isStripeAccountAvailable === true) {
                fd.append('stripeAccountId', stripeAccountId)
            }
            dispatch(addRestaurantRequest(fd))
        }
    }

    return (
        <>
            <CommonScreenContent>
                <ScreenLabel label='Restaurant Details' />
                <div className="up-form-content">
                    <div className="user-image-parent">
                        {isNull(imagePath) ? (<div className="user-image-container" onClick={() => { handleImageClick() }}>
                            <img src={require('../../assets/logo.png')} alt="restaurant" className="img" />
                        </div>) : <div className="user-image-container" onClick={() => { handleImageClick() }}>
                            <img src={imagePreview} className="img" alt="restaurant" />
                        </div>}
                    </div>
                    <input type="file" ref={inputFile} id="upload-user-photo" onChange={handleImageChange} />
                    <FormFieldLabel className='up-bold-label' label='Restaurant Information' />
                    <CommonInputRow>
                        <CommonInputHalf
                            type="name"
                            id="businessName"
                            placeholder="Restaurant Name"
                            value={businessName}
                            onChange={e => handleBusinessNameChange(e)}
                        />
                        <CommonInputHalf
                            type="name"
                            id="brandName"
                            value={brandName}
                            onChange={e => handleBrandNameChange(e)}
                            placeholder='Brand Name'
                        />
                    </CommonInputRow>
                    <FormFieldLabel className='up-bold-label' label='Payment Information' />
                    {CurrencyLabel && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={selectedCurrency}
                        getOptionLabel={e => `${e.currency} (${e.symbol})`}
                        getOptionValue={e => e}
                        options={CurrencyLabel}
                        placeholder='Select Currency'
                        onChange={e => {
                            setSelectedCurrency(e)
                        }} />}
                    <FormFieldLabel className='mt-2' label='Select accepted order payment mode(s)' />
                    <CommonCheckbox id='offlineMode'
                        label={APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.OFFLINE}
                        value={isAddOfflinePaymentMode}
                        onChange={() => {
                            setAddOfflinePaymentMode(!isAddOfflinePaymentMode)
                        }} />
                    <CommonCheckbox id='payAtEndMode'
                        label={APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.PAY_AT_END}
                        value={isAddPayAtEndPaymentMode}
                        onChange={() => {
                            setAddPayAtEndPaymentMode(!isAddPayAtEndPaymentMode)
                        }} />
                    {/* <CommonCheckbox id='onlineMode'
                        label={APP_CONSTANTS.ORDER_PAYMENT_MODE_LABEL.ONLINE}
                        value={isAddStripePaymentMode}
                        onChange={() => {
                            setAddStripePaymentMode(!isAddStripePaymentMode)
                        }} /> */}
                    {isAddStripePaymentMode === true ? (<CommonCheckbox id='connectStripeAccount'
                        label='Connect existing stripe account?'
                        value={isStripeAccountAvailable}
                        onChange={() => {
                            setStripeAccountAvailable(!isStripeAccountAvailable)
                        }} />) : null}
                    {isStripeAccountAvailable === true ? (
                        <CommonInputFull id='stripeAccountId'
                            onChange={e => setStripeAccountId(e.target.value)}
                            type='text'
                            value={stripeAccountId}
                            placeholder='Stripe Account Id' />) : null}

                    <FormFieldLabel className='up-bold-label' label='Location Information' />
                    {countryListSuccess && <Select
                        menuPlacement="auto"
                        className='select-full-width-no-border'
                        value={countryDropdown}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e}
                        options={countryListSuccess}
                        placeholder='Select Country'
                        onChange={e => {
                            handleCountrySelectChange(e)
                        }} />}
                    <CommonInputRow>
                        {stateListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={stateDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={stateListSuccess}
                            placeholder='Select State'
                            onChange={e => {
                                setStateDropdown(e);
                                setCityDropdown(null)
                                fetchCityList(countryCode, e.iso2)
                            }} />}
                        {cityListSuccess && <Select
                            menuPlacement="auto"
                            className='select-half-width up-select-margin'
                            value={cityDropdown}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e}
                            options={cityListSuccess}
                            placeholder='Select City'
                            onChange={e => {
                                setCityDropdown(e);
                            }} />}
                    </CommonInputRow>
                    <ButtonRow>
                        <CommonButton label='Submit'
                            onClick={() => { handleSubmitClick() }} />
                    </ButtonRow>
                </div>
            </CommonScreenContent>
            <Modal
                onHide={() => { openDashboard() }}
                show={showStripeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Your stripe account setup is incomplete. Kindly update your stripe details to receive order payments.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label='Do it later'
                        onClick={() => { openDashboard() }} />
                    <CommonButton label='Continue'
                        onClick={() => { openStripeLink() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text='Please wait...' />
        </>
    )
}

export default UpdateProfile