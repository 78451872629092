export const businessSchema = {
    title: 'businessSchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "businessIdText",
    properties: {
        businessId: {
            type: 'number',
            "maxLength": 99999
        },
        businessIdText: {
            type: 'string',
            "maxLength": 99999
        },
        userId: {
            type: 'number'
        },
        businessName: {
            type: 'string',
        },
        businessNameArabic: {
            type: 'string',
        },
        businessNameUrdu: {
            type: 'string',
        },
        businessNameSpanish: {
            type: 'string',
        },
        businessNameFrench: {
            type: 'string',
        },
        image: {
            type: 'string',
        },
        status: {
            type: 'number',
        },
        orderPaymentModes: {
            type: 'array',
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};