import { displayConsoleLog, isEmpty } from "../../utils/Utils";

const addProductRecord = async (db, data) => {
    const recordList = await db.product.find().exec()
    let itemId
    if (isEmpty(recordList)) {
        itemId = 1
    } else {
        let mapRecordList = recordList.map(prod => prod.toJSON())
        let lastRecord = mapRecordList[mapRecordList.length - 1]
        itemId = parseFloat(lastRecord.itemId) + 1
    }
    const doc = await db.product.insert({
        itemId: itemId,
        itemIdText: `${itemId}`,
        name: data.name ? data.name : '',
        nameArabic: data.nameArabic ? data.nameArabic : '',
        nameUrdu: data.nameUrdu ? data.nameUrdu : '',
        nameSpanish: data.nameSpanish ? data.nameSpanish : '',
        nameFrench: data.nameFrench ? data.nameFrench : '',
        categoryId: data.categoryId ? parseFloat(data.categoryId) : 0,
        categoryName: data.categoryName ? data.categoryName : '',
        categoryNameArabic: data.categoryNameArabic ? data.categoryNameArabic : '',
        categoryNameUrdu: data.categoryNameUrdu ? data.categoryNameUrdu : '',
        categoryNameSpanish: data.categoryNameSpanish ? data.categoryNameSpanish : '',
        categoryNameFrench: data.categoryNameFrench ? data.categoryNameFrench : '',
        measurementUnit: data.measurementUnit ? data.measurementUnit : '',
        measurementQuantity: data.measurementQuantity ? parseFloat(data.measurementQuantity) : 0,
        points: data.points ? parseFloat(data.points) : 0,
        currencyLabel: data.currencyLabel ? data.currencyLabel : '',
        currencySymbol: data.currencySymbol ? data.currencySymbol : '',
        currencyRegion: data.currencyRegion ? data.currencyRegion : '',
        isOptionBasedPricing: data.isOptionBasedPricing ? data.isOptionBasedPricing : false,
        optionBasedPricing: data.optionBasedPricing ? data.optionBasedPricing : [],
        price: data.price ? parseFloat(data.price.toFixed(2)) : 0,
        discount: data.discount ? parseFloat(data.discount.toFixed(2)) : 0,
        isTaxable: data.isTaxable ? data.isTaxable : false,
        tax: data.tax ? parseFloat(data.tax.toFixed(2)) : 0,
        finalPrice: !data.isOptionBasedPricing ? calculateFinalPrice(data.price, data.discount, data.tax) : 0,
        businessId: data.businessId ? parseFloat(data.businessId) : 0,
        productCode: data.productCode ? data.productCode : '',
        image: data.image ? data.image : '',
        sellingType: data.sellingType ? data.sellingType : '',
        servingType: data.servingType ? data.servingType : '',
        locationId: data.locationId ? data.locationId : [],
        addOnsList: data.addOnsList ? data.addOnsList : [],
        specialInstructions: data.specialInstructions ? data.specialInstructions : '',
        addOns: data.addOns ? data.addOns : [],
        options: data.options ? data.options : [],
        isVeg: data.isVeg ? data.isVeg : false,
        isNonVeg: data.isNonVeg ? data.isNonVeg : false,
        isVegan: data.isVegan ? data.isVegan : false,
        isBreakfast: data.isBreakfast ? data.isBreakfast : false,
        isDinner: data.isDinner ? data.isDinner : false,
        isLunch: data.isLunch ? data.isLunch : false,
        is24Hour: data.is24Hour ? data.is24Hour : false,
        status: 1,
        createdOn: new Date(),
        updatedOn: new Date()
    });
    return doc.toJSON();
};

let calculateFinalPrice = (price, discount = 0, tax = 0) => {
    const fPrice = parseFloat(price).toFixed(2)
    const fDiscount = parseFloat(discount).toFixed(2)
    const fTax = parseFloat(tax).toFixed(2)

    const discountAmount = fDiscount * fPrice / 100;
    const taxAmount = fTax * fPrice / 100
    const priceAfterDiscount = fPrice - discountAmount
    return +priceAfterDiscount + +taxAmount
}

const getProductRecordById = async (db, itemId) => {
    const doc = await db.product.findOne().where('itemId').eq(itemId).exec();
    return doc ? doc.toJSON() : null;
};

const getAllProductRecordByBusinessId = async (db, businessId) => {
    try {
        const doc = await db.product.find().where('businessId').eq(businessId).exec();
        if (doc) {
            return doc.map(prodItem => prodItem.toJSON());
        } else {
            return null
        }
    } catch (err) {
        displayConsoleLog('err', err)
    }

};

const getAllProductRecordByCategoryId = async (db, categoryId) => {
    try {
        const doc = await db.product.find().where('categoryId').eq(categoryId).exec();
        if (doc) {
            return doc.map(prodItem => prodItem.toJSON());
        } else {
            return null
        }
    } catch (err) {
        displayConsoleLog('err', err)
    }

};

const updateProductStatus = async (db, itemId, status) => {
    const doc = await db.product.findOne().where('itemId').eq(itemId).exec();
    if (doc) {
        await doc.update({
            $set: {
                status: status
            }
        });
        return doc.toJSON();
    }
    return null;
};

const updateProductDetail = async (db, itemId, data) => {
    const doc = await db.product.findOne().where('itemId').eq(itemId).exec();
    if (doc) {
        await doc.update({
            $set: {
                name: data.name ? data.name : doc.name,
                nameArabic: data.nameArabic ? data.nameArabic : doc.nameArabic,
                nameUrdu: data.nameUrdu ? data.nameUrdu : doc.nameUrdu,
                nameSpanish: data.nameSpanish ? data.nameSpanish : doc.nameSpanish,
                nameFrench: data.nameFrench ? data.nameFrench : doc.nameFrench,
                categoryId: data.categoryId ? parseFloat(data.categoryId) : parseFloat(doc.categoryId),
                categoryName: data.categoryName ? data.categoryName : doc.categoryName,
                categoryNameArabic: data.categoryNameArabic ? data.categoryNameArabic : doc.categoryNameArabic,
                categoryNameUrdu: data.categoryNameUrdu ? data.categoryNameUrdu : doc.categoryNameUrdu,
                categoryNameSpanish: data.categoryNameSpanish ? data.categoryNameSpanish : doc.categoryNameSpanish,
                categoryNameFrench: data.categoryNameFrench ? data.categoryNameFrench : doc.categoryNameFrench,
                measurementUnit: data.measurementUnit ? data.measurementUnit : doc.measurementUnit,
                measurementQuantity: data.measurementQuantity ? parseFloat(data.measurementQuantity) : parseFloat(doc.measurementQuantity),
                points: data.points ? parseFloat(data.points) : parseFloat(doc.points),
                currencyLabel: data.currencyLabel ? data.currencyLabel : doc.currencyLabel,
                currencySymbol: data.currencySymbol ? data.currencySymbol : doc.currencySymbol,
                currencyRegion: data.currencyRegion ? data.currencyRegion : doc.currencyRegion,
                isOptionBasedPricing: data.isOptionBasedPricing ? data.isOptionBasedPricing : doc.isOptionBasedPricing,
                optionBasedPricing: data.optionBasedPricing ? data.optionBasedPricing : doc.optionBasedPricing,
                price: data.price ? parseFloat(data.price.toFixed(2)) : parseFloat(doc.price.toFixed(2)),
                discount: data.discount ? parseFloat(data.discount.toFixed(2)) : parseFloat(doc.discount.toFixed(2)),
                isTaxable: data.isTaxable ? data.isTaxable : doc.isTaxable,
                tax: data.tax ? parseFloat(data.tax.toFixed(2)) : parseFloat(doc.tax.toFixed(2)),
                finalPrice: !data.isOptionBasedPricing ? calculateFinalPrice(data.price ? data.price : doc.price, data.discount ? data.discount : doc.discount, data.tax ? data.tax : doc.tax) : 0,
                image: data.image ? data.image : doc.image,
                servingType: data.servingType ? data.servingType : doc.servingType,
                addOnsList: data.addOnsList ? data.addOnsList : doc.addOnsList,
                specialInstructions: data.specialInstructions ? data.specialInstructions : doc.specialInstructions,
                addOns: data.addOns ? data.addOns : doc.addOns,
                options: data.options ? data.options : doc.options,
                isVeg: data.isVeg ? data.isVeg : doc.isVeg,
                isNonVeg: data.isNonVeg ? data.isNonVeg : doc.isNonVeg,
                isVegan: data.isVegan ? data.isVegan : doc.isVegan,
                isBreakfast: data.isBreakfast ? data.isBreakfast : doc.isBreakfast,
                isDinner: data.isDinner ? data.isDinner : doc.isDinner,
                isLunch: data.isLunch ? data.isLunch : doc.isLunch,
                is24Hour: data.is24Hour ? data.is24Hour : doc.is24Hour
            }
        });
        return doc.toJSON();
    }
    return null;
};

export {
    addProductRecord, getProductRecordById, getAllProductRecordByBusinessId,
    getAllProductRecordByCategoryId, updateProductStatus, updateProductDetail
}