import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, isEnglishText, isNull,
    isValidEmail, screenCaptureAnalytics
} from '../../utils/Utils'
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../utils/AppConstants";
import {
    userRegistrationRequest, countryListRequest, stateListRequest, cityListRequest
} from '../../redux/user/user.action'
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonButton from "../../components/button/common-button/commonButton";
import CommonInputRow from "../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../components/input/common-input-half/commonInputHalf";
import CommonInputFull from "../../components/input/common-input-full/commonInputFull";
import ButtonRow from "../../components/button/button-row/buttonRow";
import Select from 'react-select'
import { config } from "../../config";
import FormFieldLabel from "../../components/label/form-field-label/formFieldLabel";
import { CurrencyLabel } from "../../utils/CurrencyLabel";
import DatabaseContext from "../../database/dabaseContext";
import { getContentLanguage } from "../../utils/ContentLanguage";

const Registration = props => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user, loginErrorData, isLogin, countryListSuccess, countryListError,
        stateListSuccess, stateListError, cityListSuccess, cityListError } = useSelector(state => state.user)
    let navigate = useNavigate();
    const [countryDropdown, setCountryDropdown] = useState();
    const [stateDropdown, setStateDropdown] = useState();
    const [cityDropdown, setCityDropdown] = useState();
    const [countryCode, setCountryCode] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [businessNameArabic, setBusinessNameArabic] = useState('')
    const [businessNameUrdu, setBusinessNameUrdu] = useState('')
    const [businessNameSpanish, setBusinessNameSpanish] = useState('')
    const [businessNameFrench, setBusinessNameFrench] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedLanguage } = useContext(DatabaseContext)

    useEffect(() => {
        const data = {
            screen: 'SIGN_UP',
            screenClass: 'SIGN_UP'
        }
        screenCaptureAnalytics(data)
        logoutUser()
        setLoading(true)
        dispatch(countryListRequest())
    }, [])

    const logoutUser = () => {
        dispatch({ type: APP_CONSTANTS.RESET_STATE_CITY })
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast(getContentLanguage(selectedLanguage, 'REGISTRATION', 'REGISTRATION_SUCCESS'))
            logoutUser()
            clearFields()
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const fetchStateList = (countryId) => {
        setLoading(true)
        const data = {
            countryId: countryId
        }
        dispatch(stateListRequest(data))
    }

    const fetchCityList = (countryId, stateId) => {
        setLoading(true)
        const data = {
            countryId: countryId,
            stateId: stateId
        }
        dispatch(cityListRequest(data))
    }

    useEffect(() => {
        if (!isNull(countryListSuccess)) {
            setLoading(false)
        } else if (!isNull(countryListError)) {
            setLoading(false)
        }
    }, [countryListSuccess, countryListError])

    useEffect(() => {
        if (!isNull(stateListSuccess)) {
            setLoading(false)
        } else if (!isNull(stateListError)) {
            setLoading(false)
        }
    }, [stateListSuccess, stateListError])

    useEffect(() => {
        if (!isNull(cityListSuccess)) {
            setLoading(false)
        } else if (!isNull(cityListError)) {
            setLoading(false)
        }
    }, [cityListSuccess, cityListError])

    const handleCountrySelectChange = e => {
        setCountryDropdown(e);
        setCountryCode(e.iso2)
        setStateDropdown(null)
        setCityDropdown(null)
        fetchStateList(e.iso2)
    }

    const isAdminRegistration = () => {
        let accountType = searchParams.get('accountType')
        if (!isNull(accountType)) {
            let userType = accountType.toLowerCase()
            if (userType === APP_CONSTANTS.USER_TYPE.ADMIN.toLowerCase()) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const clearFields = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setBusinessName('')
        setBusinessNameArabic('')
    }

    const handleCreateClick = () => {
        if (isEmpty(firstName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'FIRST_NAME_ERROR', null, false, false, true), true)
        } else if (!isEnglishText(firstName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isEmpty(lastName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'LAST_NAME_ERROR', null, false, false, true), true)
        } else if (!isEnglishText(lastName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true), true)
        } else if (isEmpty(email)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'EMAIL_ERROR', null, false, false, true), true)
        } else if (!isValidEmail(email)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_EMAIL', null, false, false, true), true)
        } else if (!isEnglishText(email)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isNull(mobile)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_MOBILE', null, false, false, true), true)
        } else if (isEmpty(password)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PASSWORD_ERROR', null, false, false, true), true)
        } else if (!isEnglishText(password)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isEmpty(confirmPassword)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'CONFIRM_PASSWORD_ERROR', null, false, false, true), true)
        } else if (!isEnglishText(confirmPassword)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (password !== confirmPassword) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PASSWORD_MATCH_ERROR', null, false, false, true), true)
        } else if (isAdminRegistration() === false && isEmpty(businessName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME_ERROR', null, false, false, true))
        } else if (isAdminRegistration() === false && !isEmpty(businessName) && !isEnglishText(businessName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isAdminRegistration() === false && isNull(countryDropdown)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'COUNTRY_ERROR', null, false, false, true))
        } else if (isAdminRegistration() === false && isNull(stateDropdown)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'STATE_ERROR', null, false, false, true))
        } else if (isAdminRegistration() === false && isNull(cityDropdown)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'CITY_ERROR', null, false, false, true))
        } else if (isAdminRegistration() === false && isNull(selectedCurrency)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'CURRENCY_ERROR', null, false, false, true))
        } else {
            setLoading(true)
            const mobileWithoutPlusSymbol = mobile?.replace('+', "")
            const fd = new FormData()
            fd.append('firstName', firstName)
            fd.append('lastName', lastName)
            fd.append('email', email)
            fd.append('password', password)
            fd.append('mobile', parseInt(mobileWithoutPlusSymbol))
            fd.append('productCode', APP_CONSTANTS.PRODUCT_NAME)
            fd.append('userType', isAdminRegistration() === true ? APP_CONSTANTS.USER_TYPE.ADMIN : APP_CONSTANTS.USER_TYPE.BUSINESS)

            if (isAdminRegistration() === false) {
                fd.append('businessName', businessName)
                if (!isEmpty(businessNameArabic))
                    fd.append('businessNameArabic', businessNameArabic)
                if (!isEmpty(businessNameUrdu))
                    fd.append('businessNameUrdu', businessNameUrdu)
                if (!isEmpty(businessNameSpanish))
                    fd.append('businessNameSpanish', businessNameSpanish)
                if (!isEmpty(businessNameFrench))
                    fd.append('businessNameFrench', businessNameFrench)
                if (!isNull(countryDropdown) && !isNull(stateDropdown) && !isNull(cityDropdown)) {
                    fd.append('country', countryDropdown.name)
                    fd.append('state', stateDropdown.name)
                    fd.append('city', cityDropdown.name)
                }
                if (!isNull(selectedCurrency)) {
                    fd.append('currencyLabel', selectedCurrency.code)
                    fd.append('currencySymbol', selectedCurrency.symbol)
                    fd.append('currencyRegion', selectedCurrency.region)
                }
            }
            dispatch(userRegistrationRequest(fd))
        }
    }

    return (
        <>
            <div className="sign-up-container">
                <div className="sign-up-img-layout-container">
                    <img src={require('../../assets/sign-in-banner2.jpg')} className='sign-up-img-container' />
                    <img src={require('../../assets/sign-in-banner-tab1.jpg')} className='sign-up-tab-img-container' />

                </div>
                <div className="sign-up-content-layout-container">
                    <div className="sign-up-logo-container">
                        <img src={require('../../assets/logo.png')} alt='logo' className="img" />
                    </div>
                    <div className='sign-in-heading'>{getContentLanguage(selectedLanguage, 'REGISTRATION', 'CREATE_ACCOUNT')} <span className='sign-in-heading-label'>{getContentLanguage(selectedLanguage, null, 'AREABITES', null, true)}</span></div>
                    <FormFieldLabel label={getContentLanguage(selectedLanguage, 'REGISTRATION', 'ENTER_DETAIL_TO_REGISTER')} className='sign-in-sub-text-label margin-bottom-10' />
                    <CommonInputRow>
                        <CommonInputHalf
                            id='firstName'
                            onChange={e => setFirstName(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'FIRST_NAME', null, false, true)}
                            type='text'
                            value={firstName} />
                        <CommonInputHalf
                            id='lastName'
                            onChange={e => setLastName(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'LAST_NAME', null, false, true)}
                            type='text'
                            value={lastName} />
                    </CommonInputRow>
                    <CommonInputFull id='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder={getContentLanguage(selectedLanguage, null, 'EMAIL_ID', null, false, true)}
                        type='text'
                        value={email} />
                    <div className="r-phone-input">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder={getContentLanguage(selectedLanguage, null, 'MOBILE_NUMBER', null, false, true)}
                            value={mobile}
                            onChange={setMobile} />
                    </div>
                    <CommonInputRow>
                        <CommonInputHalf
                            id='password'
                            onChange={e => setPassword(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'PASSWORD', null, false, true)}
                            type='password'
                            value={password} />
                        <CommonInputHalf
                            id='confirmPassword'
                            onChange={e => setConfirmPassword(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'CONFIRM_PASSWORD', null, false, true)}
                            type='text'
                            value={confirmPassword} />
                    </CommonInputRow>
                    {isAdminRegistration() === false &&
                        <div>
                            <FormFieldLabel label={getContentLanguage(selectedLanguage, 'REGISTRATION', 'BUSINESS_INFORMATION')} />
                            <CommonInputFull
                                id='businessName'
                                onChange={e => setBusinessName(e.target.value)}
                                placeholder={getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME', null, false, true)}
                                type='text'
                                value={businessName}
                            />
                            {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull
                                id='businessNameArabic'
                                onChange={e => setBusinessNameArabic(e.target.value)}
                                placeholder={getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME_ARABIC', null, false, true)}
                                type='text'
                                value={businessNameArabic}
                                language="ar"
                            />}
                            {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull
                                id='businessNameUrdu'
                                onChange={e => setBusinessNameUrdu(e.target.value)}
                                placeholder={getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME_URDU', null, false, true)}
                                type='text'
                                value={businessNameUrdu}
                            />}
                            {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull
                                id='businessNameSpanish'
                                onChange={e => setBusinessNameSpanish(e.target.value)}
                                placeholder={getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME_SPANISH', null, false, true)}
                                type='text'
                                value={businessNameSpanish}
                            />}
                            {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull
                                id='businessNameFrench'
                                onChange={e => setBusinessNameFrench(e.target.value)}
                                placeholder={getContentLanguage(selectedLanguage, null, 'BUSINESS_NAME_FRENCH', null, false, true)}
                                type='text'
                                value={businessNameFrench}
                            />}
                            {CurrencyLabel && <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={selectedCurrency}
                                getOptionLabel={e => `${e.currency} (${e.symbol})`}
                                getOptionValue={e => e}
                                options={CurrencyLabel}
                                placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_CURRENCY', null, false, true)}
                                onChange={e => {
                                    setSelectedCurrency(e)
                                }} />}
                            {countryListSuccess && <Select
                                menuPlacement="auto"
                                className='select-full-width-no-border'
                                value={countryDropdown}
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e}
                                options={countryListSuccess}
                                placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_COUNTRY', null, false, true)}
                                onChange={e => {
                                    handleCountrySelectChange(e)
                                }} />}
                            <CommonInputRow>
                                {stateListSuccess && <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={stateDropdown}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e}
                                    options={stateListSuccess}
                                    placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_STATE', null, false, true)}
                                    onChange={e => {
                                        setStateDropdown(e);
                                        setCityDropdown(null)
                                        fetchCityList(countryCode, e.iso2)
                                    }} />}
                                {cityListSuccess && <Select
                                    menuPlacement="auto"
                                    className='select-half-width'
                                    value={cityDropdown}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e}
                                    options={cityListSuccess}
                                    placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_CITY', null, false, true)}
                                    onChange={e => {
                                        setCityDropdown(e);
                                    }} />}
                            </CommonInputRow>
                        </div>}
                    <ButtonRow>
                        <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                            isBig={true}
                            onClick={() => { handleCreateClick() }} />
                    </ButtonRow>
                    {/* <div className="r-condition-label">By clicking submit you agree to our <a href={`${config.webBaseUrl}${ROUTES.termsOfService}`} target='_blank' className="tc-a-link">Terms &amp; Conditions</a></div> */}
                </div>

            </div>
            <ToastContainer />
            <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
        </>
    )

}

export default Registration