import React, { createContext, useEffect, useState } from "react";
import { createDatabase } from './rxDatabase'
import { APP_CONSTANTS } from "../utils/AppConstants";

const DatabaseContext = createContext()

export const DatabaseProvider = ({ children }) => {
    const [database, setDatabase] = useState(null)
const [selectedLanguage, setSelectedLanguage] = useState(APP_CONSTANTS.LANGUAGE_MAP.ENGLISH)

    useEffect(() => {
        const initializeDatabase = async () => {
            const db = await createDatabase()
            setDatabase(db)
        }
        initializeDatabase()
    })

    return (
        <DatabaseContext.Provider value={{ database, selectedLanguage, setSelectedLanguage }}>{children}</DatabaseContext.Provider>
    )
}

export default DatabaseContext