import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, isNull, screenCaptureAnalytics, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, priceWithSymbol, calculateDiscountedPrice, getProductMeasurmentUnit, isImageAvailable, getCategoryLocalText, getProductLocalText, getOptionItemLabelLocalText, getBusinessLabelLocalText, removeSpecialCharAndChangeToPascal } from '../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../utils/AppConstants';
import Select from 'react-select'
import { addItemInCart, reduceItemQty, addAdditionalDiscount } from '../../redux/cart/cart.action'
import ScreenLabel from "../../components/label/screen-label/screenLabel";
import { config } from "../../config";
import Modal from 'react-bootstrap/Modal';
import CommonButton from "../../components/button/common-button/commonButton";
import ButtonRow from "../../components/button/button-row/buttonRow";
import DatabaseContext from "../../database/dabaseContext";
import { getAllCategoryRecordByBusinessId } from "../../database/operation/categoryOperation";
import { getAllProductRecordByCategoryId } from "../../database/operation/productOperation";
import { useReactToPrint } from 'react-to-print';
import { addOrderRecord } from "../../database/operation/orderOperation";
import CommonInputFull from "../../components/input/common-input-full/commonInputFull";
import { getContentLanguage } from "../../utils/ContentLanguage";

const PlaceOrder = props => {
    const { user } = useSelector(state => state.user)
    const { cartItem, totalAmount, subTotal, taxAmount, discountAmt, additionalDiscount } = useSelector(state => state.cart)
    const [isLoading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categoryList, setCategoryList] = useState([])
    const dispatch = useDispatch()
    let isFirstTime = false
    let navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState()
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)
    let { database, selectedLanguage } = useContext(DatabaseContext)
    const [productList, setProductList] = useState([])
    const [paymentMode, setPaymentMode] = useState('')
    const [showOrderSuccessModal, setShowOrderSuccessModal] = useState(false)
    const [orderNumber, setOrderNumber] = useState('')
    const orderToPrint = useRef(null)
    const [additionalDicountAmt, setAdditionalDiscountAmt] = useState('')

    useEffect(() => {
        getAllCategoryRecordByBusinessId(database, user.data.userDetails.businessDetails.businessId).then(data => {
            setCategoryList(data)
            setSelectedCategory(categoryList[0])
        }).catch(error => {
            displayConsoleLog('error', error)
        })
    }, [])

    useEffect(() => {
        if (!isEmpty(selectedCategory)) {
            getAllProductRecordByCategoryId(database, selectedCategory.categoryId).
                then(data => {
                    setProductList(data)
                }).catch(error => {
                    displayConsoleLog('err', error)
                })
        }
    }, [selectedCategory])

    const categoryClick = item => {
        setSelectedCategory(item)
    }

    const getImageClassName = index => {
        const lastDigit = index % 10;
        // return `cmc-img-container${lastDigit}`
        return `cmc-img-container-white`
    }

    const displayCustomizeButton = (item) => {
        if (isEmpty(item.addOns) && isEmpty(item.options))
            return false
        else return true
    }

    const handleOpenCustomizeScreen = (item) => {
        displayConsoleLog('item', item)
        navigate(ROUTES.menuProductCustomize, { state: item })
    }

    const addClick = item => {
        setSelectedItem(item)
        if (!item.isOptionBasedPricing) {
            addItem(item)
        } else {
            setShowOptionPriceModal(true)
        }

    }

    const addItem = item => {
        if (isNull(item)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ERROR_WHILE_ADDING_ITEM', null, false, false, true))
        } else {
            displayConsoleLog('===item', item)
            const data = {
                itemId: item.itemId,
                itemCode: item.itemCode,
                categoryId: item.categoryId,
                name: item.name,
                isOptionBasedPricing: false,
                price: item.price,
                finalPrice: item.finalPrice,
                quantity: 1,
                isFromCart: false,
                discount: item.discount,
                tax: item.isTaxable === true ? item.tax : 0,
                isTaxable: item.isTaxable,
                currencyLabel: item.currencyLabel,
                currencySymbol: item.currencySymbol,
                currencyRegion: item.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: item.measurementUnit,
                measurementQuantity: item.measurementQuantity
            }
            if (!isEmpty(item.points)) {
                data.points = Number(item.points)
                data.productPoints = Number(item.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast(getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ITEM_ADDED_IN_CART'))
            // dispatch(displayAddCartBannerAct(''))
        }
    }

    const handleAddWithPrice = item => {
        if (isNull(selectedItem)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ERROR_WHILE_ADDING_ITEM', null, false, false, true))
        } else {
            const data = {
                itemId: selectedItem.itemId,
                itemCode: selectedItem.itemCode,
                categoryId: selectedItem.categoryId,
                name: `${getProductLocalText(selectedLanguage, selectedItem)} - ${item.label}`,
                isOptionBasedPricing: true,
                price: item.price,
                finalPrice: item.price,
                quantity: 1,
                isFromCart: false,
                discount: 0,
                tax: 0,
                isTaxable: false,
                currencyLabel: selectedItem.currencyLabel,
                currencySymbol: selectedItem.currencySymbol,
                currencyRegion: selectedItem.currencyRegion,
                addOns: [],
                options: [],
                isCustomized: false,
                measurementUnit: selectedItem.measurementUnit,
                measurementQuantity: selectedItem.measurementQuantity
            }
            if (!isEmpty(selectedItem.points)) {
                data.points = Number(selectedItem.points)
                data.productPoints = Number(selectedItem.points)
            } else {
                data.points = 0
                data.productPoints = 0
            }
            dispatch(addItemInCart(data))
            displaySuccessToast(getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ITEM_ADDED_IN_CART'))
            // dispatch(displayAddCartBannerAct(''))
            setShowOptionPriceModal(false)
        }
    }

    const isValidPoints = points => {
        if (!isEmpty(points) && points > 0) {
            return true
        } else {
            return false
        }
    }

    const updateQty = element => {
        const item = {
            ...element,
            isFromCart: true
        }
        dispatch(addItemInCart(item))
    }

    const reduceQty = element => {
        dispatch(reduceItemQty(element))
    }

    const handlePlaceOrder = () => {
        if (isEmpty(cartItem)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'CART_EMPTY_ERROR', null, false, false, true))
        } else if (isEmpty(paymentMode)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PAYMENT_MODE_BLANK_ERROR', null, false, false, true))
        } else {
            const data = {
                orderStatus: APP_CONSTANTS.ORDER_STATUS.DELIVERED,
                paymentStatus: APP_CONSTANTS.ORDER_PAYMENT_STATUS.PAID,
                paymentMode: APP_CONSTANTS.ORDER_PAYMENT_MODE.OFFLINE,
                paymentModeName: paymentMode,
                discountAmount: discountAmt,
                subTotal: subTotal,
                taxAmount: taxAmount,
                grandTotal: totalAmount,
                businessId: user.data.userDetails.businessDetails.businessId,
                locationId: parseFloat(user.data.userDetails.locationDetails[0].locationId),
                processedItems: cartItem,
                currencyLabel: cartItem[0].currencyLabel,
                currencySymbol: cartItem[0].currencySymbol,
                currencyRegion: cartItem[0].currencyRegion
            }
            addOrderRecord(database, data).then((ordrItem) => {
                setOrderNumber(ordrItem.orderNumber)
                setShowOrderSuccessModal(true)
            })
        }
    }

    const handleOrderSuccessModalClose = () => {
        setShowOrderSuccessModal(false)
        dispatch({ type: APP_CONSTANTS.CLEAR_CART })
    }

    const handleOrderPrint = useReactToPrint({
        content: () => orderToPrint.current
    })

    const ComponentToPrint = React.forwardRef((props, ref) => {
        return (<div className="cr-print-container" ref={ref}>
            {!isEmpty(cartItem) && !isEmpty(user) && <div className="cr-restaurant-label">{getBusinessLabelLocalText(selectedLanguage, user.data.userDetails.businessDetails)}</div>}
            {!isEmpty(cartItem) && !isEmpty(user) && <div className="cr-location-label">{user.data.userDetails.locationDetails[0].name} - {user.data.userDetails.locationDetails[0].city}, {user.data.userDetails.locationDetails[0].state}, {user.data.userDetails.locationDetails[0].country}</div>}
            {!isEmpty(cartItem) && <div className="cr-date-label">{new Date().toDateString()}</div>}
            {!isEmpty(cartItem) && <div className="cr-print-row-parent-container cr-print-row-container">
                <div className="cr-print-row-label cr-print-row-heading">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ITEM')}</div>
                <div className="cr-print-row-qty cr-print-row-heading">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'QTY')}</div>
                <div className="cr-print-row-price cr-print-row-heading">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PRICE')}</div>
            </div>}
            {!isEmpty(cartItem) && cartItem.map(e => (
                <div className="cr-print-row-parent-container">
                    <div className="cr-print-row-container">
                        <div className="cr-print-row-label">{getProductLocalText(selectedLanguage, e)} ({e.measurementQuantity}/{e.measurementUnit})</div>
                        <div className="cr-print-row-qty"> x {e.quantity}</div>
                        <div className="cr-print-row-price">{priceWithSymbol(e, e.finalPrice)}</div>
                    </div>
                    {!isEmpty(e.options) && <div className="margin-top-10">
                        <div className="crl-customization-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'OPTIONS')}</div>
                        <div className="crl-customization-container">
                            {e.options.map(e1 => (<div className="crl-customization-item">{getOptionItemLabelLocalText(selectedLanguage, e1)}</div>))}
                        </div>
                    </div>}
                    {!isEmpty(e.addOns) && <div className="margin-top-10">
                        <div className="crl-customization-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADD_ONS')}</div>
                        <div className="crl-customization-container">
                            {e.addOns.map(e1 => (<div className="crl-customization-item">{getOptionItemLabelLocalText(selectedLanguage, e1)}</div>))}
                        </div>
                    </div>}
                </div>))}
            {!isEmpty(cartItem) && <div className="cr-print-col-container">
                <div>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'SUB_TOTAL')} {priceWithSymbol(cartItem[0], subTotal)}</div>
                <div>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'TOTAL_DISCOUNT')} {priceWithSymbol(cartItem[0], discountAmt)}</div>
                <div>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'TOTAL_TAX')} {priceWithSymbol(cartItem[0], taxAmount)}</div>
                <div>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADDITIONAL_DISCOUNT')} {priceWithSymbol(cartItem[0], additionalDiscount)}</div>
                <div className="cr-print-row-heading">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'FINAL_AMOUNT')} {priceWithSymbol(cartItem[0], totalAmount)}</div>
            </div>}
            {!isEmpty(cartItem) && <div className="cr-restaurant-label cr-location-label margin-top-10">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'THANK_YOU_FOR_VISIT')}</div>}
            {!isEmpty(cartItem) && <div className="cr-location-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'HAVE_GREAT_DAY')}</div>}
            {!isEmpty(cartItem) && <div className="cr-date-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'POWERED_BY')}</div>}
        </div>)
    })

    const itemChargeableAmount = (item) => {
        let totalAmt = 0
        let tempTotal = calculateItemSubTotal(item)

        let iDiscountAmt = (item.discount * tempTotal / 100)
        let iTaxAmount = (item.tax * tempTotal / 100)

        let priceAfterDiscount = tempTotal - iDiscountAmt
        let iTotalAmt = Number(priceAfterDiscount) + Number(iTaxAmount)

        totalAmt = Number(totalAmt) + Number(iTotalAmt)
        return (Math.round(totalAmt * 1e2) / 1e2)
    }

    const calculateItemSubTotal = item => {
        let tempTotal = item.price
        if (!isEmpty(item.addOns)) {
            item.addOns.forEach(e1 => {
                tempTotal = Number(tempTotal) + Number(e1.price)
            })
        }
        if (!isEmpty(item.options)) {
            item.options.forEach(e1 => {
                tempTotal = Number(tempTotal) + Number(e1.price)
            })
        }

        tempTotal = tempTotal * item.quantity
        return tempTotal
    }

    const handleAdditionalDiscountClick = () => {
        if (isEmpty(additionalDicountAmt)) {
            displayErrorToast('Please enter additional discount amount')
        } else if (Number(additionalDicountAmt) < 0) {
            displayErrorToast('Please enter valid additional discount amount')
        } else {
            dispatch(addAdditionalDiscount(additionalDicountAmt))
            setAdditionalDiscountAmt('')
        }
    }

    return (
        <>
            <div className="plo-container">
                {(!isEmpty(user) && !isEmpty(user.data.userDetails.businessDetails)) ? (
                    <div className="cmc-label-container mt-2">
                        <div className="mcp-logo-container">
                            <img className="img" src={require('../../assets/logoSingle.png')} alt="img" />
                        </div>
                        {/* <div className="mcp-restaurant-label">{user.data.userDetails.businessDetails.businessName}</div> */}
                        <div className="mcp-restaurant-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PLACE_ORDER_LABEL')}</div>
                    </div>
                ) :
                    (<div className="cmc-label-container">
                        <ScreenLabel label={getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PLACE_ORDER_LABEL')} />
                    </div>)
                }
                {/* <ScreenLabel label='Place Order' /> */}
                {!isEmpty(categoryList) && <div className="mcp-category-parent-container">
                    <div className="mcp-category-scroll-container">
                        {categoryList.map(e => (
                            <div className={`mcp-category-item ${selectedCategory?.categoryId === e.categoryId ? 'mcp-category-item-selected' : ''}`}
                                onClick={() => { categoryClick(e) }}>{getCategoryLocalText(selectedLanguage, e)}</div>
                        ))}
                    </div>
                </div>}
                {isEmpty(categoryList) && <div className="content-error">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'NO_CATEGORY_AVAILABEL')}</div>}
                <div className="row">
                    <div className="col-sm-6">
                        {isEmpty(productList) && <div className="content-error">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'NO_PRODUCT_FOUND')}</div>}
                        {!isEmpty(productList) && <div className="mcp-product-list-parent-container-row">
                            {productList.map((element, index) => (
                                <div className="mcp-product-row-container-row">
                                    <div className="mcp-product-row-item-container">
                                        <div className="mcp-product-text-parent-container">

                                            <div className="mcp-product-text-container">
                                                <div className="mcp-product-name">{getProductLocalText(selectedLanguage, element)} { }</div>
                                                {element.status === 1 && !element.isOptionBasedPricing && <div>
                                                    {element.discount > 0 ? (<div className="mcp-product-price-name"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)}
                                                </div>}
                                                {element.status === 1 && isValidPoints(element.points) && <div className="mcp-points-container">&#9733; {getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'EARN')} {element.points ? element.points.toLocaleString('en-US') : '0'} {getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'POINTS')}</div>}
                                                {element.status === 1 && element.isOptionBasedPricing && <div className="mcp-option-based-pricing-container">
                                                    {element.optionBasedPricing.map(e => (
                                                        <div className="mcp-option-price-label">{e.label} - {priceWithSymbol(element, e.price)}</div>
                                                    ))}
                                                </div>}
                                                {element.status === 0 && <div className="out-of-stock">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'OUT_OF_STOCK')}</div>}
                                            </div>
                                            {element.status === 1 && displayCustomizeButton(element) === false && <div className="add-cart-button-bl" onClick={() => addClick(element)}>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADD')}</div>}
                                            {element.status === 1 && displayCustomizeButton(element) === true && <div className="add-cart-button-bl" onClick={() => handleOpenCustomizeScreen(element)}>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'CUSTOMIZE')}</div>}
                                        </div>

                                        {/* <div className="mcp-product-image-container">
                                            {!isEmpty(element.image) ? (<img src={element.image} className="img" alt="..." />) : (
                                                <div className='p-default-img' />
                                            )}
                                            {element.status === 1 && displayCustomizeButton(element) === false && <div className="edit-image-button-bl" onClick={() => addClick(element)}>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADD')}</div>}
                                            {element.status === 1 && displayCustomizeButton(element) === true && <div className="edit-image-button-bl" onClick={() => handleOpenCustomizeScreen(element)}>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'CUSTOMIZE')}</div>}
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="cmc-content-container plo-center-container">
                            {!isNull(cartItem) && <div className="plo-cart-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'YOUR_CART')}</div>}
                            {!isNull(cartItem) && cartItem.map(element => (
                                <div className="add-crl-row plo-row-item-container">
                                    <div className="crl-item-label">{getProductLocalText(selectedLanguage, element)}</div>
                                    <div className="crl-item-price-container">
                                        {/* <div className="crl-item-price-edit-icon"><img src={require('../../assets/ic_edit_icon.png')} className="img" /></div> */}
                                        <div className="crl-item-price-label">{priceWithSymbol(element, element.finalPrice)}</div>
                                    </div>
                                    {/* {element.discount > 0 ? (<div className="crl-amount-label"><span className="pl-price-label-striked">{priceWithSymbol(element, element.price)}</span> {priceWithSymbol(element, calculateDiscountedPrice(element.price, element.discount))} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>) : (<div className="mcp-product-price-name">{priceWithSymbol(element, element.price)} {getProductMeasurmentUnit(element.measurementQuantity, element.measurementUnit)}</div>)} */}
                                    {!isEmpty(element.options) && <div className="margin-top-10">
                                        <div className="crl-customization-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'OPTIONS')}</div>
                                        <div className="crl-customization-container">
                                            {element.options.map(e => (<div className="crl-customization-item">{getOptionItemLabelLocalText(selectedLanguage, e)}</div>))}
                                        </div>
                                    </div>}
                                    {!isEmpty(element.addOns) && <div className="margin-top-10">
                                        <div className="crl-customization-label">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADD_ONS')}</div>
                                        <div className="crl-customization-container">
                                            {element.addOns.map(e => (<div className="crl-customization-item">{getOptionItemLabelLocalText(selectedLanguage, e)}</div>))}
                                        </div>
                                    </div>}
                                    <div className="add-crl-btn-row">
                                        <div className="plo-cart-quantity-label">{element.quantity} {removeSpecialCharAndChangeToPascal(element.measurementUnit)}</div>
                                        <div className="crl-update-qty-container">
                                            <div className="add-crl-img-container" onClick={() => reduceQty(element)}>
                                                <img className="add-crl-img" src={require('../../assets/minus.png')} alt="minus" />
                                            </div>
                                            <div className="crl-qty-update-label">{element.quantity}</div>
                                            <div className="add-crl-img-container" onClick={() => updateQty(element)}>
                                                <img className="add-crl-img" src={require('../../assets/plus.png')} alt="plus" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {!isEmpty(cartItem) && <div className="margin-bottom-10">
                            <div className="add-cr-modal-row margin-top-10">
                                <div className="add-cr-modal-row-item">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'SUB_TOTAL')}</div>
                                <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], subTotal)}</div>
                            </div>
                            <div className="add-cr-modal-row">
                                <div className="add-cr-modal-row-item">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'TOTAL_DISCOUNT')}</div>
                                <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], discountAmt)}</div>
                            </div>
                            <div className="add-cr-modal-row">
                                <div className="add-cr-modal-row-item">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'TOTAL_TAX')}</div>
                                <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], taxAmount)}</div>
                            </div>
                            <div className="add-cr-modal-row">
                                <div className="add-cr-modal-row-item">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADDITIONAL_DISCOUNT')}</div>
                                <div className="add-cr-modal-row-item-value">{priceWithSymbol(cartItem[0], additionalDiscount)}</div>
                            </div>
                            <div className="add-cr-modal-row">
                                <div className="add-cr-modal-row-item text-label-bold">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'FINAL_AMOUNT')}</div>
                                <div className="add-cr-modal-row-item-value text-label-bold">{priceWithSymbol(cartItem[0], totalAmount)}</div>
                            </div>
                        </div>}
                        {!isEmpty(cartItem) && <div className="crl-additional-discount-container"> <CommonInputFull
                            type='number'
                            id='additionalDiscount'
                            onChange={e => setAdditionalDiscountAmt(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ADDITIONAL_DISCOUNT')}
                            value={additionalDicountAmt}
                        />
                            <div className="crl-additional-discount-check-img" onClick={() => handleAdditionalDiscountClick()}>
                                <img src={require('../../assets/ic_green_check.png')} className="img" />
                            </div>
                        </div>}
                        {!isEmpty(cartItem) && <CommonInputFull
                            type='text'
                            id='paymentMode'
                            onChange={e => setPaymentMode(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'PAYMENT_MODE', null, false, true)}
                            value={paymentMode}
                            isSubtext={true}
                            subText={getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PAYMENT_MODE_EXAMPLE')}
                        />}
                        {!isEmpty(cartItem) && <div style={{ display: 'none' }}><ComponentToPrint ref={orderToPrint} /></div>}
                        {!isNull(cartItem) && <ButtonRow>
                            <CommonButton label={getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PLACE_ORDER_BUTTON')} onClick={() => handlePlaceOrder()} />
                        </ButtonRow>}
                    </div>
                </div>

            </div>
            <Modal
                show={showOptionPriceModal}
                onHide={() => setShowOptionPriceModal(!showOptionPriceModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="mp-button-container">
                        {!isNull(selectedItem) && selectedItem.optionBasedPricing.map(e => (
                            <CommonButton
                                label={`${e.label} - ${e.price}`}
                                onClick={() => { handleAddWithPrice(e) }}
                            />
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                onHide={() => { handleOrderSuccessModalClose() }}
                show={showOrderSuccessModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'SUCCESS')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cr-modal-container">
                        <div className="cr-modal-img-container">
                            <img className="img" src={require('../../assets/success_icon.png')} alt="success" />
                        </div>
                        <div className="cr-modal-order-success">{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'ORDER_PLACE_SUCCESS')}</div>
                        {/* <div className="cr-modal-order-number">Order Number</div>
                        <div className="cr-modal-order-number cr-modal-order-number-val">
                            {orderNumber}
                        </div> */}
                        <div className="cr-print-btn-container">
                            <div className="cr-print-btn" onClick={handleOrderPrint}>{getContentLanguage(selectedLanguage, 'PLACE_ORDER', 'PRINT')}</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
        </>
    )
}

export default PlaceOrder