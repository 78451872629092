export const productSchema = {
    title: 'productSchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "itemIdText",
    properties: {
        itemId: {
            type: 'number',
            "maxLength": 99999
        },
        itemIdText: {
            type: 'string',
            "maxLength": 99999
        },
        name: {
            type: 'string',
        },
        nameArabic: {
            type: 'string',
        },
        nameUrdu: {
            type: 'string',
        },
        nameSpanish: {
            type: 'string',
        },
        nameFrench: {
            type: 'string',
        },
        categoryId: {
            type: 'number',
        },
        categoryName: {
            type: 'string',
        },
        categoryNameArabic: {
            type: 'string',
        },
        measurementUnit: {
            type: 'string',
        },
        measurementQuantity: {
            type: 'number',
        },
        currencyLabel: {
            type: 'string',
        },
        currencySymbol: {
            type: 'string',
        },
        currencyRegion: {
            type: 'string',
        },
        isOptionBasedPricing: {
            type: 'boolean',
        },
        optionBasedPricing: {
            type: 'array',
        },
        price: {
            type: 'number',
        },
        discount: {
            type: 'number'
        },
        isTaxable: {
            type: 'boolean'
        },
        tax: {
            type: 'number'
        },
        finalPrice: {
            type: 'number'
        },
        businessId: {
            type: 'number'
        },
        productCode: {
            type: 'string'
        },
        image: {
            type: 'string',
            format: 'base64'
        },
        sellingType: {
            type: 'string'
        },
        servingType: {
            type: 'string'
        },
        locationId: {
            type: 'array',
        },
        addOnsList: {
            type: 'array',
        },
        specialInstructions: {
            type: 'string'
        },
        addOns: {
            type: 'array',
        },
        options: {
            type: 'array',
        },
        isVeg: {
            type: 'boolean'
        },
        isNonVeg: {
            type: 'boolean'
        },
        isVegan: {
            type: 'boolean'
        },
        isBreakfast: {
            type: 'boolean'
        },
        isDinner: {
            type: 'boolean'
        },
        isLunch: {
            type: 'boolean'
        },
        is24Hour: {
            type: 'boolean'
        },
        points: {
            type: 'number'
        },
        status: {
            type: 'number'
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};