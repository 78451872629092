import React, { useContext, useEffect, useState } from "react";
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog, displayCurrencySymbolLeft,
    displayErrorToast, displaySuccessToast, getProductMeasurmentUnit, isEmpty, isImageAvailable, isNull, screenCaptureAnalytics,
    calculateDiscountedPrice,
    isLoggedInUserBusiness,
    isLoggedInUserEmployee,
    getOptionLabelLocalText,
    getOptionItemLabelLocalText,
    getProductLocalText
} from '../../utils/Utils'
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_CONSTANTS } from '../../utils/AppConstants';
import ScreenLabel from "../../components/label/screen-label/screenLabel";
import { config } from "../../config";
import FormFieldLabel from "../../components/label/form-field-label/formFieldLabel";
import ButtonRow from "../../components/button/button-row/buttonRow";
import CommonButton from "../../components/button/common-button/commonButton";
import CommonInputRow from "../../components/input/common-input-row/commonInputRow";
import { addItemInCart, displayAddCartBannerAct } from '../../redux/cart/cart.action'
import Modal from 'react-bootstrap/Modal';
import DatabaseContext from "../../database/dabaseContext";
import { getContentLanguage } from "../../utils/ContentLanguage";

const MenuProductCustomize = props => {
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(false)
    const { state } = useLocation()
    const { user } = useSelector(state => state.user)
    const [quantity, setQuantity] = useState(0)
    const [selectedOption, setSelectedOption] = useState([])
    const [selectedAddOn, setSelectedAddOn] = useState([])
    const [counter, setCounter] = useState(0)
    const [showOptionPriceModal, setShowOptionPriceModal] = useState(false)
    const { database, selectedLanguage } = useContext(DatabaseContext)

    useEffect(() => {
        console.log('state', state);
    }, [state])

    const isNoImage = (item) => {
        return item.includes("no-image")
    }

    const getImageClassName = index => {
        const lastDigit = index % 10;
        return `mpc-img-container-color`
    }

    const increaseQuantity = () => {
        setQuantity(Number(quantity) + 1)
    }

    const descreaseQuantity = () => {
        if (quantity === 0) {
            setQuantity(0)
        } else {
            setQuantity(Number(quantity) - 1)
        }
    }

    const addItem = () => {
        if (isNull(state)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ERROR_WHILE_ADDING_ITEM', null, false, false, true))
        } else if (quantity <= 0) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ITEM_QUANTITY_ERROR', null, false, false, true))
        } else {
            if (!state.isOptionBasedPricing) {
                const data = {
                    itemId: state.itemId,
                    itemCode: state.itemCode,
                    categoryId: state.categoryId,
                    name: getProductLocalText(selectedLanguage, state),
                    price: state.price,
                    finalPrice: Number(state.finalPrice) * Number(quantity),
                    quantity: quantity,
                    discount: state.discount,
                    isOptionBasedPricing: false,
                    isFromCart: false,
                    tax: state.isTaxable === true ? state.tax : 0,
                    isTaxable: state.isTaxable,
                    currencyLabel: state.currencyLabel,
                    currencySymbol: state.currencySymbol,
                    currencyRegion: state.currencyRegion,
                    addOns: selectedAddOn,
                    options: selectedOption,
                    measurementUnit: state.measurementUnit,
                    measurementQuantity: state.measurementQuantity
                }
                if (!isEmpty(state.points)) {
                    data.points = Number(state.points) * Number(quantity)
                    data.productPoints = Number(state.points)
                } else {
                    data.points = 0
                    data.productPoints = 0
                }
                if (isEmpty(selectedOption) && isEmpty(selectedAddOn))
                    data.isCustomized = false
                else data.isCustomized = true
                dispatch(addItemInCart(data))
                displaySuccessToast(getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'ITEM_ADDED_IN_CART'))
            } else {
                setShowOptionPriceModal(true)
            }

        }
    }

    const handleAddWithPrice = item => {
        const data = {
            itemId: state.itemId,
            itemCode: state.itemCode,
            categoryId: state.categoryId,
            name: `${getProductLocalText(selectedLanguage, state)} - ${item.label}`,
            isOptionBasedPricing: state.isOptionBasedPricing,
            price: item.price,
            finalPrice: Number(item.price) * Number(quantity),
            quantity: quantity,
            discount: 0,
            isFromCart: false,
            tax: 0,
            isTaxable: false,
            currencyLabel: state.currencyLabel,
            currencySymbol: state.currencySymbol,
            currencyRegion: state.currencyRegion,
            addOns: selectedAddOn,
            options: selectedOption,
            measurementUnit: state.measurementUnit,
            measurementQuantity: state.measurementQuantity
        }
        if (!isEmpty(state.points)) {
            data.points = Number(state.points) * Number(quantity)
            data.productPoints = Number(state.points)
        } else {
            data.points = 0
            data.productPoints = 0
        }
        if (isEmpty(selectedOption) && isEmpty(selectedAddOn))
            data.isCustomized = false
        else data.isCustomized = true
        dispatch(addItemInCart(data))
        displaySuccessToast(getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'ITEM_ADDED_IN_CART'))
        setShowOptionPriceModal(false)
    }

    const isItemSelected = (item, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            if (isEmpty(selectedAddOn))
                return false
            const selItem = selectedAddOn.find(e => e.id === item.id)
            // const selItem = selectedAddOn.find(e => {
            //     let element = null
            //     if (!isEmpty(e.name) && e.name === item.name) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameArabic) && e.nameArabic === item.nameArabic) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameUrdu) && e.nameUrdu === item.nameUrdu) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameSpanish) && e.nameSpanish === item.nameSpanish) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameFrench) && e.nameFrench === item.nameFrench) {
            //         element = e
            //     }
            //     return element
            // })
            if (!isNull(selItem))
                return true
            else return false
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            if (isEmpty(selectedOption))
                return false
            const selItem = selectedOption.find(e => e.id === item.id)
            // const selItem = selectedOption.find(e => {
            //     let element = null
            //     if (!isEmpty(e.name) && e.name === item.name) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameArabic) && e.nameArabic === item.nameArabic) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameUrdu) && e.nameUrdu === item.nameUrdu) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameSpanish) && e.nameSpanish === item.nameSpanish) {
            //         element = e
            //     }
            //     if (!isEmpty(e.nameFrench) && e.nameFrench === item.nameFrench) {
            //         element = e
            //     }
            //     return element
            // })
            if (!isNull(selItem))
                return true
            else return false
        }
    }

    const handleSelectItem = (item, type, optionLabel = '') => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            displayConsoleLog('selectedAddOn', selectedAddOn)
            displayConsoleLog('item', item)
            if (isEmpty(selectedAddOn)) {
                const tempList = []
                tempList.push(item)
                setSelectedAddOn(tempList)
            } else {
                const item1 = selectedAddOn.find(e => e.id === item.id)
                // const item1 = selectedAddOn.find(e => {
                //     let element = null
                //     if (!isEmpty(e.name) && e.name === item.name) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameArabic) && e.nameArabic === item.nameArabic) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameUrdu) && e.nameUrdu === item.nameUrdu) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameSpanish) && e.nameSpanish === item.nameSpanish) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameFrench) && e.nameFrench === item.nameFrench) {
                //         element = e
                //     }
                //     return element
                // })
                if (isNull(item1)) {
                    const tempList = selectedAddOn
                    tempList.push(item)
                    setSelectedAddOn(tempList)
                    let count = counter
                    setCounter(count + 1)
                } else {
                    // const tempList = selectedAddOn.filter(e => (!isEmpty(e.name) && !isEmpty(item.name) && e.name !== item.name) || (!isEmpty(e.nameArabic) && !isEmpty(item.nameArabic) && e.nameArabic !== item.nameArabic) || (!isEmpty(e.nameUrdu) && !isEmpty(item.nameUrdu) && e.nameUrdu !== item.nameUrdu) || (!isEmpty(e.nameSpanish) && !isEmpty(item.nameSpanish) && e.nameSpanish !== item.nameSpanish) || (!isEmpty(e.nameFrench) && !isEmpty(item.nameFrench) && e.nameFrench !== item.nameFrench))
                    const tempList = selectedAddOn.filter(e => e.id !== item.id)
                    setSelectedAddOn(tempList)
                }
            }
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            displayConsoleLog('selectedOption', selectedOption)
            displayConsoleLog('item', item)
            displayConsoleLog('optionLabel', optionLabel)
            if (isEmpty(selectedOption)) {
                const tempList = [{ ...item, optionLabel: optionLabel }]
                // const tempList = []
                // tempList.push(item)
                setSelectedOption(tempList)
            } else {
                displayConsoleLog('2')
                displayConsoleLog('selectedOption2', selectedOption)
                displayConsoleLog('item2', item)
                const item1 = selectedOption.find(e => e.id === item.id)
                // const item1 = selectedOption.find(e => {
                //     let element = null
                //     if (!isEmpty(e.name) && e.name === item.name) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameArabic) && e.nameArabic === item.nameArabic) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameUrdu) && e.nameUrdu === item.nameUrdu) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameSpanish) && e.nameSpanish === item.nameSpanish) {
                //         element = e
                //     }
                //     if (!isEmpty(e.nameFrench) && e.nameFrench === item.nameFrench) {
                //         element = e
                //     }
                //     return element
                // })
                displayConsoleLog('item1', item1)
                if (isNull(item1)) {
                    displayConsoleLog('3')
                    // const tempList = selectedOption
                    // tempList.push(item)
                    // setSelectedOption(tempList)
                    // let count = counter
                    // setCounter(count + 1)
                    displayConsoleLog('selectedOption', selectedOption)
                    const itemWithSameLabel = selectedOption.find(e => e.optionLabel.id === optionLabel.id)
                    // const itemWithSameLabel = selectedOption.find(e => {
                    //     let element = null
                    //     if (!isEmpty(e.name) && e.name === optionLabel.title) {
                    //         element = e
                    //     }
                    //     if (!isEmpty(e.nameArabic) && e.nameArabic === optionLabel.titleArabic) {
                    //         element = e
                    //     }
                    //     if (!isEmpty(e.nameUrdu) && e.nameUrdu === optionLabel.titleUrdu) {
                    //         element = e
                    //     }
                    //     if (!isEmpty(e.nameSpanish) && e.nameSpanish === optionLabel.titleSpanish) {
                    //         element = e
                    //     }
                    //     if (!isEmpty(e.nameFrench) && e.nameFrench === optionLabel.titleFrench) {
                    //         element = e
                    //     }
                    //     return element
                    // })
                    displayConsoleLog('itemWithSameLabel', itemWithSameLabel)
                    if (isNull(itemWithSameLabel)) {
                        displayConsoleLog('4')
                        const tempList = selectedOption
                        tempList.push({ ...item, optionLabel: optionLabel })
                        setSelectedOption(tempList)
                        let count = counter
                        setCounter(count + 1)
                    } else {
                        displayConsoleLog('5')
                        const tempList = selectedOption.filter(e => e.id !== itemWithSameLabel.id)
                        // const tempList = selectedOption.filter(e => (!isEmpty(e.name) && !isEmpty(itemWithSameLabel.name) && e.name !== itemWithSameLabel.name) || (!isEmpty(e.nameArabic) && !isEmpty(itemWithSameLabel.nameArabic) && e.nameArabic !== itemWithSameLabel.nameArabic) || (!isEmpty(e.nameUrdu) && !isEmpty(itemWithSameLabel.nameUrdu) && e.nameUrdu !== itemWithSameLabel.nameUrdu) || (!isEmpty(e.nameSpanish) && !isEmpty(itemWithSameLabel.nameSpanish) && e.nameSpanish !== itemWithSameLabel.nameSpanish) || (!isEmpty(e.nameFrench) && !isEmpty(itemWithSameLabel.nameFrench) && e.nameFrench !== itemWithSameLabel.nameFrench))
                        tempList.push({ ...item, optionLabel: optionLabel })
                        setSelectedOption(tempList)
                    }
                } else {
                    displayConsoleLog('6')
                    // const tempList = selectedOption.filter(e => (!isEmpty(e.name) && !isEmpty(item.name) && e.name !== item.name) || (!isEmpty(e.nameArabic) && !isEmpty(item.nameArabic) && e.nameArabic !== item.nameArabic) || (!isEmpty(e.nameUrdu) && !isEmpty(item.nameUrdu) && e.nameUrdu !== item.nameUrdu) || (!isEmpty(e.nameSpanish) && !isEmpty(item.nameSpanish) && e.nameSpanish !== item.nameSpanish) || (!isEmpty(e.nameFrench) && !isEmpty(item.nameFrench) && e.nameFrench !== item.nameFrench))
                    const tempList = selectedOption.filter(e => e.id !== item.id)
                    setSelectedOption(tempList)
                }
            }
        }
    }

    return (<>
        <div className="pl-container mb-4 mpc-content-container">
            {(!isEmpty(user) && !isEmpty(user.data.userDetails.businessDetails)) ? (
                <div className="cmc-label-container mt-2">
                    <div className="mcp-logo-container">
                        <img className="img" src={require('../../assets/logoSingle.png')} alt="img" />
                    </div>
                    {/* <div className="mcp-restaurant-label">{user.data.userDetails.businessDetails.businessName}</div> */}
                    <div className="mcp-restaurant-label">{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'CUSTOMIZE_ITEM')}</div>
                </div>
            ) :
                (<div className="cmc-label-container">
                    <ScreenLabel label={getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'CUSTOMIZE_ITEM')} />
                </div>)
            }
            <div className="mpc-image-container">
                {!isEmpty(state.image) ? (<img src={state.image} className="pl-img" alt="..." />) : (
                    <div className={`${getImageClassName(0)}`} />
                )}
            </div>
            <div className="mpc-title">{getProductLocalText(selectedLanguage, state)}</div>
            <div className="mpc-description">{state.description}</div>
            {!state.isOptionBasedPricing && <div>
                {state.discount <= 0 && <div> {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                    (<div className="pl-price-label">{state.currencySymbol} {state.price.toFixed(2)} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)
                    : (<div className="pl-price-label">{state.price.toFixed(2)} {state.currencySymbol} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)}
                </div>}
            </div>}
            {!state.isOptionBasedPricing && <div>
                {state.discount > 0 && <div>
                    {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                        (<div className="pl-price-label">{state.currencySymbol} <span className="pl-price-label-striked">{state.price.toFixed(2)}</span> {calculateDiscountedPrice(state.price, state.discount)} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)
                        : (<div className="pl-price-label">{state.price.toFixed(2)} {state.currencySymbol} {getProductMeasurmentUnit(state.measurementQuantity, state.measurementUnit)}</div>)}
                </div>}
            </div>}
            {state.isOptionBasedPricing && <div>
                {state.optionBasedPricing.map(e => (
                    <div>
                        {displayCurrencySymbolLeft(state.currencyRegion) === true ?
                            (<div className="pl-price-label">{e.label} - {state.currencySymbol} {e.price.toFixed(2)}</div>)
                            : (<div className="pl-price-label">{e.label} - {e.price.toFixed(2)} {state.currencySymbol}</div>)}
                    </div>
                ))}
            </div>}

            <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'AVAILABLE_OPTION')} />
            {!isEmpty(state.options) && <div>{
                state.options.map((e, index) => (<div>
                    <div className="mpc-option-item-name">{index + 1}. {getOptionLabelLocalText(selectedLanguage, e)}</div>
                    <div className="mpc-option-item-container">
                        {e.items.map(e1 => (
                            <div className="ap-option-item">
                                <div className="mpc-center-div mpc-div-justify-space mb-2">
                                    {isItemSelected(e1, APP_CONSTANTS.KEY_OPTION) && <div className="mpc-selected-img-container">
                                        <img src={require('../../assets/ic_green_check.png')} className="img" alt="check" />
                                    </div>}
                                    <div>
                                        <div className="mpc-option-type-description">{e1.isVeg === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'VEG')}</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isNonVeg === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'NON_VEG')}</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isVegan === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'VEGAN')}</span> : null}</div>
                                    </div>
                                </div>
                                <div className="ap-option-item-name">{getOptionItemLabelLocalText(selectedLanguage, e1)}</div>
                                {e1.price === 0 ? (<div className="pl-price-label">{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'FREE')}</div>) :
                                    (<div>{displayCurrencySymbolLeft(state.currencyRegion) === true ?
                                        (<div className="pl-price-label">{state.currencySymbol} {e1.price}</div>)
                                        : (<div className="pl-price-label">{e1.price} {state.currencySymbol}</div>)}</div>)
                                }
                                <div className="ap-option-item-description">{!isEmpty(e1.description) ? <span>{e1.description}</span> : <span>&nbsp;</span>}</div>
                                <div className="mpc-add-btn" onClick={() => { handleSelectItem(e1, APP_CONSTANTS.KEY_OPTION, e) }}>{isItemSelected(e1, APP_CONSTANTS.KEY_OPTION) === true ? `${getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'REMOVE')}` : `${getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'SELECT')}`}</div>
                            </div>
                        ))}
                    </div>
                </div>))}
            </div>}
            <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'AVAILABLE_ADD_ON')} />
            {!isEmpty(state.addOns) && <div>{
                state.addOns.map((e, index) => (<div>
                    <div className="mpc-option-item-name">{index + 1}. {getOptionLabelLocalText(selectedLanguage, e)}</div>
                    <div className="mpc-option-item-container">
                        {e.items.map(e1 => (
                            <div className="ap-option-item">
                                <div className="mpc-center-div mpc-div-justify-space mb-2">
                                    {isItemSelected(e1, APP_CONSTANTS.KEY_ADD_ON) && <div className="mpc-selected-img-container">
                                        <img src={require('../../assets/ic_green_check.png')} className="img" alt="check" />
                                    </div>}
                                    <div>
                                        <div className="mpc-option-type-description">{e1.isVeg === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'VEG')}</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isNonVeg === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'NON_VEG')}</span> : null}</div>
                                        <div className="mpc-option-type-description">{e1.isVegan === true ? <span>{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'VEGAN')}</span> : null}</div>
                                    </div>
                                </div>
                                <div className="ap-option-item-name">{getOptionItemLabelLocalText(selectedLanguage, e1)}</div>
                                <div className="ap-option-item-description">{!isEmpty(e1.description) ? <span>{e1.description}</span> : <span>&nbsp;</span>}</div>
                                {e1.price === 0 ? (<div className="pl-price-label">{getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'FREE')}</div>) :
                                    (<div>{displayCurrencySymbolLeft(state.currencyRegion) === true ?
                                        (<div className="pl-price-label">{state.currencySymbol} {e1.price}</div>)
                                        : (<div className="pl-price-label">{e1.price} {state.currencySymbol}</div>)}</div>)
                                }
                                <div className="mpc-add-btn" onClick={() => { handleSelectItem(e1, APP_CONSTANTS.KEY_ADD_ON) }}>{isItemSelected(e1, APP_CONSTANTS.KEY_ADD_ON) === true ? `${getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'REMOVE')}` : `${getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'ADD')}`}</div>
                            </div>
                        ))}
                    </div>
                </div>))}
            </div>}
            <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'SELECT_QUANTITY')} />
            <div className="mpc-center-div">
                <div className="mpc-quantity-img-container" onClick={() => { descreaseQuantity() }}>
                    <img src={require('../../assets/icon_sq_minus.png')} className="img" alt="minus" />
                </div>
                <div className="mpc-quantity-val">{quantity}</div>
                <div className="mpc-quantity-img-container" onClick={() => { increaseQuantity() }}>
                    <img src={require('../../assets/icon_sq_plus.png')} alt="plus" className="img" />
                </div>
            </div>
            <ButtonRow>
                <CommonButton label={getContentLanguage(selectedLanguage, 'MENU_PRODUCT_CUSTOMIZE', 'ADD_TO_CART')}
                    onClick={() => { addItem() }} />
            </ButtonRow>
        </div>
        <Modal
            show={showOptionPriceModal}
            onHide={() => setShowOptionPriceModal(!showOptionPriceModal)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="mp-button-container">
                    {!isNull(state) && state.optionBasedPricing.map(e => (
                        <CommonButton
                            label={`${e.label} - ${e.price}`}
                            onClick={() => { handleAddWithPrice(e) }}
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
        <ToastContainer />
        <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
    </>)
}

export default MenuProductCustomize