const addLocationRecord = async (db, data) => {
    const doc = await db.location.insert({
        locationId: data.locationId,
        locationIdText: data.locationIdText,
        name: data.name,
        city: data.city,
        state: data.state,
        country: data.country,
        currencyLabel: data.currencyLabel,
        currencySymbol: data.currencySymbol,
        currencyRegion: data.currencyRegion,
        businessId: data.businessId,
        orderNumber: data.orderNumber,
        orderPaymentModes: data.orderPaymentModes,
        createdOn: data.createdOn,
        updatedOn: data.updatedOn
    });
    return doc.toJSON();
};

const getLocationRecordByBusinessId = async (db, businessId) => {
    const doc = await db.location.findOne().where('businessId').eq(businessId).exec();
    return doc ? doc.toJSON() : null;
};

const deleteLocationRecord = async (db, businessId) => {
    const doc = await db.location.findOne().where('businessId').eq(businessId).exec();
    if (doc) {
        await doc.remove();
        return true;
    }
    return false;
};

export { addLocationRecord, getLocationRecordByBusinessId, deleteLocationRecord }