export const categorySchema = {
    title: 'categorySchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "categoryIdText",
    properties: {
        categoryId: {
            type: 'number',
            "maxLength": 99999
        },
        categoryIdText: {
            type: 'string',
            "maxLength": 99999
        },
        name: {
            type: 'string',
        },
        nameArabic: {
            type: 'string',
        },
        nameUrdu: {
            type: 'string',
        },
        nameSpanish: {
            type: 'string',
        },
        nameFrench: {
            type: 'string',
        },
        remarks: {
            type: 'string',
        },
        status: {
            type: 'number',
        },
        businessId: {
            type: 'number',
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};