import { RxSchema } from 'rxdb';
import { createRxDatabase, addRxPlugin, RxDatabase } from 'rxdb';
// import pouchdbAdapterMemory from 'pouchdb-adapter-memory';
import { userSchema } from './scheme/userSchema'
import { categorySchema } from './scheme/categorySchema'
import { productSchema } from './scheme/productSchema'
import { orderSchema } from './scheme/orderSchema'
import { businessSchema } from './scheme/businessSchema'
import { locationSchema } from './scheme/locationSchema'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';

addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

let dbInstance;
// let existingSchemaVersion;

// addRxPlugin(pouchdbAdapterMemory);

export const createDatabase = async () => {
    try {
        if (dbInstance) {
            return dbInstance; // Return existing database instance if available
        }

        const db = await createRxDatabase({
            name: 'areabiteslocaldb',
            storage: getRxStorageDexie(),
            adapter: 'memory',
        });

        await userCollection.preCreate(db)
        await categoryCollection.preCreate(db)
        await productCollection.preCreate(db)
        await orderCollection.preCreate(db)
        await businessCollection.preCreate(db)
        await locationCollection.preCreate(db)

        // const existingCollection = db.collections.users;
        // const existingSchema = existingCollection.schema.jsonSchema;
        // const existingSchemaVersion = existingCollection.schema.version;

        // // Check if the existing schema matches the current schema
        // const isNewSchema = !RxSchema.isInstanceOf(existingSchema, userSchema);

        // if (isNewSchema) {
        //     console.log('Updating schema...');

        //     // Perform schema migration or any other logic for schema changes
        //     // For example, you could iterate over existing documents and update their structure
        //     // or migrate data to new fields

        //     // Example: Adding new field 'age' to existing documents
        //     if (existingSchemaVersion === 0) {
        //         const allDocs = await existingCollection.find().exec();
        //         await Promise.all(allDocs.map(async (doc) => {
        //             await doc.update({ $set: { age: 0 } }); // Set default value or handle accordingly
        //         }));
        //     }

        //     // Update the collection schema with the new schema
        //     await existingCollection.updateSchema(newUserSchema);
        // }

        dbInstance = db; // Store the database instance for future use
        return db;
    } catch (error) {
        if (error.message.includes('exists')) {
            // Database already exists, handle accordingly
            console.log('Database already exists');
            return dbInstance; // Return existing database instance
        } else {
            // Other error occurred, handle accordingly
            console.error('Error creating database:', error);
            throw error;
        }
    }
};

const userCollection = {
    async preCreate(database) {
        await database.addCollections({
            user: {
                schema: userSchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}

const categoryCollection = {
    async preCreate(database) {
        await database.addCollections({
            category: {
                schema: categorySchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}

const productCollection = {
    async preCreate(database) {
        await database.addCollections({
            product: {
                schema: productSchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}

const orderCollection = {
    async preCreate(database) {
        await database.addCollections({
            order: {
                schema: orderSchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}

const businessCollection = {
    async preCreate(database) {
        await database.addCollections({
            business: {
                schema: businessSchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}

const locationCollection = {
    async preCreate(database) {
        await database.addCollections({
            location: {
                schema: locationSchema,
                migrationStrategies: {
                    1: function (oldDoc) {
                        const oldSchemaProperties = Object.keys(oldDoc);
                        const newSchemaProperties = Object.keys(userSchema.properties);

                        // Iterate over new schema properties and add them to existing documents if missing
                        newSchemaProperties.forEach(property => {
                            if (!oldSchemaProperties.includes(property)) {
                                oldDoc[property] = null; // Initialize new field with a default value
                            }
                        });
                    }
                }
            }
        })
    }
}