const addBusinessRecord = async (db, data) => {
    const doc = await db.business.insert({
        businessId: data.businessId,
        businessIdText: data.businessIdText,
        userId: data.userId,
        businessName: data.businessName,
        businessNameArabic: data.businessNameArabic ? data.businessNameArabic : '',
        businessNameUrdu: data.businessNameUrdu ? data.businessNameUrdu : '',
        businessNameSpanish: data.businessNameSpanish ? data.businessNameSpanish : '',
        businessNameFrench: data.businessNameFrench ? data.businessNameFrench : '',
        image: data.image,
        status: data.status,
        orderPaymentModes: data.orderPaymentModes,
        createdOn: data.createdOn,
        updatedOn: data.updatedOn
    });
    return doc.toJSON();
};

const getBusinessRecordByUserId = async (db, userId) => {
    const doc = await db.business.findOne().where('userId').eq(userId).exec();
    return doc ? doc.toJSON() : null;
};

const deleteBusinessRecord = async (db, userId) => {
    const doc = await db.business.findOne().where('userId').eq(userId).exec();
    if (doc) {
        await doc.remove();
        return true;
    }
    return false;
};

export { addBusinessRecord, getBusinessRecordByUserId, deleteBusinessRecord }