import { CartActionTypes } from './cart.types'

//Add Item
export const addItemInCart = data => ({
    type: CartActionTypes.ADD_ITEM_IN_CART,
    payload: data
})

export const reduceItemQty = data => ({
    type: CartActionTypes.REDUCE_QUANTITY,
    payload: data
})

export const addAdditionalDiscount = data => ({
    type: CartActionTypes.ADDITIONAL_DISCOUNT,
    payload: data
})

export const displayAddCartBannerAct = data => ({
    type: CartActionTypes.DISPLAY_ADD_CART_BANNER,
    payload: data
})
export const hideAddCartBannerAct = data => ({
    type: CartActionTypes.HIDE_ADD_CART_BANNER,
    payload: data
})