export const locationSchema = {
    title: 'locationSchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "locationIdText",
    properties: {
        locationId: {
            type: 'number',
            "maxLength": 99999
        },
        locationIdText: {
            type: 'string',
            "maxLength": 99999
        },
        name: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
        currencyLabel: {
            type: 'string',
        },
        currencySymbol: {
            type: 'string',
        },
        currencyRegion: {
            type: 'string',
        },
        businessId: {
            type: 'number',
        },
        orderNumber: {
            type: 'number',
        },
        orderPaymentModes: {
            type: 'array',
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};