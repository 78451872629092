import { displayConsoleLog, isEmpty } from "../../utils/Utils";

const addOrderRecord = async (db, data) => {
    const recordList = await db.order.find().exec()
    let orderId
    let orderNumber
    if (isEmpty(recordList)) {
        orderId = 1
    } else {
        let mapRecordList = recordList.map(cat => cat.toJSON())
        let lastRecord = mapRecordList[mapRecordList.length - 1]
        orderId = parseFloat(lastRecord.orderId) + 1
        orderNumber = parseFloat(lastRecord.orderNumber) + 1
    }
    const doc = await db.order.insert({
        orderId: orderId,
        orderIdText: `${orderId}`,
        orderStatus: data.orderStatus ? data.orderStatus : '',
        orderNumber: orderNumber,
        contactPersonNumber: data.contactPersonNumber ? parseFloat(data.contactPersonNumber) : 0,
        dineInTableNumber: data.dineInTableNumber ? data.dineInTableNumber : '',
        curbsideSlotNumber: data.curbsideSlotNumber ? data.curbsideSlotNumber : '',
        deliveryTerminalNumber: data.deliveryTerminalNumber ? data.deliveryTerminalNumber : '',
        roomNumber: data.roomNumber ? data.roomNumber : '',
        paymentStatus: data.paymentStatus ? data.paymentStatus : '',
        paymentMode: data.paymentMode ? data.paymentMode : '',
        paymentModeName: data.paymentModeName ? data.paymentModeName : '',
        discountAmount: data.discountAmount ? parseFloat(data.discountAmount).toFixed(2) : 0,
        subTotal: data.subTotal ? parseFloat(data.subTotal).toFixed(2) : 0,
        taxAmount: data.taxAmount ? parseFloat(data.taxAmount).toFixed(2) : 0,
        deliveryCharges: data.deliveryCharges ? parseFloat(data.deliveryCharges).toFixed(2) : 0,
        grandTotal: data.grandTotal ? parseFloat(data.grandTotal).toFixed(2) : 0,
        businessId: data.businessId ? parseFloat(data.businessId) : 0,
        locationId: data.locationId ? parseFloat(data.locationId) : 0,
        otherInformation: data.otherInformation ? data.otherInformation : '',
        itemsList: data.itemsList ? data.itemsList : [],
        newItemList: data.newItemList ? data.newItemList : [],
        availableItemList: data.availableItemList ? data.availableItemList : [],
        unavailableItemList: data.unavailableItemList ? data.unavailableItemList : [],
        processedItems: data.processedItems ? data.processedItems : [],
        refundAmount: data.refundAmount ? parseFloat(data.refundAmount).toFixed(2) : 0,
        currencyLabel: data.currencyLabel ? data.currencyLabel : '',
        currencySymbol: data.currencySymbol ? data.currencySymbol : '',
        currencyRegion: data.currencyRegion ? data.currencyRegion : '',
        createdOn: new Date(),
        updatedOn: new Date()
    });
    return doc.toJSON();
};

const getAllOrderRecordByBusinessId = async (db, businessId) => {
    try {
        const doc = await db.order.find().where('businessId').eq(businessId).exec();
        if (doc) {
            return doc.map(ordrItem => ordrItem.toJSON());
        } else {
            return null
        }
    } catch (err) {
        displayConsoleLog('err', err)
    }

};

export { addOrderRecord, getAllOrderRecordByBusinessId }