const API_ENDPOINTS = {
    account: {
        signIn: 'user/login',
        signUp: 'user/businessRegistration',
        customerLogin: 'customer/login',
        forgotPassword: 'user/forgotPassword',
        changePassword: 'user/changePassword',
        userDetail: 'user/detail',
        supportRegistration: 'user/supportRegistration',
        userRegistration: 'user/userRegistration',
        updateNotificationToken: 'user/updateNotificationToken',
        operatorRegistration: 'user/operatorRegistration',
        operatorList: 'user/operatorList',
        operatorDetail: 'user/operatorDetail',
        updateOperatorLocation: 'user/updateOperatorLocation',
        registerBusinessUser: 'user/registerBusinessUser',
        businessUserList: 'user/businessUserList',
        mapUserWithBusiness: 'user/mapUserWithBusiness',
        updateCustomerDetails: 'user/updateCustomerDetails'
    },
    branch: {
        branchList: 'location/list',
        addBranch: 'location/addLocation',
        updateBranch: 'location/update',
        branchDetail: 'location/detail',
        restaurantAccessRegister: 'user/createRestaurantAccess',
        updateLocationOrderingStatus: 'location/updateOrderingStatus'
    },
    restaurant: {
        addRestaurant: 'user/addNewBusiness',
        restaurantList: 'user/userBusinessList',
        restaurantDetail: 'user/businessDetail',
        businessListByLocation: 'user/businessListByLocation',
        updateBusinessOrderingStatus: 'user/updateBusinessOrdering',
        updateRestaurantDetails: 'user/updateBusinessDetails'
    },
    location: {
        countryList: 'https://api.countrystatecity.in/v1/countries',
        stateList: 'states',
        cityList: 'cities'
    }
}

export default API_ENDPOINTS