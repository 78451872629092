import { displayConsoleLog, isEmpty } from "../../utils/Utils";

const addCategoryRecord = async (db, data) => {
    const recordList = await db.category.find().exec()
    let categoryId
    if (isEmpty(recordList)) {
        categoryId = 1
    } else {
        let mapRecordList = recordList.map(cat => cat.toJSON())
        let lastRecord = mapRecordList[mapRecordList.length - 1]
        categoryId = parseFloat(lastRecord.categoryId) + 1
    }
    const doc = await db.category.insert({
        categoryId: categoryId,
        categoryIdText: `${categoryId}`,
        name: data.name ? data.name : '',
        nameArabic: data.nameArabic ? data.nameArabic : '',
        nameUrdu: data.nameUrdu ? data.nameUrdu : '',
        nameSpanish: data.nameSpanish ? data.nameSpanish : '',
        nameFrench: data.nameFrench ? data.nameFrench : '',
        remarks: data.remarks ? data.remarks : '',
        status: 1,
        businessId: data.businessId ? data.businessId : 0,
        createdOn: new Date(),
        updatedOn: new Date()
    });
    return doc.toJSON();
};

const getAllCategoryRecordByBusinessId = async (db, businessId) => {
    try {
        const doc = await db.category.find().where('businessId').eq(businessId).exec();
        if (doc) {
            return doc.map(catItem => catItem.toJSON());
        } else {
            return null
        }
    } catch (err) {
        displayConsoleLog('err', err)
    }

};

export { addCategoryRecord, getAllCategoryRecordByBusinessId }