import React, { useEffect, useState, useContext } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayConsoleLog,
    displayCurrencySymbolLeft,
    displayErrorToast, displaySuccessToast, getCategoryLocalText, getProductLocalText, getProductMeasurmentUnitOrder, isEmpty, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, priceWithSymbol, screenCaptureAnalytics
} from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from '../../../utils/AppConstants';
import { config } from '../../../config'
import Select from 'react-select'
import TopButton from '../../../components/button/top-button/topButton'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import { PRODUCT_MEASUREMENT_UNIT_LIST } from "../../../utils/ReservationType";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import CommonInputHalf from "../../../components/input/common-input-half/commonInputHalf";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import DatabaseContext from "../../../database/dabaseContext";
import { getAllProductRecordByBusinessId, updateProductStatus } from '../../../database/operation/productOperation'
import { getContentLanguage } from "../../../utils/ContentLanguage";

const ProductList = props => {
    const { user } = useSelector(state => state.user)
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let isFirstTime = false
    const PRODUCT_LIST = 'PRODUCT_LIST'
    const ADD_PRODUCT = 'ADD_PRODUCT'
    const PRODUCT_DETAIL = 'PRODUCT_DETAIL'
    const [productList, setProductList] = useState([])
    const { database, selectedLanguage } = useContext(DatabaseContext)

    useEffect(() => {
        const data = {
            screen: 'PRODUCT_LIST',
            screenClass: 'PRODUCT_LIST'
        }
        screenCaptureAnalytics(data)
        fetchProductList()
    }, [])

    const fetchProductList = async () => {
        try {
            let products = await getAllProductRecordByBusinessId(database, user.data.userDetails.businessDetails.businessId)
            if (!isEmpty(products)) {
                setProductList(products)
            } else {
                setProductList([])
            }
        } catch (error) {
            console.log('err', error);
        }

    }

    const addProductClickHandle = () => {
        if (checkFunctionalityPermission(ADD_PRODUCT) === true) {
            navigate(ROUTES.addProduct)
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const enableClickHandle = item => {
        updateProductStatus(database, item.itemId, 1).then((data) => {
            displaySuccessToast(getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRODUCT_ENABLE_SUCCESS', 'PRODUCT'))
            fetchProductList()
        }).catch((error) => {
            displayConsoleLog('error', error)
            displayErrorToast(getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRODUCT_ENABLE_ERROR', 'PRODUCT'))
        })
    }
    const disableClickHandle = item => {
        updateProductStatus(database, item.itemId, 0).then((data) => {
            displaySuccessToast(getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRODUCT_DISABLE_SUCCESS', 'PRODUCT'))
            fetchProductList()
        }).catch((error) => {
            displayErrorToast(getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRODUCT_DISABLE_ERROR', 'PRODUCT'))
        })
    }

    const handleCardClick = element => {
        if (checkFunctionalityPermission(PRODUCT_DETAIL) === true) {
            navigate(ROUTES.updateProduct, { state: element.itemId });
        } else {
            displayErrorToast(APP_CONSTANTS.UNAUTHORIZE_TO_PERFORM_OPERATION_MSG)
        }
    }

    const checkFunctionalityPermission = (itemType) => {
        return true
        // if (isLoggedInUserEmployee(user) === true) {
        //     const itemObj = user.data.userDetails?.employeeDetails.permissions.find(item => (item.type === APP_CONSTANTS.MENU_ITEM_TYPE.PRODUCT && item.businessId === employeeSelectedRestaurant && item.locationId === employeeSelectedLocation))
        //     if (itemObj !== undefined && !isNull(itemObj)) {
        //         if (itemType === PRODUCT_LIST) {
        //             if (itemObj.productList === true) {
        //                 return true
        //             } else {
        //                 return false
        //             }
        //         } else if (itemType === PRODUCT_DETAIL) {
        //             if (itemObj.editProduct === true) {
        //                 return true
        //             } else {
        //                 return false
        //             }
        //         } else if (itemType === ADD_PRODUCT) {
        //             if (itemObj.addProduct === true) {
        //                 return true
        //             } else {
        //                 return false
        //             }
        //         }
        //     } else return false
        // } else {
        //     return true
        // }
    }

    const handleViewCategories = () => {
        navigate(ROUTES.categoryList)
    }

    const getServingValue = (key) => {
        if (isEmpty(key)) {
            return '-'
        }
        const result = PRODUCT_MEASUREMENT_UNIT_LIST.find(e => e.key === key)
        if (!isNull(result)) {
            return result.value
        } else return '-'
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow classname="margin-top-10">
                    {/* <ScreenLabel label='Menu Items' /> */}
                    {(!isEmpty(user) && !isEmpty(user.data.userDetails.businessDetails)) ? (
                        <div className="cmc-label-container mt-2">
                            <div className="mcp-logo-container">
                                <img className="img" src={require('../../../assets/logoSingle.png')} alt="img" />
                            </div>
                            {/* <div className="mcp-restaurant-label">{user.data.userDetails.businessDetails.businessName}</div> */}
                            <div className="mcp-restaurant-label">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'MENU_ITEMS', 'PRODUCT')}</div>
                        </div>
                    ) :
                        (<div className="cmc-label-container">
                            <ScreenLabel label={getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'MENU_ITEMS', 'PRODUCT')} />
                        </div>)
                    }
                    <TopButton
                        label={getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'ADD_PRODUCT', 'PRODUCT')}
                        isInverted={true}
                        onClick={() => addProductClickHandle()} />
                </CommonInputRow>
                {/* <div className="add-p-btn-container">
                    <TopButton
                        label='View Categories'
                        onClick={() => handleViewCategories()} />
                </div> */}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && !isEmpty(productList) &&
                    <div className="p-price-warning mt-4">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRICE_INCLUDE_TAX', 'PRODUCT')}</div>
                }
                {checkFunctionalityPermission(PRODUCT_LIST) === true && <div className="table-scroll-container">
                    {!isEmpty(productList) && <table class="table table-hover margin-top-10">
                        <thead>
                            <tr>
                                <th scope="col" className="rl-table-col-title">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'IMAGE', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'NAME', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'CATEGORY', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'PRICE', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'OPTION_BASED_PRICE', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'SERVING', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'AVAILAVBILITY', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'STATUS', 'PRODUCT')}</th>
                                <th scope="col" className="rl-table-col-title text-center">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'ACTION', 'PRODUCT')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList.map(element => (
                                <tr className="rl-table-row-container">
                                    <td className="rl-table-col-item">
                                        <div className="p-image-container">
                                            {!isEmpty(element.image) && <img src={element.image} className="img" alt="..." />}
                                            {isEmpty(element.image) && <div className="p-default-img" />}
                                        </div>
                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{getProductLocalText(selectedLanguage, element)}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{getCategoryLocalText(selectedLanguage, element)}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        {!element.isOptionBasedPricing && <div>{priceWithSymbol(element, element.finalPrice)}</div>}
                                        {element.isOptionBasedPricing && <div>
                                            {element.optionBasedPricing.map(e => (
                                                <div>{e.label} - {priceWithSymbol(element, e.price)}</div>
                                            ))}
                                        </div>}
                                    </td>
                                    <td className="rl-table-col-item p-item-container text-center">{element.isOptionBasedPricing ? 'Yes' : 'No'}</td>
                                    <td className="rl-table-col-item p-item-container text-center">{getProductMeasurmentUnitOrder(element.measurementQuantity, element.measurementUnit)}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="p-availability-item-container">
                                            {element.isBreakfast === true && <div className="p-availability-container">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'BREAKFAST_SYMBOL', 'PRODUCT')}</div>}
                                            {element.isLunch === true && <div className="p-availability-container">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'LUNCH_SYMBOL', 'PRODUCT')}</div>}
                                            {element.isDinner === true && <div className="p-availability-container">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'DINNER_SYMBOL', 'PRODUCT')}</div>}
                                            {element.is24Hour === true && <div className="p-availability-container">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'FULL_DAY_SYMBOL', 'PRODUCT')}</div>}
                                        </div>
                                    </td>
                                    {/* <td className="rl-table-col-item text-center">{element.locationList ? element.locationList.length : 0}</td> */}
                                    <td className={`rl-table-col-item p-item-container text-center ${element.status === 1 ? 'loc-active' : 'loc-in-active'}`}>{element.status === 1 ? getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'ACTIVE', 'PRODUCT') : getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'IN_ACTIVE', 'PRODUCT')}</td>
                                    <td className="rl-table-col-item p-item-container text-center">
                                        <div className="d-flex flex-row justify-content-evenly align-items-center">
                                            <div className="btn p-card-btn" onClick={() => handleCardClick(element)}>{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'EDIT', 'PRODUCT')}</div> {element.status === 1 ?
                                                (<div className="btn p-card-btn" onClick={() => disableClickHandle(element)}>{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'DISABLE', 'PRODUCT')}</div>) :
                                                (<div className="btn p-card-btn" onClick={() => enableClickHandle(element)}>{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'ENABLE', 'PRODUCT')}</div>)}
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>}
                </div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === true && isEmpty(productList) && <div className="content-error">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'NO_PRODUCT_FOUND', 'PRODUCT')}</div>}
                {checkFunctionalityPermission(PRODUCT_LIST) === false && <div className="content-error">{getContentLanguage(selectedLanguage, 'PRODUCT_LIST', 'NOT_AUTHROZISE', 'PRODUCT')}</div>}
            </CommonScreenContent>
            <ToastContainer />
            <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
        </>
    )
}

export default ProductList