export const CurrencyLabel = [
    {
        "currency": "Bulgarian lev",
        "code": "BGN",
        "symbol": "лв",
        "region": "Europe"
    },
    {
        "currency": "Swiss franc",
        "code": "CHF",
        "symbol": "CHF",
        "region": "Europe"
    },
    {
        "currency": "Czech koruna",
        "code": "CZK",
        "symbol": "Kč",
        "region": "Europe"
    },
    {
        "currency": "Danish krone",
        "code": "DKK",
        "symbol": "kr",
        "region": "Europe"
    },
    {
        "currency": "Euro",
        "code": "EUR",
        "symbol": "€",
        "region": "Europe"
    },
    {
        "currency": "Pounds sterling",
        "code": "GBP",
        "symbol": "£",
        "region": "Europe"
    },
    {
        "currency": "Croatian Kuna",
        "code": "HRK",
        "symbol": "kn",
        "region": "Europe"
    },
    {
        "currency": "Georgian lari",
        "code": "GEL",
        "symbol": "₾",
        "region": "Europe"
    },
    {
        "currency": "Hungarian forint",
        "code": "HUF",
        "symbol": "ft",
        "region": "Europe"
    },
    {
        "currency": "Norwegian krone",
        "code": "NOK",
        "symbol": "kr",
        "region": "Europe"
    },
    {
        "currency": "Polish zloty",
        "code": "PLN",
        "symbol": "zł",
        "region": "Europe"
    },
    {
        "currency": "Russian ruble",
        "code": "RUB",
        "symbol": "₽",
        "region": "Europe"
    },
    {
        "currency": "Romanian leu",
        "code": "RON",
        "symbol": "lei",
        "region": "Europe"
    },
    {
        "currency": "Swedish krona",
        "code": "SEK",
        "symbol": "kr",
        "region": "Europe"
    },
    {
        "currency": "Turkish lira",
        "code": "TRY",
        "symbol": "₺",
        "region": "Europe"
    },
    {
        "currency": "Ukrainian hryvna",
        "code": "UAH",
        "symbol": "₴",
        "region": "Europe"
    },
    {
        "currency": "Emirati dirham",
        "code": "AED",
        "symbol": "د.إ",
        "region": "Middle East and Africa"
    },
    {
        "currency": "Israeli shekel",
        "code": "ILS",
        "symbol": "₪",
        "region": "Middle East and Africa"
    },
    {
        "currency": "Kenyan shilling",
        "code": "KES",
        "symbol": "Ksh",
        "region": "Middle East and Africa"
    },
    {
        "currency": "Moroccan dirham",
        "code": "MAD",
        "symbol": ".د.م",
        "region": "Middle East and Africa"
    },
    {
        "currency": "Nigerian naira",
        "code": "NGN",
        "symbol": "₦",
        "region": "Middle East and Africa"
    },
    {
        "currency": "South african",
        "code": "ZAR",
        "symbol": "R",
        "region": "Middle East and Africa"
    },
    {
        "currency": "Brazilian real",
        "code": "BRL",
        "symbol": "R$",
        "region": "Americas"
    },
    {
        "currency": "Canadian dollars",
        "code": "CAD",
        "symbol": "$",
        "region": "Americas"
    },
    {
        "currency": "Chilean peso",
        "code": "CLP",
        "symbol": "$",
        "region": "Americas"
    },
    {
        "currency": "Colombian peso",
        "code": "COP",
        "symbol": "$",
        "region": "Americas"
    },
    {
        "currency": "Mexican peso",
        "code": "MXN",
        "symbol": "$",
        "region": "Americas"
    },
    {
        "currency": "Peruvian sol",
        "code": "PEN",
        "symbol": "S/.",
        "region": "Americas"
    },
    {
        "currency": "US dollar",
        "code": "USD",
        "symbol": "$",
        "region": "Americas"
    },
    {
        "currency": "Australian dollars",
        "code": "AUD",
        "symbol": "$",
        "region": "Asia"
    },
    {
        "currency": "Bangladeshi taka",
        "code": "BDT",
        "symbol": "৳",
        "region": "Asia"
    },
    {
        "currency": "Chinese yuan",
        "code": "CNY",
        "symbol": "¥",
        "region": "Asia"
    },
    {
        "currency": "Hong Kong dollar",
        "code": "HKD",
        "symbol": "$",
        "region": "Asia"
    },
    {
        "currency": "Indonesian rupiah",
        "code": "IDR",
        "symbol": "Rp",
        "region": "Asia"
    },
    {
        "currency": "Indian rupee",
        "code": "INR",
        "symbol": "₹",
        "region": "Asia"
    },
    {
        "currency": "Japanese yen",
        "code": "JPY",
        "symbol": "¥",
        "region": "Asia"
    },
    {
        "currency": "Malaysian ringgit",
        "code": "MYR",
        "symbol": "RM",
        "region": "Asia"
    },
    {
        "currency": "New Zealand dollar",
        "code": "NZD",
        "symbol": "$",
        "region": "Asia"
    },
    {
        "currency": "Philippine peso",
        "code": "PHP",
        "symbol": "₱",
        "region": "Asia"
    },
    {
        "currency": "Pakistani rupee",
        "code": "PKR",
        "symbol": "Rs",
        "region": "Asia"
    },
    {
        "currency": "Singapore dollar",
        "code": "SGD",
        "symbol": "$",
        "region": "Asia"
    },
    {
        "currency": "Korean won",
        "code": "KRW",
        "symbol": "₩",
        "region": "Asia"
    },
    {
        "currency": "Sri Lankan rupee",
        "code": "LKR",
        "symbol": "Rs",
        "region": "Asia"
    },
    {
        "currency": "Thai baht",
        "code": "THB",
        "symbol": "฿",
        "region": "Asia"
    },
    {
        "currency": "Vietnamese dong",
        "code": "VND",
        "symbol": "₫",
        "region": "Asia"
    }
]