export const orderSchema = {
    title: 'orderSchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "orderIdText",
    properties: {
        orderId: {
            type: 'number',
            "maxLength": 99999
        },
        orderIdText: {
            type: 'string',
            "maxLength": 99999
        },
        orderStatus: {
            type: 'string',
        },
        orderNumber: {
            type: 'number',
        },
        contactPersonNumber: {
            type: 'number',
        },
        dineInTableNumber: {
            type: 'string',
        },
        curbsideSlotNumber: {
            type: 'string',
        },
        deliveryTerminalNumber: {
            type: 'string',
        },
        roomNumber: {
            type: 'string',
        },
        paymentStatus: {
            type: 'string',
        },
        paymentMode: {
            type: 'string',
        },
        paymentModeName: {
            type: 'string',
        },
        subTotal: {
            type: 'number',
        },
        discountAmount: {
            type: 'number',
        },
        taxAmount: {
            type: 'number',
        },
        deliveryCharges: {
            type: 'number',
        },
        grandTotal: {
            type: 'number',
        },
        businessId: {
            type: 'number',
        },
        locationId: {
            type: 'number',
        },
        otherInformation: {
            type: 'string',
        },
        itemsList: {
            type: 'array',
        },
        newItemList: {
            type: 'array',
        },
        availableItemList: {
            type: 'array',
        },
        unavailableItemList: {
            type: 'array',
        },
        processedItems: {
            type: 'array',
        },
        refundAmount: {
            type: 'number',
        },
        currencyLabel: {
            type: 'string',
        },
        currencySymbol: {
            type: 'string',
        },
        currencyRegion: {
            type: 'string',
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};