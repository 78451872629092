import React, { useEffect, useState, useContext } from 'react'
import './styles.css'
import Loader from '../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import {
    displayErrorToast, displaySuccessToast, isEmpty, isEnglishText, isInternetConnected, isNull, screenCaptureAnalytics
} from '../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS, ROUTES } from '../../utils/AppConstants';
import { loginUserRequest, displaySideBarAct, loginSuccess } from '../../redux/user/user.action'
import CommonButton from '../../components/button/common-button/commonButton'
import CommonInputFull from '../../components/input/common-input-full/commonInputFull';
import FormFieldLabel from '../../components/label/form-field-label/formFieldLabel';
import ButtonRow from '../../components/button/button-row/buttonRow';
import DatabaseContext from '../../database/dabaseContext';
import { getUserRecordByEmail, addUserRecord } from '../../database/operation/userOperation';
import { addBusinessRecord, getBusinessRecordByUserId } from '../../database/operation/businessOperation';
import { addLocationRecord, getLocationRecordByBusinessId } from '../../database/operation/locationOperation';
import { decryptStringData, encryptStringData } from '../../utils/DataDecryptionUtils';
import { getContentLanguage } from '../../utils/ContentLanguage';

const SignInScreen = props => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const { user, isLogin, loginErrorData } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { database, selectedLanguage } = useContext(DatabaseContext)
    const [isLocalData, setIsLocalData] = useState(false)

    useEffect(() => {
        const data = {
            screen: 'SIGN_IN',
            screenClass: 'SIGN_IN'
        }
        screenCaptureAnalytics(data)
    }, [])

    useEffect(() => {
        logoutUser()
    }, [])

    const logoutUser = () => {
        sessionStorage.clear()
        dispatch({ type: APP_CONSTANTS.LOGOUT })
    }

    const mapUserObject = (userObj) => {
        if (!isEmpty(userObj)) {
            const data = {
                ...userObj,
                userIdText: `${userObj.userId}`,
                password: encryptStringData(password),
            }
            return data
        } else {
            return {}
        }
    }

    const mapBusinessObject = (businessObj) => {
        if (!isEmpty(businessObj)) {
            const data = {
                businessId: businessObj.businessId,
                businessIdText: `${businessObj.businessId}`,
                userId: businessObj.userId[0],
                businessName: businessObj.businessName,
                businessNameArabic: businessObj.businessNameArabic,
                image: businessObj.image,
                status: businessObj.status,
                createdOn: businessObj.createdOn,
                updatedOn: businessObj.updatedOn,
                orderPaymentModes: businessObj.orderPaymentModes
            }
            return data
        } else {
            return {}
        }
    }

    const mapLocationObject = (locationObj) => {
        if (!isEmpty(locationObj)) {
            const data = {
                locationId: locationObj.locationId,
                locationIdText: `${locationObj.locationId}`,
                name: locationObj.name,
                city: locationObj.city,
                state: locationObj.state,
                country: locationObj.country,
                currencyLabel: locationObj.currencyLabel,
                currencySymbol: locationObj.currencySymbol,
                currencyRegion: locationObj.currencyRegion,
                businessId: locationObj.businessId,
                orderNumber: locationObj.orderNumber,
                createdOn: locationObj.createdOn,
                updatedOn: locationObj.updatedOn,
                orderPaymentModes: locationObj.orderPaymentModes
            }
            return data
        } else {
            return {}
        }
    }

    useEffect(() => {
        if (isLogin) {
            setLoading(false)
            displaySuccessToast(getContentLanguage(selectedLanguage, 'SIGNIN', 'LOGIN_SUCCESSFULL'))
            sessionStorage.setItem(APP_CONSTANTS.USER, JSON.stringify(user.data))
            sessionStorage.setItem(APP_CONSTANTS.USER_TOKEN, user.data.authToken)
            if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.BUSINESS) {
                if (!isNull(user) && isEmpty(user.data.userDetails.isDataFromLocal)) {
                    let userObject = mapUserObject(user.data.userDetails)
                    let businessObject = mapBusinessObject(user.data.userDetails.businessDetails)
                    let locationObject = mapLocationObject(user.data.userDetails.locationDetails[0])
                    addUserRecord(database, userObject)
                        .then((_record) => {
                            return addBusinessRecord(database, businessObject)
                        })
                        .then((_record) => {
                            return addLocationRecord(database, locationObject)
                        })
                        .then((_record) => {
                            dispatch(displaySideBarAct(true))
                            navigate(ROUTES.placeOrder, { replace: true });
                        }).catch((error) => {
                            console.log('error', error);
                            console.log('Error while storing user details');
                        })
                }
            } else if (user.data.userDetails.userType === APP_CONSTANTS.USER_TYPE.ADMIN) {
                dispatch(displaySideBarAct(true))
                navigate(ROUTES.operatorList, { replace: true });
            } else {
                displayErrorToast(getContentLanguage(selectedLanguage, 'SIGNIN', 'USER_TYPE_NOT_ALLOWED'))
                logoutUser()
            }
        } else {
            if (loginErrorData) {
                setLoading(false)
                displayErrorToast(loginErrorData.message)
            }
        }
    }, [user, isLogin, loginErrorData])

    const handleSignIn = async () => {
        if (isEmpty(email)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'EMAIL_ERROR', null, false, false, true))
        } else if (!isEnglishText(email)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isEmpty(password)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PASSWORD_ERROR', null, false, false, true))
        } else if (!isEnglishText(password)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else {
            setLoading(true)
            if (database) {
                let emailId = email.toLowerCase()
                let userInfo = await getUserRecordByEmail(database, emailId)
                setLoading(false)
                if (userInfo) {
                    let storedPassword = decryptStringData(userInfo.password)
                    if (password === storedPassword) {
                        let businessObj = await getBusinessRecordByUserId(database, userInfo.userId)
                        let locationList = []
                        if (!isNull(businessObj)) {
                            let locationDetails = await getLocationRecordByBusinessId(database, businessObj.businessId)
                            if (!isNull(locationDetails)) {
                                locationList.push(locationDetails)
                                let responseData = {
                                    data: {
                                        authToken: '',
                                        userDetails: {
                                            ...userInfo,
                                            businessDetails: businessObj,
                                            locationDetails: locationList,
                                            isDataFromLocal: true
                                        }
                                    }

                                }
                                dispatch(loginSuccess(responseData))
                                dispatch(displaySideBarAct(true))
                                navigate(ROUTES.placeOrder, { replace: true });
                            }
                        }
                    } else {
                        displayErrorToast(getContentLanguage(selectedLanguage, 'SIGNIN', 'INCORRECT_PASSWORD'))
                    }

                } else {
                    if (isInternetConnected() === true) {
                        const data = {
                            email: emailId,
                            password: password
                        }
                        dispatch(loginUserRequest(data))
                    } else {
                        setLoading(false)
                        displayErrorToast(getContentLanguage(selectedLanguage, null, 'INTERNET_NOT_AVAILABLE', null, true))
                    }
                }
            }
        }
    }

    const handleForgotPassClick = () => {
        navigate(ROUTES.forgotPassword)
    }

    const createAccountClick = () => {
        navigate(ROUTES.signUp)
    }

    return (
        <>
            <div className='sign-in-parent-container'>
                <div className='sign-in-img-layout-container'>
                    <img src={require('../../assets/sign-in-banner2.jpg')} className='sign-up-img-container' />
                    <img src={require('../../assets/sign-in-banner-tab1.jpg')} className='sign-up-tab-img-container' />
                </div>
                <div className='sign-in-content-layout-container'>
                    <div className="sign-in-logo-container">
                        <img src={require('../../assets/logo.png')} alt='logo' className="img" />
                    </div>
                    <div className='sign-in-heading'>{getContentLanguage(selectedLanguage, 'SIGNIN', 'LOGIN_TO')} <span className='sign-in-heading-label'>{getContentLanguage(selectedLanguage, null, 'AREABITES', null, true)}</span></div>
                    <FormFieldLabel label={getContentLanguage(selectedLanguage, 'SIGNIN', 'LOGIN_WITH_REGISTERED_CREDENTIALS')} className='sign-in-sub-text-label margin-bottom-10' />
                    <CommonInputFull
                        id="email"
                        placeholder={getContentLanguage(selectedLanguage, null, 'EMAIL_ID', null, false, true)}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        language={selectedLanguage}
                        type="email" />
                    <CommonInputFull
                        id="password"
                        placeholder={getContentLanguage(selectedLanguage, null, 'PASSWORD', null, false, true)}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        language={selectedLanguage}
                        type="password" />
                    {/* <div className='si-forgot-password-label' onClick={() => handleForgotPassClick()}>FORGOT PASSWORD</div> */}
                    <ButtonRow>
                        <CommonButton label={getContentLanguage(selectedLanguage, 'SIGNIN', 'LOGIN')}
                            onClick={() => handleSignIn()}
                            isBig={true}
                        />
                    </ButtonRow>
                    <div className='sign-in-or-label'>{getContentLanguage(selectedLanguage, 'SIGNIN', 'OR_LABEL')}</div>
                    <div className='sign-in-create-account-label'>{getContentLanguage(selectedLanguage, 'SIGNIN', 'NOT_A_MEMBER')} <span className='sign-in-create-account-label-italic' onClick={() => { createAccountClick() }}>{getContentLanguage(selectedLanguage, 'SIGNIN', 'CREATE_ACCOUNT')}</span></div>
                </div>
            </div>
            <ToastContainer />
            <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
        </>
    )
}

export default SignInScreen