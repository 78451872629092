import { all, call } from 'redux-saga/effects'
import {
    customerLoginReq, loginUserReq, registerUserReq,
    branchListReq, subscriptionListReq, userDetailReq,
    addBranchReq, addRestaurantReq, restaurantListReq, updateBranchReq,
    restuarantDetailReq, changePasswordReq, forgotPasswordReq,
    cityListReq, countryListReq, stateListReq, agentLoginReq,
    businessListReq, terminalLoginReq, employeeLoginReq, supportRegistrationReq,
    ipLocationReq, userRegistrationReq, userUpdateUserNotificationTokenReq,
    restaurantAccessRegisterReq, branchDetailReq, operatorDetailReq, operatorListReq, operatorRegisterReq, updateOperatorLocationReq,
    businessListByLocationReq, updateBranchOrderingStatusReq, updateBusinessOrderingStatusReq,
    businessUserListReq, mapUserWithBusinessReq, registerBusinessUserReq, updateCustomerDetailsReq,
    updateRestaurantDetailsReq
} from './user/user.saga'

export default function* rootSaga() {
    yield all([
        call(customerLoginReq),
        call(loginUserReq),
        call(registerUserReq),
        call(branchListReq),
        call(subscriptionListReq),
        call(userDetailReq),
        call(addBranchReq),
        call(addRestaurantReq),
        call(restaurantListReq),
        call(updateBranchReq),
        call(restuarantDetailReq),
        call(changePasswordReq),
        call(forgotPasswordReq),
        call(cityListReq),
        call(countryListReq),
        call(stateListReq),
        call(agentLoginReq),
        call(businessListReq),
        call(terminalLoginReq),
        call(employeeLoginReq),
        call(supportRegistrationReq),
        call(ipLocationReq),
        call(userRegistrationReq),
        call(userUpdateUserNotificationTokenReq),
        call(restaurantAccessRegisterReq),
        call(branchDetailReq),
        call(operatorDetailReq),
        call(operatorListReq),
        call(operatorRegisterReq),
        call(updateOperatorLocationReq),
        call(businessListByLocationReq),
        call(updateBranchOrderingStatusReq),
        call(updateBusinessOrderingStatusReq),
        call(businessUserListReq),
        call(mapUserWithBusinessReq),
        call(registerBusinessUserReq),
        call(updateCustomerDetailsReq),
        call(updateRestaurantDetailsReq)
    ])
}