import { isEmpty } from "./Utils"

export const ContentLanguage = {
    GENERAL: {
        en: {
            PLEASE_WAIT: 'Please wait...',
            INTERNET_NOT_AVAILABLE: 'Internet connection not available. Try again later',
            AREABITES: 'Areabites',
            SUBMIT: 'Submit',
            SOMETHING_WENT_WRONG: 'Something went wrong. Please try again.',
            UPDATE: 'Update',
            ENGLISH_LANGUAGE_ONLY: 'Please enter in English language only',
            PLACE_ORDER_TAB: 'Place Order',
            PRODUCTS_TAB: 'Products',
            LOGOUT_TAB: 'Logout',
            SELECT_LANGUAGE: 'Select Language'
        },
        ar: {
            PLEASE_WAIT: 'انتظر من فضلك...',
            INTERNET_NOT_AVAILABLE: 'الاتصال بالإنترنت غير متوفر. حاول مرة أخرى في وقت لاحق',
            AREABITES: 'المنطقة',
            SUBMIT: 'يُقدِّم',
            SOMETHING_WENT_WRONG: 'هناك خطأ ما. حاول مرة اخرى.',
            UPDATE: 'تحديث',
            ENGLISH_LANGUAGE_ONLY: 'يرجى الدخول باللغة الإنجليزية فقط',
            PLACE_ORDER_TAB: 'مكان الامر',
            PRODUCTS_TAB: 'منتجات',
            LOGOUT_TAB: 'تسجيل خروج',
            SELECT_LANGUAGE: 'اختار اللغة'
        },
        ur: {
            PLEASE_WAIT: 'برائے مہربانی انتظار کریں...',
            INTERNET_NOT_AVAILABLE: 'انٹرنیٹ کنکشن دستیاب نہیں ہے۔ بعد میں دوبارہ کوشش کریں۔',
            AREABITES: 'ایریابیٹس',
            SUBMIT: 'جمع کرائیں',
            SOMETHING_WENT_WRONG: 'کچھ غلط ہو گیا. دوبارہ کوشش کریں.',
            UPDATE: 'اپ ڈیٹ',
            ENGLISH_LANGUAGE_ONLY: 'براہ کرم صرف انگریزی زبان میں درج کریں۔',
            PLACE_ORDER_TAB: 'حکم صادر کریں',
            PRODUCTS_TAB: 'مصنوعات',
            LOGOUT_TAB: 'لاگ آوٹ',
            SELECT_LANGUAGE: 'زبان منتخب کریں۔'
        },
        es: {
            PLEASE_WAIT: 'Espere por favor...',
            INTERNET_NOT_AVAILABLE: 'Conexión a Internet no disponible. Vuelve a intentarlo más tarde',
            AREABITES: 'mordeduras de área',
            SUBMIT: 'Entregar',
            SOMETHING_WENT_WRONG: 'Algo salió mal. Inténtalo de nuevo.',
            UPDATE: 'Actualizar',
            ENGLISH_LANGUAGE_ONLY: 'Por favor ingrese solo en idioma inglés',
            PLACE_ORDER_TAB: 'Realizar pedido',
            PRODUCTS_TAB: 'Productos',
            LOGOUT_TAB: 'Cerrar sesión',
            SELECT_LANGUAGE: 'Seleccione el idioma'
        },
        fr: {
            PLEASE_WAIT: "S'il vous plaît, attendez...",
            INTERNET_NOT_AVAILABLE: 'Connexion Internet non disponible. Réessayez plus tard',
            AREABITES: 'Zonebites',
            SUBMIT: 'Soumettre',
            SOMETHING_WENT_WRONG: "Quelque chose s'est mal passé. Veuillez réessayer.",
            UPDATE: 'Mise à jour',
            ENGLISH_LANGUAGE_ONLY: 'Veuillez entrer en anglais uniquement',
            PLACE_ORDER_TAB: 'Passer la commande',
            PRODUCTS_TAB: 'Des produits',
            LOGOUT_TAB: 'Se déconnecter',
            SELECT_LANGUAGE: 'Choisir la langue'
        },
    },
    PLACEHOLDER: {
        en: {
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            EMAIL_ID: 'Email Id',
            MOBILE_NUMBER: 'Mobile Number',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm Password',
            BUSINESS_NAME: 'Business Name',
            BUSINESS_NAME_ARABIC: 'Business Name Arabic',
            BUSINESS_NAME_URDU: 'Business Name Urdu',
            BUSINESS_NAME_SPANISH: 'Business Name Spanish',
            BUSINESS_NAME_FRENCH: 'Business Name French',
            SELECT_CURRENCY: 'Select Currency',
            SELECT_COUNTRY: 'Select Country',
            SELECT_STATE: 'Select State',
            SELECT_CITY: 'Select City',
            RESTAURANT_NAME: 'Restaurant Name',
            BRAND_NAME: 'Brand Name',
            PAYMENT_MODE: 'Payment Mode',
            PRODUCT_NAME: 'Product Name',
            PRODUCT_NAME_ARABIC: 'Product Name Arabic',
            PRODUCT_NAME_URDU: 'Product Name Urdu',
            PRODUCT_NAME_SPANISH: 'Product Name Spanish',
            PRODUCT_NAME_FRENCH: 'Product Name French',
            DESCRIPTION: 'Description',
            POINTS: 'Points',
            SERVING_TYPE: 'Serving Type',
            SERVING_QUANTITY: 'Serving Quantity',
            OPTION_NAME: 'Option Name',
            PRICE: 'Price',
            DISCOUNT_PERCENTAGE: 'Discount %',
            IS_TAXABLE: 'Is Taxable?',
            TAX_PERCENTAGE: 'Tax %',
            SELECT_CATEGORY: 'Select Category',
            BREAKFAST: 'Breakfast',
            LUNCH: 'Lunch',
            DINNER: 'Dinner',
            ALL_DAY: '24 Hours',
            CATEGORY_NAME: 'Category Name',
            CATEGORY_NAME_ARABIC: 'Category Name Arabic',
            CATEGORY_NAME_URDU: 'Category Name Urdu',
            CATEGORY_NAME_SPANISH: 'Category Name Spanish',
            CATEGORY_NAME_FRENCH: 'Category Name French',
            SPECIAL_REMARKS: 'Special Remarks',
            OPTION_LABEL: 'Option Label',
            OPTION_LABEL_ARABIC: 'Option Label Arabic',
            OPTION_LABEL_URDU: 'Option Label Urdu',
            OPTION_LABEL_SPANISH: 'Option Label Spanish',
            OPTION_LABEL_FRENCH: 'Option Label French',
            IS_DEFAULT: 'Is Default?',
            NAME: 'Name',
            NAME_ARABIC: 'Name Arabic',
            NAME_URDU: 'Name Urdu',
            NAME_SPANISH: 'Name Spanish',
            NAME_FRENCH: 'Name French',
            ADD_ON_LABEL: 'Add-On Label',
            ADD_ON_LABEL_ARABIC: 'Add-On Label Arabic',
            ADD_ON_LABEL_URDU: 'Add-On Label Urdu',
            ADD_ON_LABEL_SPANISH: 'Add-On Label Spanish',
            ADD_ON_LABEL_FRENCH: 'Add-On Label French',
        },
        ar: {
            FIRST_NAME: 'الاسم الأول',
            LAST_NAME: 'اسم العائلة',
            EMAIL_ID: 'عنوان الايميل',
            MOBILE_NUMBER: 'رقم الهاتف المحمول',
            PASSWORD: 'كلمة المرور',
            CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
            BUSINESS_NAME: 'الاسم التجاري',
            BUSINESS_NAME_ARABIC: 'الاسم التجاري عربي',
            BUSINESS_NAME_URDU: 'الاسم التجاري الأردية',
            BUSINESS_NAME_SPANISH: 'الاسم التجاري الاسباني',
            BUSINESS_NAME_FRENCH: 'الاسم التجاري فرنسي',
            SELECT_CURRENCY: 'اختر العملة',
            SELECT_COUNTRY: 'حدد الدولة',
            SELECT_STATE: 'اختر ولايه',
            SELECT_CITY: 'اختر مدينة',
            RESTAURANT_NAME: 'اسم المطعم',
            BRAND_NAME: 'اسم العلامة التجارية',
            PAYMENT_MODE: 'طريقة الدفع',
            PRODUCT_NAME: 'اسم المنتج',
            PRODUCT_NAME_ARABIC: 'اسم المنتج عربي',
            PRODUCT_NAME_URDU: 'اسم المنتج أوردو',
            PRODUCT_NAME_SPANISH: 'اسم المنتج اسباني',
            PRODUCT_NAME_FRENCH: 'اسم المنتج فرنسي',
            DESCRIPTION: 'وصف',
            POINTS: 'نقاط',
            SERVING_TYPE: 'نوع التقديم',
            SERVING_QUANTITY: 'كمية التقديم',
            OPTION_NAME: 'اسم الخيار',
            PRICE: 'سعر',
            DISCOUNT_PERCENTAGE: 'تخفيض ٪',
            IS_TAXABLE: 'هل خاضع للضريبة؟',
            TAX_PERCENTAGE: 'نسبة الضريبة',
            SELECT_CATEGORY: 'اختر الفئة',
            BREAKFAST: 'إفطار',
            LUNCH: 'غداء',
            DINNER: 'عشاء',
            ALL_DAY: '24 ساعة',
            CATEGORY_NAME: 'اسم التصنيف',
            CATEGORY_NAME_ARABIC: 'اسم الفئة عربي',
            CATEGORY_NAME_URDU: 'اسم الفئة الأردية',
            CATEGORY_NAME_SPANISH: 'اسم الفئة الاسبانية',
            CATEGORY_NAME_FRENCH: 'اسم الفئة الفرنسية',
            SPECIAL_REMARKS: 'كلمة خاصة',
            OPTION_LABEL: 'تسمية الخيار',
            OPTION_LABEL_ARABIC: 'خيار التسمية العربية',
            OPTION_LABEL_URDU: 'تسمية الخيار الأردية',
            OPTION_LABEL_SPANISH: 'تسمية الخيار الإسبانية',
            OPTION_LABEL_FRENCH: 'تسمية الخيار الفرنسية',
            IS_DEFAULT: 'هل هو الافتراضي؟',
            NAME: 'اسم',
            NAME_ARABIC: 'الاسم عربي',
            NAME_URDU: 'الاسم الأردية',
            NAME_SPANISH: 'اسم الاسبانية',
            NAME_FRENCH: 'اسم الفرنسية',
            ADD_ON_LABEL: 'تسمية الوظيفة الإضافية',
            ADD_ON_LABEL_ARABIC: 'إضافة على التسمية العربية',
            ADD_ON_LABEL_URDU: 'الوظيفة الإضافية التسمية الأردية',
            ADD_ON_LABEL_SPANISH: 'تسمية الوظيفة الإضافية الإسبانية',
            ADD_ON_LABEL_FRENCH: 'الوظيفة الإضافية التسمية الفرنسية',
        },
        ur: {
            FIRST_NAME: 'پہلا نام',
            LAST_NAME: 'آخری نام',
            EMAIL_ID: 'ای میل کا پتہ',
            MOBILE_NUMBER: 'موبائل فون کانمبر',
            PASSWORD: 'پاس ورڈ',
            CONFIRM_PASSWORD: 'پاس ورڈ کی تصدیق کریں۔',
            BUSINESS_NAME: 'کاروبار کا نام',
            BUSINESS_NAME_ARABIC: 'کاروباری نام عربی',
            BUSINESS_NAME_URDU: 'کاروباری نام اردو',
            BUSINESS_NAME_SPANISH: 'کاروباری نام ہسپانوی',
            BUSINESS_NAME_FRENCH: 'کاروباری نام فرانسیسی',
            SELECT_CURRENCY: 'کرنسی کا انتخاب کریں۔',
            SELECT_COUNTRY: 'ملک کا انتخاب کیجئے',
            SELECT_STATE: 'ریاست منتخب کریں۔',
            SELECT_CITY: 'شہر منتخب کریں۔',
            RESTAURANT_NAME: 'ریستوراں کا نام',
            BRAND_NAME: 'برانڈ کا نام',
            PAYMENT_MODE: 'ادائیگی کا طریقہ',
            PRODUCT_NAME: 'پروڈکٹ کا نام',
            PRODUCT_NAME_ARABIC: 'پروڈکٹ کا نام عربی',
            PRODUCT_NAME_URDU: 'پروڈکٹ کا نام اردو',
            PRODUCT_NAME_SPANISH: 'پروڈکٹ کا نام ہسپانوی',
            PRODUCT_NAME_FRENCH: 'پروڈکٹ کا نام فرانسیسی',
            DESCRIPTION: 'تفصیل',
            POINTS: 'پوائنٹس',
            SERVING_TYPE: 'سرونگ کی قسم',
            SERVING_QUANTITY: 'سرونگ کی مقدار',
            OPTION_NAME: 'آپشن کا نام',
            PRICE: 'قیمت',
            DISCOUNT_PERCENTAGE: 'رعایت %',
            IS_TAXABLE: 'کیا قابل ٹیکس ہے؟',
            TAX_PERCENTAGE: 'ٹیکس %',
            SELECT_CATEGORY: 'زمرہ منتخب کریں۔',
            BREAKFAST: 'ناشتہ',
            LUNCH: 'دوپہر کا کھانا',
            DINNER: 'رات کا کھانا',
            ALL_DAY: '24 گھنٹے',
            CATEGORY_NAME: 'قسم کا نام',
            CATEGORY_NAME_ARABIC: 'زمرہ کا نام عربی',
            CATEGORY_NAME_URDU: 'زمرہ کا نام اردو',
            CATEGORY_NAME_SPANISH: 'زمرہ کا نام ہسپانوی',
            CATEGORY_NAME_FRENCH: 'زمرہ کا نام فرانسیسی',
            SPECIAL_REMARKS: 'خصوصی ریمارکس',
            OPTION_LABEL: 'آپشن لیبل',
            OPTION_LABEL_ARABIC: 'آپشن لیبل عربی',
            OPTION_LABEL_URDU: 'آپشن لیبل اردو',
            OPTION_LABEL_SPANISH: 'آپشن لیبل ہسپانوی',
            OPTION_LABEL_FRENCH: 'آپشن لیبل فرانسیسی',
            IS_DEFAULT: 'ڈیفالٹ ہے؟',
            NAME: 'نام',
            NAME_ARABIC: 'عربی نام رکھیں',
            NAME_URDU: 'اردو کا نام رکھیں',
            NAME_SPANISH: 'ہسپانوی نام رکھیں',
            NAME_FRENCH: 'فرانسیسی کا نام لیں۔',
            ADD_ON_LABEL: 'ایڈ آن لیبل',
            ADD_ON_LABEL_ARABIC: 'ایڈ آن لیبل عربی',
            ADD_ON_LABEL_URDU: 'ایڈ آن لیبل اردو',
            ADD_ON_LABEL_SPANISH: 'ایڈ آن لیبل ہسپانوی۔',
            ADD_ON_LABEL_FRENCH: 'ایڈ آن لیبل فرانسیسی',
        },
        es: {
            FIRST_NAME: 'Nombre de pila',
            LAST_NAME: 'Apellido',
            EMAIL_ID: 'Identificación de correo',
            MOBILE_NUMBER: 'Número de teléfono móvil',
            PASSWORD: 'Contraseña',
            CONFIRM_PASSWORD: 'confirmar Contraseña',
            BUSINESS_NAME: 'Nombre del Negocio',
            BUSINESS_NAME_ARABIC: 'Nombre comercial árabe',
            BUSINESS_NAME_URDU: 'Nombre comercial Urdu',
            BUSINESS_NAME_SPANISH: 'Nombre comercial español',
            BUSINESS_NAME_FRENCH: 'Nombre comercial francés',
            SELECT_CURRENCY: 'Seleccione el tipo de moneda',
            SELECT_COUNTRY: 'Seleccionar país',
            SELECT_STATE: 'Seleccione estado',
            SELECT_CITY: 'Ciudad selecta',
            RESTAURANT_NAME: 'Nombre del restaurante',
            BRAND_NAME: 'Nombre de la marca',
            PAYMENT_MODE: 'Modo de pago',
            PRODUCT_NAME: 'nombre del producto',
            PRODUCT_NAME_ARABIC: 'Nombre del producto árabe',
            PRODUCT_NAME_URDU: 'Nombre del producto Urdu',
            PRODUCT_NAME_SPANISH: 'Nombre del producto Español',
            PRODUCT_NAME_FRENCH: 'Nombre del producto francés',
            DESCRIPTION: 'Descripción',
            POINTS: 'Puntos',
            SERVING_TYPE: 'Tipo de porción',
            SERVING_QUANTITY: 'Cantidad de porción',
            OPTION_NAME: 'Nombre de la opción',
            PRICE: 'Precio',
            DISCOUNT_PERCENTAGE: 'Descuento %',
            IS_TAXABLE: '¿Está sujeto a impuestos?',
            TAX_PERCENTAGE: '% de impuestos',
            SELECT_CATEGORY: 'selecciona una categoría',
            BREAKFAST: 'Desayuno',
            LUNCH: 'Almuerza',
            DINNER: 'Cena',
            ALL_DAY: '24 horas',
            CATEGORY_NAME: 'nombre de la categoría',
            CATEGORY_NAME_ARABIC: 'Nombre de la categoría Árabe',
            CATEGORY_NAME_URDU: 'Nombre de la categoría Urdu',
            CATEGORY_NAME_SPANISH: 'Nombre de la categoría española',
            CATEGORY_NAME_FRENCH: 'Nombre de la categoría Francés',
            SPECIAL_REMARKS: 'Observaciones especiales',
            OPTION_LABEL: 'Etiqueta de opción',
            OPTION_LABEL_ARABIC: 'Etiqueta de opción árabe',
            OPTION_LABEL_URDU: 'Etiqueta de opción Urdu',
            OPTION_LABEL_SPANISH: 'Etiqueta de opción española',
            OPTION_LABEL_FRENCH: 'Etiqueta de opción en francés',
            IS_DEFAULT: '¿Está predeterminado?',
            NAME: 'Nombre',
            NAME_ARABIC: 'Nombre árabe',
            NAME_URDU: 'Nombre Urdu',
            NAME_SPANISH: 'Nombre española',
            NAME_FRENCH: 'Nombre francesa',
            ADD_ON_LABEL: 'Etiqueta complementaria',
            ADD_ON_LABEL_ARABIC: 'Etiqueta complementaria árabe',
            ADD_ON_LABEL_URDU: 'Etiqueta complementaria Urdu',
            ADD_ON_LABEL_SPANISH: 'Etiqueta complementaria española',
            ADD_ON_LABEL_FRENCH: 'Etiqueta adicional en francés',
        },
        fr: {
            FIRST_NAME: 'Prénom',
            LAST_NAME: 'Nom de famille',
            EMAIL_ID: 'Identifiant de courrier électronique',
            MOBILE_NUMBER: 'Numéro de portable',
            PASSWORD: 'Mot de passe',
            CONFIRM_PASSWORD: 'Confirmez le mot de passe',
            BUSINESS_NAME: "Nom de l'entreprise",
            BUSINESS_NAME_ARABIC: "Nom de l'entreprise arabe",
            BUSINESS_NAME_URDU: "Nom de l'entreprise ourdou",
            BUSINESS_NAME_SPANISH: "Nom de l'entreprise espagnol",
            BUSINESS_NAME_FRENCH: "Nom de l'entreprise français",
            SELECT_CURRENCY: 'Sélectionnez la devise',
            SELECT_COUNTRY: 'Choisissez le pays',
            SELECT_STATE: "Sélectionnez l'état",
            SELECT_CITY: 'Sélectionnez une ville',
            RESTAURANT_NAME: 'Nom du restaurant',
            BRAND_NAME: 'Marque',
            PAYMENT_MODE: 'Mode de paiement',
            PRODUCT_NAME: 'Nom du produit',
            PRODUCT_NAME_ARABIC: 'Nom du produit arabe',
            PRODUCT_NAME_URDU: 'Nom du produit Ourdou',
            PRODUCT_NAME_SPANISH: 'Nom du produit espagnol',
            PRODUCT_NAME_FRENCH: 'Nom du produit français',
            DESCRIPTION: 'Description',
            POINTS: 'Points',
            SERVING_TYPE: 'Type de portion',
            SERVING_QUANTITY: 'Quantité par portion',
            OPTION_NAME: "Nom de l'option",
            PRICE: 'Prix',
            DISCOUNT_PERCENTAGE: 'Rabais %',
            IS_TAXABLE: 'Est-ce imposable ?',
            TAX_PERCENTAGE: 'Impôt %',
            SELECT_CATEGORY: 'Choisir une catégorie',
            BREAKFAST: 'Petit-déjeuner',
            LUNCH: 'Déjeuner',
            DINNER: 'Dîner',
            ALL_DAY: '24 heures',
            CATEGORY_NAME: 'Nom de catégorie',
            CATEGORY_NAME_ARABIC: 'Nom de la catégorie Arabe',
            CATEGORY_NAME_URDU: 'Nom de la catégorie Ourdou',
            CATEGORY_NAME_SPANISH: 'Nom de la catégorie Espagnol',
            CATEGORY_NAME_FRENCH: 'Nom de la catégorie Français',
            SPECIAL_REMARKS: 'Remarques spéciales',
            OPTION_LABEL: "Étiquette d'option",
            OPTION_LABEL_ARABIC: "Étiquette d'option Arabe",
            OPTION_LABEL_URDU: "Étiquette d'option ourdou",
            OPTION_LABEL_SPANISH: "Étiquette d'option Espagnol",
            OPTION_LABEL_FRENCH: "Étiquette d'option Français",
            IS_DEFAULT: 'Est-ce par défaut ?',
            NAME: 'Nom',
            NAME_ARABIC: 'Nom arabe',
            NAME_URDU: 'Nom ourdou',
            NAME_SPANISH: 'Nom espagnol',
            NAME_FRENCH: 'Nom français',
            ADD_ON_LABEL: 'Étiquette complémentaire',
            ADD_ON_LABEL_ARABIC: 'Étiquette complémentaire arabe',
            ADD_ON_LABEL_URDU: 'Étiquette complémentaire ourdou',
            ADD_ON_LABEL_SPANISH: 'Étiquette complémentaire espagnol',
            ADD_ON_LABEL_FRENCH: 'Étiquette complémentaire en français',
        },
    },
    ERROR: {
        en: {
            FIRST_NAME_ERROR: 'First name cannot be blank',
            LAST_NAME_ERROR: 'Last name cannot be blank',
            EMAIL_ERROR: 'Email cannot be blank',
            INVALID_EMAIL: 'Invalid email id',
            INVALID_MOBILE: 'Invalid mobile number',
            PASSWORD_ERROR: 'Password cannot be blank',
            CONFIRM_PASSWORD_ERROR: 'Confirm pasword cannot be blank',
            PASSWORD_MATCH_ERROR: 'Password does not match',
            BUSINESS_NAME_ERROR: 'Business name cannot be blank',
            COUNTRY_ERROR: 'Country not selected',
            STATE_ERROR: 'State not selected',
            CITY_ERROR: 'City not selected',
            CURRENCY_ERROR: 'Currency not selected',
            RESTAURANT_NAME_ERROR: 'Restaurant name cannot be blank',
            PRICE_CURRENCY_ERROR: 'Price currency not selected',
            PAYMENT_MODE_ERROR: 'Payment mode not selected',
            STRIPE_ACCOUNT_ID_ERROR: 'Stripe account id cannot be blank',
            BRAND_NAME_ERROR: 'Brand name cannot be blank',
            ERROR_WHILE_ADDING_ITEM: 'Error while adding item. Please try again',
            ITEM_QUANTITY_ERROR: 'Item quantity should be greater than 0',
            CART_EMPTY_ERROR: 'Your cart is empty. Please try adding item from menu',
            PAYMENT_MODE_BLANK_ERROR: 'Payment mode cannot be blank',
            PRODUCT_NAME_BLANK: 'Product name cannot be blank',
            INVALID_PRODUCT_POINT: 'Invalid product points',
            PRODUCT_PRICE_BLANK: 'Product price cannot be blank',
            INVALID_PRODUCT_PRICE: 'Invalid product price',
            INVALID_PRODUCT_DISCOUNT: 'Invalid product discount %',
            INVALID_PRODUCT_TAX: 'Invalid product tax %',
            PRODUCT_OPTION_PRICE_BLANK: 'Product option price(s) cannot be blank',
            PRODUCT_CATEGORY_ERROR: 'Product category is not selected',
            PRODUCT_AVAILABILITY_ERROR: 'Please select availability',
            ITEM_OPTION_TYPE_SELECT_ERROR: 'Item option type not selected',
            ITEM_SERVING_TYPE_SELECT_ERROR: 'Item serving type not selected',
            ITEM_SERVING_TYPE_QUANTITY_ERROR: 'Item serving type quantity cannot be blank',
            CATEGORY_NAME_BLANK_ERROR: 'Category name cannot be blank',
            OPTION_LABEL_BLANK_ERROR: 'Option label cannot be blank',
            OPTION_ALREADY_EXISTS_ERROR: 'Option already added. Try with different name',
            OPTION_NAME_ERROR: 'Option name cannot be blank',
            OPTION_PRICE_ERROR: 'Invalid option price',
            OPTION_NAME_ALREADY_EXISTS_ERROR: 'Option with name already added. Please try with different name.',
            ADD_ON_LABEL_ERROR: 'Add-on label cannot be blank',
            ADD_ON_ALREADY_EXISTS: 'Add-on already added. Try with different name',
            ADD_ON_NAME_ERROR: 'Add-on name cannot be blank',
            ADD_ON_PRICE_ERROR: 'Invalid add-on price',
            ADD_ON_NAME_ALREADY_EXISTS_ERROR: 'Add-on with name already added. Please try with different name.',
        },
        ar: {
            FIRST_NAME_ERROR: 'لا يمكن أن يكون الاسم الأول فارغًا',
            LAST_NAME_ERROR: 'لا يمكن أن يكون الاسم الأخير فارغًا',
            EMAIL_ERROR: 'لا يمكن أن يكون البريد الإلكتروني فارغًا',
            INVALID_EMAIL: 'البريد الإلكتروني غير صالح',
            INVALID_MOBILE: 'رقم الجوال غير صالح',
            PASSWORD_ERROR: 'كلمة السر لا يمكن أن تترك فارغة',
            CONFIRM_PASSWORD_ERROR: 'تأكيد كلمة المرور لا يمكن أن يكون فارغا',
            PASSWORD_MATCH_ERROR: 'كلمة السر غير متطابقة',
            BUSINESS_NAME_ERROR: 'لا يمكن أن يكون اسم النشاط التجاري فارغًا',
            COUNTRY_ERROR: 'لم يتم تحديد البلد',
            STATE_ERROR: 'لم يتم تحديد الدولة',
            CITY_ERROR: 'لم يتم تحديد المدينة',
            CURRENCY_ERROR: 'لم يتم تحديد العملة',
            RESTAURANT_NAME_ERROR: 'لا يمكن أن يكون اسم المطعم فارغًا',
            PRICE_CURRENCY_ERROR: 'لم يتم تحديد عملة السعر',
            PAYMENT_MODE_ERROR: 'لم يتم تحديد وضع الدفع',
            STRIPE_ACCOUNT_ID_ERROR: 'لا يمكن أن يكون معرف الحساب الشريطي فارغًا',
            BRAND_NAME_ERROR: 'لا يمكن أن يكون اسم العلامة التجارية فارغًا',
            ERROR_WHILE_ADDING_ITEM: 'حدث خطأ أثناء إضافة العنصر. حاول مرة اخرى',
            ITEM_QUANTITY_ERROR: 'يجب أن تكون كمية العنصر أكبر من 0',
            CART_EMPTY_ERROR: 'عربة التسوق فارغة. يرجى محاولة إضافة عنصر من القائمة',
            PAYMENT_MODE_BLANK_ERROR: 'لا يمكن أن يكون وضع الدفع فارغًا',
            PRODUCT_NAME_BLANK: 'لا يمكن أن يكون اسم المنتج فارغًا',
            INVALID_PRODUCT_POINT: 'نقاط المنتج غير صالحة',
            PRODUCT_PRICE_BLANK: 'لا يمكن أن يكون سعر المنتج فارغًا',
            INVALID_PRODUCT_PRICE: 'سعر المنتج غير صالح',
            INVALID_PRODUCT_DISCOUNT: '% خصم المنتج غير صالح',
            INVALID_PRODUCT_TAX: 'نسبة ضريبة المنتج غير صالحة',
            PRODUCT_OPTION_PRICE_BLANK: 'لا يمكن أن يكون سعر (أسعار) خيار المنتج فارغًا',
            PRODUCT_CATEGORY_ERROR: 'لم يتم تحديد فئة المنتج',
            PRODUCT_AVAILABILITY_ERROR: 'الرجاء تحديد التوفر',
            ITEM_OPTION_TYPE_SELECT_ERROR: 'لم يتم تحديد نوع خيار العنصر',
            ITEM_SERVING_TYPE_SELECT_ERROR: 'لم يتم تحديد نوع عرض العنصر',
            ITEM_SERVING_TYPE_QUANTITY_ERROR: 'لا يمكن أن تكون كمية نوع عرض العنصر فارغة',
            CATEGORY_NAME_BLANK_ERROR: 'لا يمكن أن يكون اسم الفئة فارغًا',
            OPTION_LABEL_BLANK_ERROR: 'لا يمكن أن تكون تسمية الخيار فارغة',
            OPTION_ALREADY_EXISTS_ERROR: 'تمت إضافة الخيار بالفعل. حاول باسم مختلف',
            OPTION_NAME_ERROR: 'لا يمكن أن يكون اسم الخيار فارغًا',
            OPTION_PRICE_ERROR: 'سعر الخيار غير صالح',
            OPTION_NAME_ALREADY_EXISTS_ERROR: 'الخيار مع الاسم المضاف بالفعل. يرجى المحاولة باسم مختلف.',
            ADD_ON_LABEL_ERROR: 'لا يمكن أن يكون عنوان الوظيفة الإضافية فارغًا',
            ADD_ON_ALREADY_EXISTS: 'تمت إضافة الوظيفة الإضافية بالفعل. حاول باسم مختلف',
            ADD_ON_NAME_ERROR: 'لا يمكن أن يكون اسم الوظيفة الإضافية فارغًا',
            ADD_ON_PRICE_ERROR: 'سعر الإضافة غير صالح',
            ADD_ON_NAME_ALREADY_EXISTS_ERROR: 'الوظيفة الإضافية مع الاسم المضافة بالفعل. يرجى المحاولة باسم مختلف.',
        },
        ur: {
            FIRST_NAME_ERROR: 'پہلا نام خالی نہیں ہو سکتا',
            LAST_NAME_ERROR: 'آخری نام خالی نہیں ہو سکتا',
            EMAIL_ERROR: 'ای میل خالی نہیں ہو سکتی',
            INVALID_EMAIL: 'غلط ای میل آئی ڈی',
            INVALID_MOBILE: 'غلط موبائل نمبر',
            PASSWORD_ERROR: 'پاس ورڈ خالی نہیں ہو سکتا',
            CONFIRM_PASSWORD_ERROR: 'تصدیق کریں پاس ورڈ خالی نہیں ہو سکتا',
            PASSWORD_MATCH_ERROR: 'پاس ورڈ میچ نہیں کرتا',
            BUSINESS_NAME_ERROR: 'کاروبار کا نام خالی نہیں ہو سکتا',
            COUNTRY_ERROR: 'ملک منتخب نہیں کیا گیا۔',
            STATE_ERROR: 'ریاست منتخب نہیں ہوئی۔',
            CITY_ERROR: 'شہر کا انتخاب نہیں کیا گیا۔',
            CURRENCY_ERROR: 'کرنسی منتخب نہیں کی گئی۔',
            RESTAURANT_NAME_ERROR: 'ریسٹورنٹ کا نام خالی نہیں ہو سکتا',
            PRICE_CURRENCY_ERROR: 'قیمت کی کرنسی منتخب نہیں کی گئی۔',
            PAYMENT_MODE_ERROR: 'ادائیگی کا موڈ منتخب نہیں کیا گیا۔',
            STRIPE_ACCOUNT_ID_ERROR: 'پٹی اکاؤنٹ کی شناخت خالی نہیں ہوسکتی ہے۔',
            BRAND_NAME_ERROR: 'برانڈ کا نام خالی نہیں ہو سکتا',
            ERROR_WHILE_ADDING_ITEM: 'آئٹم شامل کرتے وقت خرابی دوبارہ کوشش کریں',
            ITEM_QUANTITY_ERROR: 'آئٹم کی مقدار 0 سے زیادہ ہونی چاہیے۔',
            CART_EMPTY_ERROR: 'آپ کی ٹوکری خالی ہے. براہ کرم مینو سے آئٹم شامل کرنے کی کوشش کریں۔',
            PAYMENT_MODE_BLANK_ERROR: 'ادائیگی کا موڈ خالی نہیں ہو سکتا',
            PRODUCT_NAME_BLANK: 'پروڈکٹ کا نام خالی نہیں ہو سکتا',
            INVALID_PRODUCT_POINT: 'غلط پروڈکٹ پوائنٹس',
            PRODUCT_PRICE_BLANK: 'پروڈکٹ کی قیمت خالی نہیں ہو سکتی',
            INVALID_PRODUCT_PRICE: 'غلط پروڈکٹ کی قیمت',
            INVALID_PRODUCT_DISCOUNT: 'غلط پروڈکٹ ڈسکاؤنٹ %',
            INVALID_PRODUCT_TAX: 'غلط پروڈکٹ ٹیکس %',
            PRODUCT_OPTION_PRICE_BLANK: 'پروڈکٹ کے اختیار کی قیمت خالی نہیں ہو سکتی',
            PRODUCT_CATEGORY_ERROR: 'پروڈکٹ کا زمرہ منتخب نہیں کیا گیا ہے۔',
            PRODUCT_AVAILABILITY_ERROR: 'براہ کرم دستیابی کو منتخب کریں۔',
            ITEM_OPTION_TYPE_SELECT_ERROR: 'آئٹم کے اختیار کی قسم منتخب نہیں کی گئی۔',
            ITEM_SERVING_TYPE_SELECT_ERROR: 'آئٹم پیش کرنے کی قسم منتخب نہیں کی گئی۔',
            ITEM_SERVING_TYPE_QUANTITY_ERROR: 'آئٹم پیش کرنے کی قسم کی مقدار خالی نہیں ہوسکتی ہے۔',
            CATEGORY_NAME_BLANK_ERROR: 'زمرہ کا نام خالی نہیں ہو سکتا',
            OPTION_LABEL_BLANK_ERROR: 'اختیاری لیبل خالی نہیں ہو سکتا',
            OPTION_ALREADY_EXISTS_ERROR: 'آپشن پہلے ہی شامل کر دیا گیا ہے۔ مختلف نام کے ساتھ کوشش کریں۔',
            OPTION_NAME_ERROR: 'اختیار کا نام خالی نہیں ہو سکتا',
            OPTION_PRICE_ERROR: 'غلط آپشن کی قیمت',
            OPTION_NAME_ALREADY_EXISTS_ERROR: 'نام کے ساتھ آپشن پہلے ہی شامل کر دیا گیا ہے۔ براہ کرم مختلف نام کے ساتھ کوشش کریں۔',
            ADD_ON_LABEL_ERROR: 'ایڈ آن لیبل خالی نہیں ہو سکتا',
            ADD_ON_ALREADY_EXISTS: 'ایڈ آن پہلے ہی شامل کر دیا گیا ہے۔ مختلف نام کے ساتھ کوشش کریں۔',
            ADD_ON_NAME_ERROR: 'ایڈ آن کا نام خالی نہیں ہو سکتا',
            ADD_ON_PRICE_ERROR: 'غلط اضافی قیمت',
            ADD_ON_NAME_ALREADY_EXISTS_ERROR: 'نام کے ساتھ ایڈ آن پہلے ہی شامل کر دیا گیا ہے۔ براہ کرم مختلف نام کے ساتھ کوشش کریں۔',
        },
        es: {
            FIRST_NAME_ERROR: 'El nombre no puede estar en blanco',
            LAST_NAME_ERROR: 'El apellido no puede estar en blanco',
            EMAIL_ERROR: 'El correo electrónico no puede estar en blanco',
            INVALID_EMAIL: 'ID de correo electrónico no válido',
            INVALID_MOBILE: 'Numero de celular invalido',
            PASSWORD_ERROR: 'La contraseña no puede estar en blanco',
            CONFIRM_PASSWORD_ERROR: 'Confirmar contraseña no puede estar en blanco',
            PASSWORD_MATCH_ERROR: 'Las contraseñas no coinciden',
            BUSINESS_NAME_ERROR: 'El nombre de la empresa no puede estar en blanco',
            COUNTRY_ERROR: 'País no seleccionado',
            STATE_ERROR: 'Estado no seleccionado',
            CITY_ERROR: 'Ciudad no seleccionada',
            CURRENCY_ERROR: 'Moneda no seleccionada',
            RESTAURANT_NAME_ERROR: 'El nombre del restaurante no puede estar en blanco.',
            PRICE_CURRENCY_ERROR: 'Moneda del precio no seleccionada',
            PAYMENT_MODE_ERROR: 'Modo de pago no seleccionado',
            STRIPE_ACCOUNT_ID_ERROR: 'La identificación de la cuenta de Stripe no puede estar en blanco',
            BRAND_NAME_ERROR: 'El nombre de la marca no puede estar en blanco',
            ERROR_WHILE_ADDING_ITEM: 'Error al agregar el artículo. Inténtalo de nuevo',
            ITEM_QUANTITY_ERROR: 'La cantidad del artículo debe ser mayor que 0.',
            CART_EMPTY_ERROR: 'Tu carrito esta vacío. Intente agregar un elemento del menú.',
            PAYMENT_MODE_BLANK_ERROR: 'El modo de pago no puede estar en blanco',
            PRODUCT_NAME_BLANK: 'El nombre del producto no puede estar en blanco',
            INVALID_PRODUCT_POINT: 'Puntos de producto no válidos',
            PRODUCT_PRICE_BLANK: 'El precio del producto no puede estar en blanco.',
            INVALID_PRODUCT_PRICE: 'Precio del producto no válido',
            INVALID_PRODUCT_DISCOUNT: '% de descuento de producto no válido',
            INVALID_PRODUCT_TAX: '% de impuesto sobre productos no válidos',
            PRODUCT_OPTION_PRICE_BLANK: 'Los precios de las opciones de producto no pueden estar en blanco',
            PRODUCT_CATEGORY_ERROR: 'La categoría de producto no está seleccionada',
            PRODUCT_AVAILABILITY_ERROR: 'Por favor seleccione disponibilidad',
            ITEM_OPTION_TYPE_SELECT_ERROR: 'Tipo de opción de artículo no seleccionado',
            ITEM_SERVING_TYPE_SELECT_ERROR: 'Tipo de publicación de artículo no seleccionado',
            ITEM_SERVING_TYPE_QUANTITY_ERROR: 'La cantidad del tipo de porción del artículo no puede estar en blanco',
            CATEGORY_NAME_BLANK_ERROR: 'El nombre de la categoría no puede estar en blanco',
            OPTION_LABEL_BLANK_ERROR: 'La etiqueta de opción no puede estar en blanco',
            OPTION_ALREADY_EXISTS_ERROR: 'Opción ya agregada. Prueba con otro nombre',
            OPTION_NAME_ERROR: 'El nombre de la opción no puede estar en blanco',
            OPTION_PRICE_ERROR: 'Precio de opción no válido',
            OPTION_NAME_ALREADY_EXISTS_ERROR: 'Opción con nombre ya agregado. Intente con un nombre diferente.',
            ADD_ON_LABEL_ERROR: 'La etiqueta del complemento no puede estar en blanco',
            ADD_ON_ALREADY_EXISTS: 'Complemento ya agregado. Prueba con otro nombre',
            ADD_ON_NAME_ERROR: 'El nombre del complemento no puede estar en blanco',
            ADD_ON_PRICE_ERROR: 'Precio adicional no válido',
            ADD_ON_NAME_ALREADY_EXISTS_ERROR: 'Complemento con nombre ya agregado. Intente con un nombre diferente.',
        },
        fr: {
            FIRST_NAME_ERROR: 'Le prénom ne peut pas être vide',
            LAST_NAME_ERROR: 'Le nom de famille ne peut pas être vide',
            EMAIL_ERROR: "L'e-mail ne peut pas être vide",
            INVALID_EMAIL: 'Identifiant de messagerie invalide',
            INVALID_MOBILE: 'Numéro de portable invalide',
            PASSWORD_ERROR: 'Le mot de passe ne peut pas être vide',
            CONFIRM_PASSWORD_ERROR: 'Confirmer que le mot de passe ne peut pas être vide',
            PASSWORD_MATCH_ERROR: 'Le mot de passe ne correspond pas',
            BUSINESS_NAME_ERROR: "Le nom de l'entreprise ne peut pas être vide",
            COUNTRY_ERROR: 'Pays non sélectionné',
            STATE_ERROR: 'Etat non sélectionné',
            CITY_ERROR: 'Ville non sélectionnée',
            CURRENCY_ERROR: 'Devise non sélectionnée',
            RESTAURANT_NAME_ERROR: 'Le nom du restaurant ne peut pas être vide',
            PRICE_CURRENCY_ERROR: 'Devise du prix non sélectionnée',
            PAYMENT_MODE_ERROR: 'Mode de paiement non sélectionné',
            STRIPE_ACCOUNT_ID_ERROR: "L'identifiant du compte Stripe ne peut pas être vide",
            BRAND_NAME_ERROR: 'Le nom de la marque ne peut pas être vide',
            ERROR_WHILE_ADDING_ITEM: "Erreur lors de l'ajout d'un élément. Veuillez réessayer",
            ITEM_QUANTITY_ERROR: "La quantité d'articles doit être supérieure à 0",
            CART_EMPTY_ERROR: "Votre panier est vide. Veuillez essayer d'ajouter un élément du menu",
            PAYMENT_MODE_BLANK_ERROR: 'Le mode de paiement ne peut pas être vide',
            PRODUCT_NAME_BLANK: 'Le nom du produit ne peut pas être vide',
            INVALID_PRODUCT_POINT: 'Points de produit invalides',
            PRODUCT_PRICE_BLANK: 'Le prix du produit ne peut pas être vide',
            INVALID_PRODUCT_PRICE: 'Prix du produit invalide',
            INVALID_PRODUCT_DISCOUNT: '% de réduction sur le produit invalide',
            INVALID_PRODUCT_TAX: '% de taxe sur le produit invalide',
            PRODUCT_OPTION_PRICE_BLANK: 'Les prix des options de produit ne peuvent pas être vides',
            PRODUCT_CATEGORY_ERROR: "La catégorie de produit n'est pas sélectionnée",
            PRODUCT_AVAILABILITY_ERROR: 'Veuillez sélectionner la disponibilité',
            ITEM_OPTION_TYPE_SELECT_ERROR: "Type d'option d'article non sélectionné",
            ITEM_SERVING_TYPE_SELECT_ERROR: "Type de diffusion de l'article non sélectionné",
            ITEM_SERVING_TYPE_QUANTITY_ERROR: "La quantité du type de diffusion de l'article ne peut pas être vide",
            CATEGORY_NAME_BLANK_ERROR: 'Le nom de la catégorie ne peut pas être vide',
            OPTION_LABEL_BLANK_ERROR: "L'étiquette d'option ne peut pas être vide",
            OPTION_ALREADY_EXISTS_ERROR: "Option déjà ajoutée. Essayez avec un nom différent",
            OPTION_NAME_ERROR: "Le nom de l'option ne peut pas être vide",
            OPTION_PRICE_ERROR: "Prix d'option invalide",
            OPTION_NAME_ALREADY_EXISTS_ERROR: "Option avec le nom déjà ajouté. Veuillez essayer avec un nom différent.",
            ADD_ON_LABEL_ERROR: "L'étiquette du module complémentaire ne peut pas être vide",
            ADD_ON_ALREADY_EXISTS: 'Module complémentaire déjà ajouté. Essayez avec un nom différent',
            ADD_ON_NAME_ERROR: 'Le nom du module complémentaire ne peut pas être vide',
            ADD_ON_PRICE_ERROR: 'Prix complémentaire invalide',
            ADD_ON_NAME_ALREADY_EXISTS_ERROR: 'Module complémentaire avec un nom déjà ajouté. Veuillez essayer avec un nom différent.',
        },
    },
    SIGNIN: {
        en: {
            LOGIN_TO: 'Login to',
            LOGIN: 'Login',
            FORGOT_PASSWORD: 'FORGOT PASSWORD',
            LOGIN_SUCCESSFULL: 'Login successfull',
            USER_TYPE_NOT_ALLOWED: 'User type not supported. Please try with valid user credentials',
            INCORRECT_PASSWORD: 'Incorrect password. Please try with correct password.',
            LOGIN_WITH_REGISTERED_CREDENTIALS: 'Login with your registered restaurant credentials',
            OR_LABEL: 'OR',
            NOT_A_MEMBER: 'Not a member?',
            CREATE_ACCOUNT: 'Create Account'
        },
        ar: {
            LOGIN_TO: 'تسجيل الدخول إلى',
            LOGIN: 'تسجيل الدخول',
            FORGOT_PASSWORD: 'هل نسيت كلمة السر',
            LOGIN_SUCCESSFULL: 'تم تسجيل الدخول بنجاح',
            USER_TYPE_NOT_ALLOWED: 'نوع المستخدم غير مدعوم. يرجى المحاولة باستخدام بيانات اعتماد مستخدم صالحة',
            INCORRECT_PASSWORD: 'كلمة سر خاطئة. يرجى المحاولة باستخدام كلمة المرور الصحيحة.',
            LOGIN_WITH_REGISTERED_CREDENTIALS: 'تسجيل الدخول باستخدام بيانات اعتماد المطعم المسجل الخاص بك',
            OR_LABEL: 'أو',
            NOT_A_MEMBER: 'ليس عضوا؟',
            CREATE_ACCOUNT: 'إنشاء حساب'
        },
        ur: {
            LOGIN_TO: 'داخل ہوجاو',
            LOGIN: 'لاگ ان کریں',
            FORGOT_PASSWORD: 'پاسورڈ بھول گے',
            LOGIN_SUCCESSFULL: 'لاگ ان کامیاب',
            USER_TYPE_NOT_ALLOWED: 'صارف کی قسم تعاون یافتہ نہیں ہے۔ براہ کرم درست صارف کی اسناد کے ساتھ کوشش کریں۔',
            INCORRECT_PASSWORD: 'غلط پاس ورڈ. براہ کرم درست پاس ورڈ کے ساتھ کوشش کریں۔',
            LOGIN_WITH_REGISTERED_CREDENTIALS: 'اپنے رجسٹرڈ ریستوراں کی اسناد کے ساتھ لاگ ان کریں۔',
            OR_LABEL: 'یا',
            NOT_A_MEMBER: 'ممبر نہیں؟',
            CREATE_ACCOUNT: 'اکاؤنٹ بنائیں'
        },
        es: {
            LOGIN_TO: 'Iniciar sesión en',
            LOGIN: 'Acceso',
            FORGOT_PASSWORD: 'HAS OLVIDADO TU CONTRASEÑA',
            LOGIN_SUCCESSFULL: 'Inicio de sesión exitosa',
            USER_TYPE_NOT_ALLOWED: 'Tipo de usuario no admitido. Inténtelo con credenciales de usuario válidas.',
            INCORRECT_PASSWORD: 'Contraseña incorrecta. Inténtelo con la contraseña correcta.',
            LOGIN_WITH_REGISTERED_CREDENTIALS: 'Inicie sesión con las credenciales de su restaurante registrado',
            OR_LABEL: 'O',
            NOT_A_MEMBER: '¿No es un miembro?',
            CREATE_ACCOUNT: 'Crear una cuenta'
        },
        fr: {
            LOGIN_TO: 'Se connecter à',
            LOGIN: 'Se connecter',
            FORGOT_PASSWORD: 'MOT DE PASSE OUBLIÉ',
            LOGIN_SUCCESSFULL: 'Connexion réussie',
            USER_TYPE_NOT_ALLOWED: "Type d'utilisateur non pris en charge. Veuillez essayer avec des informations d'identification d'utilisateur valides",
            INCORRECT_PASSWORD: 'Mot de passe incorrect. Veuillez essayer avec le mot de passe correct.',
            LOGIN_WITH_REGISTERED_CREDENTIALS: 'Connectez-vous avec vos identifiants de restaurant enregistrés',
            OR_LABEL: 'OU',
            NOT_A_MEMBER: 'Pas un membre?',
            CREATE_ACCOUNT: 'Créer un compte'
        },
    },
    REGISTRATION: {
        en: {
            REGISTRATION_SUCCESS: 'User registration successfull',
            CREATE_ACCOUNT: 'Create Account',
            ENTER_DETAIL_TO_REGISTER: 'Enter details to register your restaurant on Areabites',
            BUSINESS_INFORMATION: "Business Information"
        },
        ar: {
            REGISTRATION_SUCCESS: 'تم تسجيل المستخدم بنجاح',
            CREATE_ACCOUNT: 'إنشاء حساب',
            ENTER_DETAIL_TO_REGISTER: 'أدخل التفاصيل لتسجيل مطعمك على المنطقة',
            BUSINESS_INFORMATION: "معلومات العمل"
        },
        ur: {
            REGISTRATION_SUCCESS: 'صارف کی رجسٹریشن کامیاب',
            CREATE_ACCOUNT: 'اکاؤنٹ بنائیں',
            ENTER_DETAIL_TO_REGISTER: 'ایریابیٹس پر اپنے ریستوراں کو رجسٹر کرنے کے لیے تفصیلات درج کریں۔',
            BUSINESS_INFORMATION: "کاروباری معلومات"
        },
        es: {
            REGISTRATION_SUCCESS: 'Registro de usuario exitosa',
            CREATE_ACCOUNT: 'Crear una cuenta',
            ENTER_DETAIL_TO_REGISTER: 'Introduce los datos para registrar tu restaurante en Areabites',
            BUSINESS_INFORMATION: "Información de negocios"
        },
        fr: {
            REGISTRATION_SUCCESS: "Enregistrement de l'utilisateur réussi",
            CREATE_ACCOUNT: 'Créer un compte',
            ENTER_DETAIL_TO_REGISTER: 'Entrez les détails pour enregistrer votre restaurant sur Areabites',
            BUSINESS_INFORMATION: "Informations d'affaires"
        },
    },
    UPDATE_PROFILE: {
        en: {
            RESTAURANT_ADD_SUCCESS: 'Restaurant added successfully',
            RESTAURANT_DETAIL: 'Restaurant Details',
            RESTAURANT_INFORMATION: 'Restaurant Information',
            LOCATION_INFORMATION: 'Location Information'
        },
        ar: {
            RESTAURANT_ADD_SUCCESS: 'تمت إضافة المطعم بنجاح',
            RESTAURANT_DETAIL: 'تفاصيل المطعم',
            RESTAURANT_INFORMATION: 'معلومات المطعم',
            LOCATION_INFORMATION: 'معلومات الموقع'
        },
        ur: {
            RESTAURANT_ADD_SUCCESS: 'ریستوراں کامیابی کے ساتھ شامل ہو گیا۔',
            RESTAURANT_DETAIL: 'ریستوراں کی تفصیلات',
            RESTAURANT_INFORMATION: 'ریستوراں کی معلومات',
            LOCATION_INFORMATION: 'مقام کی معلومات'
        },
        es: {
            RESTAURANT_ADD_SUCCESS: 'Restaurante agregado exitosamente',
            RESTAURANT_DETAIL: 'Detalles del restaurante',
            RESTAURANT_INFORMATION: 'Información del restaurante',
            LOCATION_INFORMATION: 'Información sobre la ubicación'
        },
        fr: {
            RESTAURANT_ADD_SUCCESS: 'Restaurant ajouté avec succès',
            RESTAURANT_DETAIL: 'Détails du restaurant',
            RESTAURANT_INFORMATION: 'Informations sur le restaurant',
            LOCATION_INFORMATION: 'Information de Lieu'
        },
    },
    MENU_PRODUCT_CUSTOMIZE: {
        en: {
            ITEM_ADDED_IN_CART: 'Item added in cart',
            CUSTOMIZE_ITEM: 'Customize Item',
            PRODUCT_DETAIL: 'Product Details',
            AVAILABLE_OPTION: 'Available Options',
            VEG: 'Veg',
            NON_VEG: 'Non Veg',
            VEGAN: 'Vegan',
            FREE: 'Free',
            REMOVE: 'Remove',
            SELECT: 'Select',
            AVAILABLE_ADD_ON: 'Available Add-Ons',
            ADD: 'Add',
            SELECT_QUANTITY: 'Select Quantity',
            ADD_TO_CART: 'Add To Cart'
        },
        ar: {
            ITEM_ADDED_IN_CART: 'تمت إضافة العنصر في سلة التسوق',
            CUSTOMIZE_ITEM: 'تخصيص العنصر',
            PRODUCT_DETAIL: 'تفاصيل المنتج',
            AVAILABLE_OPTION: 'الخيارات المتاحة',
            VEG: 'نباتي',
            NON_VEG: 'غير نباتي',
            VEGAN: 'نباتي',
            FREE: 'حر',
            REMOVE: 'يزيل',
            SELECT: 'يختار',
            AVAILABLE_ADD_ON: 'الإضافات المتاحة',
            ADD: 'يضيف',
            SELECT_QUANTITY: 'حدد الكمية',
            ADD_TO_CART: 'أضف إلى السلة'
        },
        ur: {
            ITEM_ADDED_IN_CART: 'آئٹم کو کارٹ میں شامل کیا گیا۔',
            CUSTOMIZE_ITEM: 'آئٹم کو حسب ضرورت بنائیں',
            PRODUCT_DETAIL: 'پروڈکٹ کی تفصیلات',
            AVAILABLE_OPTION: 'دستیاب اختیارات',
            VEG: 'سبزی',
            NON_VEG: 'نان ویج',
            VEGAN: 'ویگن',
            FREE: 'مفت',
            REMOVE: 'دور',
            SELECT: 'منتخب کریں۔',
            AVAILABLE_ADD_ON: 'دستیاب ایڈ آنز',
            ADD: 'شامل کریں۔',
            SELECT_QUANTITY: 'مقدار کو منتخب کریں۔',
            ADD_TO_CART: 'ٹوکری میں شامل کریں'
        },
        es: {
            ITEM_ADDED_IN_CART: 'Artículo agregado al carrito',
            CUSTOMIZE_ITEM: 'Personalizar artículo',
            PRODUCT_DETAIL: 'Detalles de producto',
            AVAILABLE_OPTION: 'Opciones Disponibles',
            VEG: 'vegetales',
            NON_VEG: 'No vegetales',
            VEGAN: 'Vegana',
            FREE: 'Gratis',
            REMOVE: 'Eliminar',
            SELECT: 'Seleccionar',
            AVAILABLE_ADD_ON: 'Complementos disponibles',
            ADD: 'Agregar',
            SELECT_QUANTITY: 'Selecciona la cantidad',
            ADD_TO_CART: 'Añadir a la cesta'
        },
        fr: {
            ITEM_ADDED_IN_CART: 'Article ajouté au panier',
            CUSTOMIZE_ITEM: "Personnaliser l'article",
            PRODUCT_DETAIL: 'détails du produit',
            AVAILABLE_OPTION: 'Options disponibles',
            VEG: 'Végétarienne',
            NON_VEG: 'Non végétarien',
            VEGAN: 'Végétalienne',
            FREE: 'Gratuite',
            REMOVE: 'Retirer',
            SELECT: 'Sélectionner',
            AVAILABLE_ADD_ON: 'Modules complémentaires disponibles',
            ADD: 'Ajouter',
            SELECT_QUANTITY: 'Sélectionnez la quantité',
            ADD_TO_CART: 'Ajouter au panier'
        },
    },
    PLACE_ORDER: {
        en: {
            ITEM_ADDED_IN_CART: 'Item added in cart',
            ITEM: 'Item',
            QTY: 'Qty',
            PRICE: 'Price',
            OPTIONS: 'Options',
            ADD_ONS: "Add-ons",
            SUB_TOTAL: 'Sub Total:',
            TOTAL_DISCOUNT: 'Total Discount:',
            TOTAL_TAX: 'Total Tax:',
            FINAL_AMOUNT: 'Final Amount:',
            THANK_YOU_FOR_VISIT: 'Thank you for your visit! Please come again!',
            HAVE_GREAT_DAY: 'Have a great day!',
            POWERED_BY: 'Powered by: Areabites',
            PLACE_ORDER_LABEL: 'Place Order',
            NO_CATEGORY_AVAILABEL: "No Category is available. Please select the Product option on the bottom option to Add a new Category or Product.",
            NO_PRODUCT_FOUND: "No Product Found. Please select the Product option on the bottom option to Add a new Category or Product.",
            POINTS: "points",
            OUT_OF_STOCK: 'Out Of Stock',
            ADD: 'Add',
            CUSTOMIZE: 'Customize',
            YOUR_CART: 'Your Cart',
            PLACE_ORDER_BUTTON: 'Place Order',
            SUCCESS: 'Success',
            ORDER_PLACE_SUCCESS: 'Order Placed Success',
            PRINT: "Print",
            PAYMENT_MODE_EXAMPLE: 'Ex. Cash/Card/Online Payment',
            EARN: 'Earn',
            ADDITIONAL_DISCOUNT: 'Additional Discount:'
        },
        ar: {
            ITEM_ADDED_IN_CART: 'تمت إضافة العنصر في سلة التسوق',
            ITEM: 'غرض',
            QTY: 'الكمية',
            PRICE: 'سعر',
            OPTIONS: 'خيارات',
            ADD_ONS: "الإضافات",
            SUB_TOTAL: 'المجموع الفرعي:',
            TOTAL_DISCOUNT: 'إجمالي الخصم:',
            TOTAL_TAX: 'مجموع الضريبة:',
            FINAL_AMOUNT: 'القيمة النهائية:',
            THANK_YOU_FOR_VISIT: 'شكرا على الزيارة! ارجوك عد مجددا!',
            HAVE_GREAT_DAY: 'أتمنى لك يوماً عظيماً!',
            POWERED_BY: 'مدعوم من: المنطقة',
            PLACE_ORDER_LABEL: 'مكان الامر',
            NO_CATEGORY_AVAILABEL: "لا توجد فئة متاحة. يرجى تحديد خيار المنتج في الخيار السفلي لإضافة فئة أو منتج جديد.",
            NO_PRODUCT_FOUND: "لم يتم العثور على منتج. يرجى تحديد خيار المنتج في الخيار السفلي لإضافة فئة أو منتج جديد.",
            POINTS: "نقاط",
            OUT_OF_STOCK: 'إنتهى من المخزن',
            ADD: 'يضيف',
            CUSTOMIZE: 'يعدل أو يكيف',
            YOUR_CART: 'عربتك',
            PLACE_ORDER_BUTTON: 'مكان الامر',
            SUCCESS: 'نجاح',
            ORDER_PLACE_SUCCESS: 'تم وضع الطلب بنجاح',
            PRINT: "مطبعة",
            PAYMENT_MODE_EXAMPLE: 'السابق. نقدا / بطاقة / الدفع عبر الإنترنت',
            EARN: 'يكسب',
            ADDITIONAL_DISCOUNT: 'خصم إضافي:'
        },
        ur: {
            ITEM_ADDED_IN_CART: 'آئٹم کو کارٹ میں شامل کیا گیا۔',
            ITEM: 'آئٹم',
            QTY: 'مقدار',
            PRICE: 'قیمت',
            OPTIONS: 'اختیارات',
            ADD_ONS: "ایڈ آنز",
            SUB_TOTAL: 'ذیلی کل:',
            TOTAL_DISCOUNT: 'کل رعایت:',
            TOTAL_TAX: 'کل ٹیکس:',
            FINAL_AMOUNT: 'حتمی رقم:',
            THANK_YOU_FOR_VISIT: 'آپ کے دورے کے لئے آپ کا شکریہ! براہ کرم دوبارہ آئیں!',
            HAVE_GREAT_DAY: 'آپ کا دن بہت اچھا گزرے!',
            POWERED_BY: 'تقویت یافتہ: ایریابیٹس',
            PLACE_ORDER_LABEL: 'حکم صادر کریں',
            NO_CATEGORY_AVAILABEL: "کوئی زمرہ دستیاب نہیں ہے۔ براہ کرم نیا زمرہ یا پروڈکٹ شامل کرنے کے لیے نیچے والے آپشن پر پروڈکٹ کا آپشن منتخب کریں۔",
            NO_PRODUCT_FOUND: "کوئی پروڈکٹ نہیں ملا۔ براہ کرم نیا زمرہ یا پروڈکٹ شامل کرنے کے لیے نیچے والے آپشن پر پروڈکٹ کا آپشن منتخب کریں۔",
            POINTS: "پوائنٹس",
            OUT_OF_STOCK: 'زخیرے سے باہر',
            ADD: 'شامل کریں۔',
            CUSTOMIZE: 'حسب ضرورت بنائیں',
            YOUR_CART: 'آپ کی ٹوکری',
            PLACE_ORDER_BUTTON: 'حکم صادر کریں',
            SUCCESS: 'کامیابی',
            ORDER_PLACE_SUCCESS: 'آرڈر کی کامیابی',
            PRINT: "پرنٹ کریں",
            PAYMENT_MODE_EXAMPLE: 'سابق. نقد/کارڈ/آن لائن ادائیگی',
            EARN: 'کمائیں',
            ADDITIONAL_DISCOUNT: 'اضافی رعایت:'
        },
        es: {
            ITEM_ADDED_IN_CART: 'Artículo agregado al carrito',
            ITEM: 'Artículo',
            QTY: 'Cantidad',
            PRICE: 'Precio',
            OPTIONS: 'Opciones',
            ADD_ONS: "Complementos",
            SUB_TOTAL: 'Subtotal:',
            TOTAL_DISCOUNT: 'Descuento total:',
            TOTAL_TAX: 'Total impuestos:',
            FINAL_AMOUNT: 'Cantidad final:',
            THANK_YOU_FOR_VISIT: '¡Gracias por tu visita! ¡Por favor ven de nuevo!',
            HAVE_GREAT_DAY: '¡Qué tengas un lindo día!',
            POWERED_BY: 'Desarrollado por: Areabites',
            PLACE_ORDER_LABEL: 'Realizar pedido',
            NO_CATEGORY_AVAILABEL: "No hay ninguna categoría disponible. Seleccione la opción Producto en la opción inferior para agregar una nueva categoría o producto.",
            NO_PRODUCT_FOUND: "No se encontró ningún producto. Seleccione la opción Producto en la opción inferior para agregar una nueva categoría o producto.",
            POINTS: "puntos",
            OUT_OF_STOCK: 'Agotado',
            ADD: 'Agregar',
            CUSTOMIZE: 'Personalizar',
            YOUR_CART: 'Tu carrito',
            PLACE_ORDER_BUTTON: 'Realizar pedido',
            SUCCESS: 'Éxito',
            ORDER_PLACE_SUCCESS: 'Pedido realizado con éxito',
            PRINT: "Imprimir",
            PAYMENT_MODE_EXAMPLE: 'Ex. Efectivo/Tarjeta/Pago en línea',
            EARN: 'Ganar',
            ADDITIONAL_DISCOUNT: 'Descuento adicional:'
        },
        fr: {
            ITEM_ADDED_IN_CART: 'Article ajouté au panier',
            ITEM: 'Article',
            QTY: 'Qté',
            PRICE: 'Prix',
            OPTIONS: 'Possibilités',
            ADD_ONS: "Modules complémentaires",
            SUB_TOTAL: 'Sous-total :',
            TOTAL_DISCOUNT: 'Remise totale:',
            TOTAL_TAX: 'Taxe total:',
            FINAL_AMOUNT: 'Le montant final:',
            THANK_YOU_FOR_VISIT: "Merci pour votre visite! Reviens s'il te plait!",
            HAVE_GREAT_DAY: 'Passe une bonne journée!',
            POWERED_BY: 'Propulsé par : Areabites',
            PLACE_ORDER_LABEL: 'Passer la commande',
            NO_CATEGORY_AVAILABEL: "Aucune catégorie n'est disponible. Veuillez sélectionner l'option Produit en bas pour ajouter une nouvelle catégorie ou un nouveau produit.",
            NO_PRODUCT_FOUND: "Aucun produit trouvé. Veuillez sélectionner l'option Produit en bas pour ajouter une nouvelle catégorie ou un nouveau produit.",
            POINTS: "points",
            OUT_OF_STOCK: 'En rupture de stock',
            ADD: 'Ajouter',
            CUSTOMIZE: 'Personnaliser',
            YOUR_CART: 'Votre panier',
            PLACE_ORDER_BUTTON: 'Passer la commande',
            SUCCESS: 'Succès',
            ORDER_PLACE_SUCCESS: 'Commande passée avec succès',
            PRINT: "Imprimer",
            PAYMENT_MODE_EXAMPLE: 'Ex. Paiement en espèces/carte/en ligne',
            EARN: 'Gagner',
            ADDITIONAL_DISCOUNT: 'Remise additionnelle:'
        },
    },
    PRODUCT: {
        ADD_PRODUCT: {
            en: {
                PRODUCT_ADD_SUCCESS: 'Product added successfully',
                PRODUCT_ADD_ERROR: 'Error while adding product',
                CATEGORY_ADD_SUCCESS: 'Category added successfully',
                CATEGORY_ADD_ERROR: 'Error while adding category',
                ADD_MENU_ITEM: 'Add Menu Item',
                PRODUCT_DETAIL: 'Product Details',
                SERVING_TYPE: 'Serving Type',
                PRICING_INFORMATION: "Pricing Information",
                IS_OPTION_BASED_PRICING: 'Is Option Based Pricing?',
                OPTION_DETAILS: 'Options Details',
                DEFAULT: 'Default',
                VEG: 'Veg',
                NON_VEG: 'Non Veg',
                VEGAN: 'Vegan',
                REMOVE: 'Remove',
                ADD_ON_DETAILS: 'Add-Ons Details',
                OTHER_INFORMATION: 'Other Information',
                SELECT_CATEGORY: 'Select Category',
                NO_CATEGORY_FOUND: 'No Category found',
                ADD_CATEGORY: 'Add Category',
                SELECT_AVAILABILITY: 'Select Availability',
                SELECT_OPTION_TYPE: 'Select Option Type',
                VEGETERIAN: 'Vegeterian',
                NON_VEGETERIAN: 'Non Vegeterian',
                VEGAN: 'Vegan',
                SELECT_PRODUCT_IMAGE: 'Select Product Image',
                FILL_DETAILS_TO_ADD_CATEGORY: 'Fill details to add new category',
                SELECT_IMAGE: "Select Image",
                ADD_OPTION_TYPE: 'Add Option Type',
                ADD_OPTION: 'Add Option',
                ADD_ADD_ON_TYPE: 'Add Add-On Type',
                ADD_ADD_ON_ITEM: 'Add Add-On Item'
            },
            ar: {
                PRODUCT_ADD_SUCCESS: 'تمت إضافة المنتج بنجاح',
                PRODUCT_ADD_ERROR: 'حدث خطأ أثناء إضافة المنتج',
                CATEGORY_ADD_SUCCESS: 'تمت إضافة الفئة بنجاح',
                CATEGORY_ADD_ERROR: 'حدث خطأ أثناء إضافة الفئة',
                ADD_MENU_ITEM: 'إضافة عنصر القائمة',
                PRODUCT_DETAIL: 'تفاصيل المنتج',
                SERVING_TYPE: 'نوع التقديم',
                PRICING_INFORMATION: "معلومات التسعير",
                IS_OPTION_BASED_PRICING: 'هل التسعير يعتمد على الخيار؟',
                OPTION_DETAILS: 'تفاصيل الخيارات',
                DEFAULT: 'تقصير',
                VEG: 'نباتي',
                NON_VEG: 'غير نباتي',
                VEGAN: 'نباتي',
                REMOVE: 'يزيل',
                ADD_ON_DETAILS: 'تفاصيل الإضافات',
                OTHER_INFORMATION: 'معلومات أخرى',
                SELECT_CATEGORY: 'اختر الفئة',
                NO_CATEGORY_FOUND: 'لم يتم العثور على فئة',
                ADD_CATEGORY: 'إضافة فئة',
                SELECT_AVAILABILITY: 'حدد التوفر',
                SELECT_OPTION_TYPE: 'حدد نوع الخيار',
                VEGETERIAN: 'نباتي',
                NON_VEGETERIAN: 'غير نباتي',
                VEGAN: 'نباتي',
                SELECT_PRODUCT_IMAGE: 'حدد صورة المنتج',
                FILL_DETAILS_TO_ADD_CATEGORY: 'املأ التفاصيل لإضافة فئة جديدة',
                SELECT_IMAGE: "اختر صورة",
                ADD_OPTION_TYPE: 'إضافة نوع الخيار',
                ADD_OPTION: 'إضافة خيار',
                ADD_ADD_ON_TYPE: 'إضافة نوع الوظيفة الإضافية',
                ADD_ADD_ON_ITEM: 'إضافة عنصر إضافي'
            },
            ur: {
                PRODUCT_ADD_SUCCESS: 'پروڈکٹ کامیابی کے ساتھ شامل ہو گئی۔',
                PRODUCT_ADD_ERROR: 'پروڈکٹ شامل کرتے وقت خرابی۔',
                CATEGORY_ADD_SUCCESS: 'زمرہ کامیابی کے ساتھ شامل کیا گیا۔',
                CATEGORY_ADD_ERROR: 'زمرہ شامل کرتے وقت خرابی',
                ADD_MENU_ITEM: 'مینو آئٹم شامل کریں۔',
                PRODUCT_DETAIL: 'پروڈکٹ کی تفصیلات',
                SERVING_TYPE: 'سرونگ کی قسم',
                PRICING_INFORMATION: "قیمتوں کی معلومات",
                IS_OPTION_BASED_PRICING: 'کیا آپشن پر مبنی قیمت ہے؟',
                OPTION_DETAILS: 'اختیارات کی تفصیلات',
                DEFAULT: 'طے شدہ',
                VEG: 'سبزی',
                NON_VEG: 'نان ویج',
                VEGAN: 'ویگن',
                REMOVE: 'دور',
                ADD_ON_DETAILS: 'ایڈ آنز کی تفصیلات',
                OTHER_INFORMATION: 'دوسری معلومات',
                SELECT_CATEGORY: 'زمرہ منتخب کریں۔',
                NO_CATEGORY_FOUND: 'کوئی زمرہ نہیں ملا',
                ADD_CATEGORY: 'زمرہ شامل کریں۔',
                SELECT_AVAILABILITY: 'دستیابی کو منتخب کریں۔',
                SELECT_OPTION_TYPE: 'آپشن کی قسم منتخب کریں۔',
                VEGETERIAN: 'سبزی خور',
                NON_VEGETERIAN: 'نان ویجیٹیرین',
                VEGAN: 'ویگن',
                SELECT_PRODUCT_IMAGE: 'پروڈکٹ کی تصویر منتخب کریں۔',
                FILL_DETAILS_TO_ADD_CATEGORY: 'نیا زمرہ شامل کرنے کے لیے تفصیلات پُر کریں۔',
                SELECT_IMAGE: "تصویر منتخب کریں۔",
                ADD_OPTION_TYPE: 'آپشن کی قسم شامل کریں۔',
                ADD_OPTION: 'آپشن شامل کریں۔',
                ADD_ADD_ON_TYPE: 'ایڈ آن کی قسم شامل کریں۔',
                ADD_ADD_ON_ITEM: 'ایڈ آن آئٹم شامل کریں۔'
            },
            es: {
                PRODUCT_ADD_SUCCESS: 'Producto agregado exitosamente',
                PRODUCT_ADD_ERROR: 'Error al agregar producto',
                CATEGORY_ADD_SUCCESS: 'Categoría agregada exitosamente',
                CATEGORY_ADD_ERROR: 'Error al agregar categoría',
                ADD_MENU_ITEM: 'Agregar elemento de menú',
                PRODUCT_DETAIL: 'Detalles de producto',
                SERVING_TYPE: 'Tipo de porción',
                PRICING_INFORMATION: "Información de precio",
                IS_OPTION_BASED_PRICING: '¿El precio se basa en opciones?',
                OPTION_DETAILS: 'Detalles de opciones',
                DEFAULT: 'Por defecto',
                VEG: 'vegetales',
                NON_VEG: 'No vegetales',
                VEGAN: 'Vegana',
                REMOVE: 'Eliminar',
                ADD_ON_DETAILS: 'Detalles de complementos',
                OTHER_INFORMATION: 'Otra información',
                SELECT_CATEGORY: 'selecciona una categoría',
                NO_CATEGORY_FOUND: 'No se encontró ninguna categoría',
                ADD_CATEGORY: 'añadir categoría',
                SELECT_AVAILABILITY: 'Seleccione Disponibilidad',
                SELECT_OPTION_TYPE: 'Seleccionar tipo de opción',
                VEGETERIAN: 'Vegetariana',
                NON_VEGETERIAN: 'No vegetariana',
                VEGAN: 'Vegana',
                SELECT_PRODUCT_IMAGE: 'Seleccionar imagen del producto',
                FILL_DETAILS_TO_ADD_CATEGORY: 'Complete los detalles para agregar una nueva categoría',
                SELECT_IMAGE: "Seleccionar imagen",
                ADD_OPTION_TYPE: 'Agregar tipo de opción',
                ADD_OPTION: 'Agregar opción',
                ADD_ADD_ON_TYPE: 'Agregar tipo de complemento',
                ADD_ADD_ON_ITEM: 'Agregar elemento complementario'
            },
            fr: {
                PRODUCT_ADD_SUCCESS: 'Produit ajouté avec succès',
                PRODUCT_ADD_ERROR: "Erreur lors de l'ajout du produit",
                CATEGORY_ADD_SUCCESS: 'Catégorie ajoutée avec succès',
                CATEGORY_ADD_ERROR: "Erreur lors de l'ajout d'une catégorie",
                ADD_MENU_ITEM: 'Ajouter un élément de menu',
                PRODUCT_DETAIL: 'détails du produit',
                SERVING_TYPE: 'Type de portion',
                PRICING_INFORMATION: "Information sur les prix",
                IS_OPTION_BASED_PRICING: 'La tarification est-elle basée sur les options ?',
                OPTION_DETAILS: 'Détails des options',
                DEFAULT: 'Défaut',
                VEG: 'Végétarienne',
                NON_VEG: 'Non végétarien',
                VEGAN: 'Végétalienne',
                REMOVE: 'Remove',
                ADD_ON_DETAILS: 'Détails des modules complémentaires',
                OTHER_INFORMATION: 'les autres informations',
                SELECT_CATEGORY: 'Choisir une catégorie',
                NO_CATEGORY_FOUND: 'Aucune catégorie trouvée',
                ADD_CATEGORY: 'ajouter une catégorie',
                SELECT_AVAILABILITY: 'Sélectionnez la disponibilité',
                SELECT_OPTION_TYPE: "Sélectionnez le type d'option",
                VEGETERIAN: 'Végétarienne',
                NON_VEGETERIAN: 'Non végétarien',
                VEGAN: 'Végétalienne',
                SELECT_PRODUCT_IMAGE: "Sélectionnez l'image du produit",
                FILL_DETAILS_TO_ADD_CATEGORY: 'Remplissez les détails pour ajouter une nouvelle catégorie',
                SELECT_IMAGE: "Sélectionnez une image",
                ADD_OPTION_TYPE: "Ajouter un type d'option",
                ADD_OPTION: 'Ajouter une option',
                ADD_ADD_ON_TYPE: 'Ajouter un type de module complémentaire',
                ADD_ADD_ON_ITEM: 'Ajouter un élément complémentaire'
            },
        },
        PRODUCT_LIST: {
            en: {
                PRODUCT_ENABLE_SUCCESS: 'Product enabled successfully',
                PRODUCT_ENABLE_ERROR: 'Error while enabling product',
                PRODUCT_DISABLE_SUCCESS: 'Product disabled successfully',
                PRODUCT_DISABLE_ERROR: 'Error while disabling product',
                MENU_ITEMS: 'Menu Items',
                ADD_PRODUCT: 'Add Product',
                PRICE_INCLUDE_TAX: '*Price including tax and discount',
                IMAGE: 'Image',
                NAME: 'Name',
                CATEGORY: 'Category',
                PRICE: 'Price',
                OPTION_BASED_PRICE: 'Option Based Price',
                SERVING: 'Serving',
                AVAILAVBILITY: 'Availability',
                STATUS: 'Status',
                ACTION: 'Action',
                BREAKFAST_SYMBOL: 'B',
                LUNCH_SYMBOL: 'L',
                DINNER_SYMBOL: 'D',
                FULL_DAY_SYMBOL: '24h',
                ACTIVE: 'Active',
                IN_ACTIVE: 'In-Active',
                DETAIL: 'Detail',
                EDIT: 'Edit',
                DISABLE: 'Disable',
                ENABLE: 'Enable',
                NO_PRODUCT_FOUND: 'No Product Found',
                NOT_AUTHROZISE: 'You are not authorized to view products list'
            },
            ar: {
                PRODUCT_ENABLE_SUCCESS: 'تم تمكين المنتج بنجاح',
                PRODUCT_ENABLE_ERROR: 'حدث خطأ أثناء تمكين المنتج',
                PRODUCT_DISABLE_SUCCESS: 'تم تعطيل المنتج بنجاح',
                PRODUCT_DISABLE_ERROR: 'حدث خطأ أثناء تعطيل المنتج',
                MENU_ITEMS: 'عناصر القائمة',
                ADD_PRODUCT: 'أضف منتج',
                PRICE_INCLUDE_TAX: '*السعر شامل الضريبة والخصم',
                IMAGE: 'صورة',
                NAME: 'اسم',
                CATEGORY: 'فئة',
                PRICE: 'سعر',
                OPTION_BASED_PRICE: 'السعر على أساس الخيار',
                SERVING: 'خدمة',
                AVAILAVBILITY: 'التوفر',
                STATUS: 'حالة',
                ACTION: 'فعل',
                BREAKFAST_SYMBOL: 'ب',
                LUNCH_SYMBOL: 'ل',
                DINNER_SYMBOL: 'د',
                FULL_DAY_SYMBOL: '24 ساعة',
                ACTIVE: 'نشيط',
                IN_ACTIVE: 'غير نشط',
                DETAIL: 'التفاصيل',
                EDIT: 'يحرر',
                DISABLE: 'إبطال',
                ENABLE: 'يُمكَِن',
                NO_PRODUCT_FOUND: 'لم يتم العثور على منتج',
                NOT_AUTHROZISE: 'غير مصرح لك بمشاهدة قائمة المنتجات'
            },
            ur: {
                PRODUCT_ENABLE_SUCCESS: 'پروڈکٹ کو کامیابی سے فعال کر دیا گیا۔',
                PRODUCT_ENABLE_ERROR: 'پروڈکٹ کو فعال کرتے وقت خرابی',
                PRODUCT_DISABLE_SUCCESS: 'پروڈکٹ کو کامیابی سے غیر فعال کر دیا گیا۔',
                PRODUCT_DISABLE_ERROR: 'پروڈکٹ کو غیر فعال کرتے وقت خرابی۔',
                MENU_ITEMS: 'مینو آئٹمز',
                ADD_PRODUCT: 'پروڈکٹ شامل کریں۔',
                PRICE_INCLUDE_TAX: '*قیمت بشمول ٹیکس اور رعایت',
                IMAGE: 'تصویر',
                NAME: 'نام',
                CATEGORY: 'قسم',
                PRICE: 'قیمت',
                OPTION_BASED_PRICE: 'آپشن پر مبنی قیمت',
                SERVING: 'سرونگ',
                AVAILAVBILITY: 'دستیابی',
                STATUS: 'حالت',
                ACTION: 'عمل',
                BREAKFAST_SYMBOL: 'بی',
                LUNCH_SYMBOL: 'ایل',
                DINNER_SYMBOL: 'ڈی',
                FULL_DAY_SYMBOL: '24 گھنٹے',
                ACTIVE: 'فعال',
                IN_ACTIVE: 'غیر فعال',
                DETAIL: 'تفصیل',
                EDIT: 'يحرر',
                DISABLE: 'غیر فعال کریں۔',
                ENABLE: 'فعال',
                NO_PRODUCT_FOUND: 'کوئی پروڈکٹ نہیں ملا',
                NOT_AUTHROZISE: 'آپ کو مصنوعات کی فہرست دیکھنے کا اختیار نہیں ہے۔'
            },
            es: {
                PRODUCT_ENABLE_SUCCESS: 'Producto habilitado exitosamente',
                PRODUCT_ENABLE_ERROR: 'Error al habilitar el producto',
                PRODUCT_DISABLE_SUCCESS: 'Producto deshabilitado exitosamente',
                PRODUCT_DISABLE_ERROR: 'Error al desactivar el producto',
                MENU_ITEMS: 'Elementos de menú',
                ADD_PRODUCT: 'Agregar producto',
                PRICE_INCLUDE_TAX: '*Precio incluyendo impuestos y descuento',
                IMAGE: 'Imagen',
                NAME: 'Nombre',
                CATEGORY: 'Categoría',
                PRICE: 'Precio',
                OPTION_BASED_PRICE: 'Precio basado en opciones',
                SERVING: 'Servicio',
                AVAILAVBILITY: 'Disponibilidad',
                STATUS: 'Estado',
                ACTION: 'Acción',
                BREAKFAST_SYMBOL: 'B',
                LUNCH_SYMBOL: 'l',
                DINNER_SYMBOL: 'D',
                FULL_DAY_SYMBOL: '24 horas',
                ACTIVE: 'Activa',
                IN_ACTIVE: 'Inactiva',
                DETAIL: 'Detalle',
                EDIT: 'Editar',
                DISABLE: 'Desactivar',
                ENABLE: 'Permitir',
                NO_PRODUCT_FOUND: 'No se encontró ningún producto',
                NOT_AUTHROZISE: 'No estás autorizado a ver la lista de productos.'
            },
            fr: {
                PRODUCT_ENABLE_SUCCESS: 'Produit activé avec succès',
                PRODUCT_ENABLE_ERROR: "Erreur lors de l'activation du produit",
                PRODUCT_DISABLE_SUCCESS: 'Produit désactivé avec succès',
                PRODUCT_DISABLE_ERROR: 'Erreur lors de la désactivation du produit',
                MENU_ITEMS: 'Éléments de menu',
                ADD_PRODUCT: 'Ajouter un produit',
                PRICE_INCLUDE_TAX: '*Prix incluant taxes et remise',
                IMAGE: 'Image',
                NAME: 'Nom',
                CATEGORY: 'Catégorie',
                PRICE: 'Prix',
                OPTION_BASED_PRICE: "Prix basé sur l'option",
                SERVING: 'Portion',
                AVAILAVBILITY: 'Disponibilité',
                STATUS: 'Statut',
                ACTION: 'Action',
                BREAKFAST_SYMBOL: '',
                LUNCH_SYMBOL: 'L',
                DINNER_SYMBOL: 'D',
                FULL_DAY_SYMBOL: '24 heures',
                ACTIVE: 'Active',
                IN_ACTIVE: 'Inactive',
                DETAIL: 'Détail',
                EDIT: 'Modifier',
                DISABLE: 'Désactiver',
                ENABLE: 'Activer',
                NO_PRODUCT_FOUND: 'Aucun produit trouvé',
                NOT_AUTHROZISE: "Vous n'êtes pas autorisé à consulter la liste des produits"
            },
        },
        UPDATE_PRODUCT: {
            en: {
                ERROR_PRODUCT_DETAIL: 'Error while getting product detail',
                PRODUCT_UPDATE_SUCCESS: 'Product details updated successfully',
                PRODUCT_UPDATE_ERROR: 'Error while updating product details',
                UPDATE_MENU_ITEM: 'Update Menu Item'
            },
            ar: {
                ERROR_PRODUCT_DETAIL: 'حدث خطأ أثناء الحصول على تفاصيل المنتج',
                PRODUCT_UPDATE_SUCCESS: 'تم تحديث تفاصيل المنتج بنجاح',
                PRODUCT_UPDATE_ERROR: 'حدث خطأ أثناء تحديث تفاصيل المنتج',
                UPDATE_MENU_ITEM: 'تحديث عنصر القائمة'
            },
            ur: {
                ERROR_PRODUCT_DETAIL: 'پروڈکٹ کی تفصیلات حاصل کرتے وقت خرابی',
                PRODUCT_UPDATE_SUCCESS: 'پروڈکٹ کی تفصیلات کامیابی کے ساتھ اپ ڈیٹ ہو گئیں۔',
                PRODUCT_UPDATE_ERROR: 'پروڈکٹ کی تفصیلات کو اپ ڈیٹ کرتے وقت خرابی۔',
                UPDATE_MENU_ITEM: 'مینو آئٹم کو اپ ڈیٹ کریں۔'
            },
            es: {
                ERROR_PRODUCT_DETAIL: 'Error al obtener detalles del producto',
                PRODUCT_UPDATE_SUCCESS: 'Detalles del producto actualizados exitosamente',
                PRODUCT_UPDATE_ERROR: 'Error al actualizar los detalles del producto',
                UPDATE_MENU_ITEM: 'Actualizar elemento del menú'
            },
            fr: {
                ERROR_PRODUCT_DETAIL: "Erreur lors de l'obtention des détails du produit",
                PRODUCT_UPDATE_SUCCESS: 'Les détails du produit ont été mis à jour avec succès',
                PRODUCT_UPDATE_ERROR: 'Erreur lors de la mise à jour des détails du produit',
                UPDATE_MENU_ITEM: "Mettre à jour l'élément de menu"
            },
        }
    }
}

export const getContentLanguage = (language, screenType, key, folderType = null, isGeneral = false, isPlaceholder = false, isError = false) => {
    if (isGeneral) {
        return ContentLanguage.GENERAL[language][key]
    } else if (isPlaceholder) {
        return ContentLanguage.PLACEHOLDER[language][key]
    } else if (isError) {
        return ContentLanguage.ERROR[language][key]
    } else if (!isEmpty(folderType)) {
        return ContentLanguage[folderType][screenType][language][key]
    } else {
        return ContentLanguage[screenType][language][key]
    }
}