import { takeLatest, call, put } from 'redux-saga/effects'
import {
    customerLoginAPI, loginAPI, registerAPI, branchList, subscriptionList,
    userDetail, addBranch, addRestaurant, restaurantList, updateBranch, restaurantDetail,
    changePassword, forgotPassword, cityListApi, countryListApi, stateListApi,
    agentLogin, businessesList, loginTerminal, loginEmployee, supportRegisterAPI,
    ipLocation, userRegistrationAPI, updateUserNotificationToken, restaurantAccessRegisterAPI,
    branchDetail, operatorDetail, operatorList, operatorRegistrationAPI, updateOperatorLocations,
    businessListByLocation, updateBranchOrderingStatus, updateBusinessOrderingStatus,
    businessUserListAPI, mapUserWithBusinessAPI, registerBusinessUserAPI,
    updateCustomerDetailsAPI, updateRestaurantDetails
} from '../api-requests/user';
import {
    customerLoginRequestError, customerLoginRequestSuccess, loginSuccess,
    loginError, registerRequestError, registerRequestSuccess,
    subscriptionListRequestSuccess, subscriptionListRequestError,
    branchListRequestSuccess, branchListRequestError, userDetailRequestSuccess,
    userDetailRequestError, addBranchRequestSuccess, addBranchRequestError,
    addRestaurantRequestSuccess, addRestaurantRequestError, restaurantListRequestSuccess,
    restaurantListRequestError, updateBranchRequestSuccess, updateBranchRequestError,
    restaurantDetailRequestSuccess, restaurantDetailRequestError, forgotPasswordRequestSuccess,
    forgotPasswordRequestError, changePasswordRequestSuccess, changePasswordRequestError,
    countryListRequestSuccess, countryListRequestError, stateListRequestSuccess, stateListRequestError,
    cityListRequestSuccess, cityListRequestError, agentLoginRequestError, agentLoginRequestSuccess,
    businessListRequestError, businessListRequestSuccess, loginTerminalRequestError,
    loginTerminalRequestSuccess, loginEmployeeRequestError, loginEmployeeRequestSuccess,
    supportRegisterRequestError, supportRegisterRequestSuccess, ipLocationRequestError, ipLocationRequestSuccess,
    userRegistrationRequestError, userRegistrationRequestSuccess, updateUserNotificationTokenRequestError,
    updateUserNotificationTokenRequestSuccess, restaurantAccessRegisterRequestError,
    restaurantAccessRegisterRequestSuccess, branchDetailRequestError,
    branchDetailRequestSuccess, operatorDetailRequestError, operatorDetailRequestSuccess,
    operatorRegistrationRequestError, operatorRegistrationRequestSuccess, operatorListRequestError,
    operatorListRequestSuccess, updateOperatorLocationRequestError, updateOperatorLocationRequestSuccess,
    businessListByLocationRequestError, businessListByLocationRequestSuccess, updateBranchOrderingStatusRequestError,
    updateBranchOrderingStatusRequestSuccess, updateBusinessOrderingStatusRequestError, updateBusinessOrderingStatusRequestSuccess,
    businesUserListRequestError, businesUserListRequestSuccess,
    registerBusinessUserRequestError, registerBusinessUserRequestSuccess, mapUserWithBusinesRequestError,
    mapUserWithBusinesRequestSuccess, updateCustomerDetailsRequestError, updateCustomerDetailsRequestSuccess,
    updateRestaurantDetailsRequestError, updateRestaurantDetailsRequestSuccess
} from './user.action';
import { UserActionTypes } from './user.types'
import { displayConsoleLog } from '../../utils/Utils'
import { decryptResponseData } from '../../utils/DataDecryptionUtils';
import { APP_CONSTANTS } from '../../utils/AppConstants';

function* handleUpdateRestaurantDetailsRequest({ payload }) {
    try {
        const response = yield call(updateRestaurantDetails, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateRestaurantDetailsRequestSuccess(decryptedData))
            } else {
                yield put(updateRestaurantDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateRestaurantDetailsRequestError(error.response.data))
        }
    }
}

export function* updateRestaurantDetailsReq() {
    yield takeLatest(UserActionTypes.UPDATE_RESTAURANT_DETAILS_REQUEST, handleUpdateRestaurantDetailsRequest)
}

function* handleUpdateCustomerDetailsRequest({ payload }) {
    try {
        const response = yield call(updateCustomerDetailsAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateCustomerDetailsRequestSuccess(decryptedData))
            } else {
                yield put(updateCustomerDetailsRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateCustomerDetailsRequestError(error.response.data))
        }
    }
}

export function* updateCustomerDetailsReq() {
    yield takeLatest(UserActionTypes.UPDATE_CUSTOMER_DETAILS_REQUEST, handleUpdateCustomerDetailsRequest)
}

function* handleRegisterBusinessUserRequest({ payload }) {
    try {
        const response = yield call(registerBusinessUserAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(registerBusinessUserRequestSuccess(decryptedData))
            } else {
                yield put(registerBusinessUserRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(registerBusinessUserRequestError(error.response.data))
        }
    }
}

export function* registerBusinessUserReq() {
    yield takeLatest(UserActionTypes.REGISTER_BUSINESS_USER_REQUEST, handleRegisterBusinessUserRequest)
}

function* handleMapUserWithBusinessRequest({ payload }) {
    try {
        const response = yield call(mapUserWithBusinessAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(mapUserWithBusinesRequestSuccess(decryptedData))
            } else {
                yield put(mapUserWithBusinesRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(mapUserWithBusinesRequestError(error.response.data))
        }
    }
}

export function* mapUserWithBusinessReq() {
    yield takeLatest(UserActionTypes.MAP_USER_WITH_BUSINESS_REQUEST, handleMapUserWithBusinessRequest)
}

function* handleBusinessUserRequest({ payload }) {
    try {
        const response = yield call(businessUserListAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businesUserListRequestSuccess(decryptedData))
            } else {
                yield put(businesUserListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businesUserListRequestError(error.response.data))
        }
    }
}

export function* businessUserListReq() {
    yield takeLatest(UserActionTypes.BUSINESS_USER_LIST_REQUEST, handleBusinessUserRequest)
}

function* handleUpdateBranchOrderingStatusRequest({ payload }) {
    try {
        const response = yield call(updateBranchOrderingStatus, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBranchOrderingStatusRequestSuccess(decryptedData))
            } else {
                yield put(updateBranchOrderingStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateBranchOrderingStatusRequestError(error.response.data))
        }
    }
}

export function* updateBranchOrderingStatusReq() {
    yield takeLatest(UserActionTypes.UPDATE_BRANCH_ORDERING_STATUS_REQUEST, handleUpdateBranchOrderingStatusRequest)
}

function* handleUpdateBusinessOrderingStatusRequest({ payload }) {
    try {
        const response = yield call(updateBusinessOrderingStatus, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBusinessOrderingStatusRequestSuccess(decryptedData))
            } else {
                yield put(updateBusinessOrderingStatusRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateBusinessOrderingStatusRequestError(error.response.data))
        }
    }
}

export function* updateBusinessOrderingStatusReq() {
    yield takeLatest(UserActionTypes.UPDATE_BUSINESS_ORDERING_STATUS_REQUEST, handleUpdateBusinessOrderingStatusRequest)
}

function* handleBusinessListByLocationRequest({ payload }) {
    try {
        const response = yield call(businessListByLocation, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessListByLocationRequestSuccess(decryptedData))
            } else {
                yield put(businessListByLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(businessListByLocationRequestError(error.response.data))
        }
    }
}

export function* businessListByLocationReq() {
    yield takeLatest(UserActionTypes.BUSINESS_LIST_BY_LOCATION_REQUEST, handleBusinessListByLocationRequest)
}

function* handleUpdateOperatorLocationRequest({ payload }) {
    try {
        const response = yield call(updateOperatorLocations, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateOperatorLocationRequestSuccess(decryptedData))
            } else {
                yield put(updateOperatorLocationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(updateOperatorLocationRequestError(error.response.data))
        }
    }
}

export function* updateOperatorLocationReq() {
    yield takeLatest(UserActionTypes.UPDATE_OPERATOR_LOCATIONS_REQUEST, handleUpdateOperatorLocationRequest)
}

function* handleOperatorDetailRequest({ payload }) {
    try {
        const response = yield call(operatorDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorDetailRequestSuccess(decryptedData))
            } else {
                yield put(operatorDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorDetailRequestError(error.response.data))
        }
    }
}

export function* operatorDetailReq() {
    yield takeLatest(UserActionTypes.OPERATOR_DETAIL_REQUEST, handleOperatorDetailRequest)
}

function* handleOperatorListRequest() {
    try {
        const response = yield call(operatorList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorListRequestSuccess(decryptedData))
            } else {
                yield put(operatorListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorListRequestError(error.response.data))
        }
    }
}

export function* operatorListReq() {
    yield takeLatest(UserActionTypes.OPERATOR_LIST_REQUEST, handleOperatorListRequest)
}

function* handleOperatorRegisterRequest({ payload }) {
    try {
        const response = yield call(operatorRegistrationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(operatorRegistrationRequestSuccess(decryptedData))
            } else {
                yield put(operatorRegistrationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        if (error.response?.status === 401) {
            const errData = {
                status: error.response?.status,
                isSessionError: true,
                message: APP_CONSTANTS.SESSION_EXPIRE_MESSAGE
            }
            yield put({ type: UserActionTypes.COMMON_ERROR, errData })
        } else {
            displayConsoleLog(error.response?.data)
            yield put(operatorRegistrationRequestError(error.response.data))
        }
    }
}

export function* operatorRegisterReq() {
    yield takeLatest(UserActionTypes.OPERATOR_REGISTRATION_REQUEST, handleOperatorRegisterRequest)
}

function* handleBranchDetail({ payload }) {
    try {
        const response = yield call(branchDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(branchDetailRequestSuccess(decryptedData))
            } else {
                yield put(branchDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        yield put(branchDetailRequestError(error.response.data))
    }
}

export function* branchDetailReq() {
    yield takeLatest(UserActionTypes.BRANCH_DETAIL_REQUEST, handleBranchDetail)
}

function* handleRestaurantAccessRegister({ payload }) {
    try {
        const response = yield call(restaurantAccessRegisterAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(restaurantAccessRegisterRequestSuccess(decryptedData))
            } else {
                yield put(restaurantAccessRegisterRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        yield put(restaurantAccessRegisterRequestError(error.response.data))
    }
}

export function* restaurantAccessRegisterReq() {
    yield takeLatest(UserActionTypes.RESTAURANT_ACCESS_REGISTER_REQUEST, handleRestaurantAccessRegister)
}

function* handleUpdateUserNotificationToken({ payload }) {
    try {
        const response = yield call(updateUserNotificationToken, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateUserNotificationTokenRequestSuccess(decryptedData))
            } else {
                yield put(updateUserNotificationTokenRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error)
        displayConsoleLog(error.response?.data)
        yield put(updateUserNotificationTokenRequestError(error.response.data))
    }
}

export function* userUpdateUserNotificationTokenReq() {
    yield takeLatest(UserActionTypes.UPDATE_NOTIFICATION_TOKEN_REQUEST, handleUpdateUserNotificationToken)
}

function* handleUserRegistration({ payload }) {
    try {
        const response = yield call(userRegistrationAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(userRegistrationRequestSuccess(decryptedData))
            } else {
                yield put(userRegistrationRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(userRegistrationRequestError(error.response.data))
    }
}

export function* userRegistrationReq() {
    yield takeLatest(UserActionTypes.USER_REGISTRATION_REQUEST, handleUserRegistration)
}

function* handleIpLocation({ payload }) {
    try {
        const response = yield call(ipLocation, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            if (data.error) {
                yield put(ipLocationRequestError(data))
            } else {
                yield put(ipLocationRequestSuccess(data))
            }
        } else {
            throw response
        }
    } catch (error) {
        yield put(ipLocationRequestError(error.response))
    }
}

export function* ipLocationReq() {
    yield takeLatest(UserActionTypes.IP_LOCATION_REQUEST, handleIpLocation)
}

function* handleSupportRegistration({ payload }) {
    try {
        const response = yield call(supportRegisterAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(supportRegisterRequestSuccess(decryptedData))
            } else {
                yield put(supportRegisterRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(supportRegisterRequestError(error.response.data))
    }
}

export function* supportRegistrationReq() {
    yield takeLatest(UserActionTypes.SUPPORT_REGISTRATION_REQUEST, handleSupportRegistration)
}

function* handleLoginEmployee({ payload }) {
    try {
        const response = yield call(loginEmployee, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(loginEmployeeRequestSuccess(decryptedData))
            } else {
                yield put(loginEmployeeRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(loginEmployeeRequestError(error.response.data))
    }
}

export function* employeeLoginReq() {
    yield takeLatest(UserActionTypes.EMPLOYEE_LOGIN_REQUEST, handleLoginEmployee)
}

function* handleLoginTerminal({ payload }) {
    try {
        const response = yield call(loginTerminal, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(loginTerminalRequestSuccess(decryptedData))
            } else {
                yield put(loginTerminalRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(loginTerminalRequestError(error.response.data))
    }
}

export function* terminalLoginReq() {
    yield takeLatest(UserActionTypes.TERMINAL_LOGIN_REQUEST, handleLoginTerminal)
}


function* handleBusinessList({ payload }) {
    try {
        const response = yield call(businessesList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(businessListRequestSuccess(decryptedData))
            } else {
                yield put(businessListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(businessListRequestError(error.response.data))
    }
}

export function* businessListReq() {
    yield takeLatest(UserActionTypes.BUSINESS_LIST_REQUEST, handleBusinessList)
}

function* handleAgentLogin({ payload }) {
    try {
        const response = yield call(agentLogin, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(agentLoginRequestSuccess(decryptedData))
            } else {
                yield put(agentLoginRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(agentLoginRequestError(error.response.data))
    }
}

export function* agentLoginReq() {
    yield takeLatest(UserActionTypes.AGENT_LOGIN_REQUEST, handleAgentLogin)
}

function* handleChangePassword({ payload }) {
    try {
        const response = yield call(changePassword, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(changePasswordRequestSuccess(decryptedData))
            } else {
                yield put(changePasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(changePasswordRequestError(error.response.data))
    }
}

export function* changePasswordReq() {
    yield takeLatest(UserActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword)
}

function* handleForgotPassword({ payload }) {
    try {
        const response = yield call(forgotPassword, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(forgotPasswordRequestSuccess(decryptedData))
            } else {
                yield put(forgotPasswordRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(forgotPasswordRequestError(error.response.data))
    }
}

export function* forgotPasswordReq() {
    yield takeLatest(UserActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword)
}

function* handleRestaurantDetail({ payload }) {
    try {
        const response = yield call(restaurantDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(restaurantDetailRequestSuccess(decryptedData))
            } else {
                yield put(restaurantDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(restaurantDetailRequestError(error.response.data))
    }
}

export function* restuarantDetailReq() {
    yield takeLatest(UserActionTypes.RESTAURANT_DETAIL_REQUEST, handleRestaurantDetail)
}

function* handleUpdateBranch({ payload }) {
    try {
        const response = yield call(updateBranch, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(updateBranchRequestSuccess(decryptedData))
            } else {
                yield put(updateBranchRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(updateBranchRequestError(error.response.data))
    }
}

export function* updateBranchReq() {
    yield takeLatest(UserActionTypes.UPDATE_BRANCH_REQUEST, handleUpdateBranch)
}

function* handleRestaurantList({ payload }) {
    try {
        const response = yield call(restaurantList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(restaurantListRequestSuccess(decryptedData))
            } else {
                yield put(restaurantListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(restaurantListRequestError(error.response.data))
    }
}

export function* restaurantListReq() {
    yield takeLatest(UserActionTypes.RESTAURANT_LIST_REQUEST, handleRestaurantList)
}

function* handleAddRestaurant({ payload }) {
    try {
        const response = yield call(addRestaurant, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addRestaurantRequestSuccess(decryptedData))
            } else {
                yield put(addRestaurantRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addRestaurantRequestError(error.response.data))
    }
}

export function* addRestaurantReq() {
    yield takeLatest(UserActionTypes.ADD_RESTAURANT_REQUEST, handleAddRestaurant)
}

function* handleAddBranch({ payload }) {
    try {
        const response = yield call(addBranch, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(addBranchRequestSuccess(decryptedData))
            } else {
                yield put(addBranchRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(addBranchRequestError(error.response.data))
    }
}

export function* addBranchReq() {
    yield takeLatest(UserActionTypes.ADD_BRANCH_REQUEST, handleAddBranch)
}

function* handleUserDetail({ payload }) {
    try {
        const response = yield call(userDetail, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(userDetailRequestSuccess(decryptedData))
            } else {
                yield put(userDetailRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(userDetailRequestError(error.response.data))
    }
}

export function* userDetailReq() {
    yield takeLatest(UserActionTypes.USER_DETAIL_REQUEST, handleUserDetail)
}

function* handleBranchList({ payload }) {
    try {
        const response = yield call(branchList, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(branchListRequestSuccess(decryptedData))
            } else {
                yield put(branchListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(branchListRequestError(error.response.data))
    }
}

export function* branchListReq() {
    yield takeLatest(UserActionTypes.BRANCH_LIST_REQUEST, handleBranchList)
}

function* handleSubscriptionList() {
    try {
        const response = yield call(subscriptionList);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(subscriptionListRequestSuccess(decryptedData))
            } else {
                yield put(subscriptionListRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(subscriptionListRequestError(error.response.data))
    }
}

export function* subscriptionListReq() {
    yield takeLatest(UserActionTypes.SUBSCRIPTION_LIST_REQUEST, handleSubscriptionList)
}

function* handleCustomerLoginUser({ payload }) {
    try {
        const response = yield call(customerLoginAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(customerLoginRequestSuccess(decryptedData))
            } else {
                yield put(customerLoginRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(customerLoginRequestError(error.response.data))
    }
}

export function* customerLoginReq() {
    yield takeLatest(UserActionTypes.CUSTOMER_LOGIN_REQUEST, handleCustomerLoginUser)
}

function* handleRegisterUser({ payload }) {
    try {
        const response = yield call(registerAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(registerRequestSuccess(decryptedData))
            } else {
                yield put(registerRequestError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response.data)
        yield put(registerRequestError(error.response.data))
    }
}

export function* registerUserReq() {
    yield takeLatest(UserActionTypes.REGISTER_REQUEST, handleRegisterUser)
}

function* handleLoginUser({ payload }) {
    try {
        const response = yield call(loginAPI, payload);
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            const decryptedData = decryptResponseData(data)
            if (decryptedData.data !== null) {
                yield put(loginSuccess(decryptedData))
            } else {
                yield put(loginError(decryptedData))
            }
        } else {
            throw response
        }
    } catch (error) {
        displayConsoleLog(error.response)
        yield put(loginError(error.response.data))
    }
}

export function* loginUserReq() {
    yield takeLatest(UserActionTypes.LOGIN_USER_REQUEST, handleLoginUser)
}

function* handleCityList({ payload }) {
    try {
        const response = yield call(cityListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(cityListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        console.log('error', error.response)
        yield put(cityListRequestError(error.response))
    }
}

export function* cityListReq() {
    yield takeLatest(UserActionTypes.CITY_LIST_REQUEST, handleCityList)
}

function* handleStateList({ payload }) {
    try {
        const response = yield call(stateListApi, payload)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(stateListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        console.log('error', error.response)
        yield put(stateListRequestError(error.response))
    }
}

export function* stateListReq() {
    yield takeLatest(UserActionTypes.STATE_LIST_REQUEST, handleStateList)
}

function* handleCountryList() {
    try {
        const response = yield call(countryListApi)
        if (response.status >= 200 && response.status < 300) {
            const { data } = response
            yield put(countryListRequestSuccess(data))
        } else {
            throw response
        }
    } catch (error) {
        console.log('error', error.response)
        yield put(countryListRequestError(error.response))
    }
}

export function* countryListReq() {
    yield takeLatest(UserActionTypes.COUNTRY_LIST_REQUEST, handleCountryList)
}