import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import React, { useContext, useState } from 'react';
import './App.css';
import SignInScreen from './pages/sign-in/signInScreen'
import Registration from './pages/registration/registration'
import UpdateProfile from './pages/update-profile/updateProfile'
import { APP_CONSTANTS, ROUTES } from './utils/AppConstants.js';
import { Routes, Route, useNavigate } from "react-router-dom";
import RouteGuard from './components/route-guard/route-guard.component'
import { useSelector, useDispatch } from 'react-redux'
import { isLoggedInUserBusiness, removeSpecialCharAndChangeToPascal } from './utils/Utils.js';
import PlaceOrder from './pages/place-order/placeOrder'
import ProductList from './pages/product/product-list/productList'
import AddProduct from './pages/product/add-product/addProduct'
import UpdateProduct from './pages/product/update-product/updateProduct'
import MenuProductCustomize from './pages/menu-product-customize/menuProductCustomize'
import DatabaseContext from './database/dabaseContext.js';
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { getContentLanguage } from './utils/ContentLanguage.js';
import Select from 'react-select'

function App() {
  const { isLogin, displaySideBar, user } = useSelector((state) => state.user)
  let navigate = useNavigate();
  let dispatch = useDispatch()
  let { selectedLanguage, setSelectedLanguage } = useContext(DatabaseContext)
  const [englishSelected, setEnglishSelected] = useState(selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ENGLISH)
  const [arabicSelected, setArabicSelected] = useState(selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC)
  const [urduSelected, setUrduSelected] = useState(selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU)
  const [spanishSelected, setSpanishSelected] = useState(selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH)
  const [frenchSelected, setFrenchSelected] = useState(selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH)
  const [languageDropdown, setLanguageDropdown] = useState({ value: APP_CONSTANTS.LANGUAGE_MAP.ENGLISH, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.ENGLISH })
  const LANGUAGE_LIST = [
    { value: APP_CONSTANTS.LANGUAGE_MAP.ENGLISH, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.ENGLISH },
    { value: APP_CONSTANTS.LANGUAGE_MAP.ARABIC, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.ARABIC },
    { value: APP_CONSTANTS.LANGUAGE_MAP.URDU, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.URDU },
    { value: APP_CONSTANTS.LANGUAGE_MAP.SPANISH, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.SPANISH },
    { value: APP_CONSTANTS.LANGUAGE_MAP.FRENCH, label: APP_CONSTANTS.SUPPORTED_LANGUAGE.FRENCH },
  ]


  const theme = createTheme({
    palette: {
      primary: {
        main: '#f68d46',
        light: '#f68d46'
      }
    },
    typography: {
      fontSize: 13
    },
    direction: selectedLanguage === 'en' ? 'ltr' : 'rtl',
  })
  const clearLocalStorage = () => {
    sessionStorage.clear()
  }

  const handleItemTypeChoiceChange = type => {
    if (type === APP_CONSTANTS.SUPPORTED_LANGUAGE.ENGLISH) {
      setEnglishSelected(true)
      setArabicSelected(false)
      setUrduSelected(false)
      setSpanishSelected(false)
      setFrenchSelected(false)
      setSelectedLanguage(APP_CONSTANTS.LANGUAGE_MAP.ENGLISH)
    } else if (type === APP_CONSTANTS.SUPPORTED_LANGUAGE.ARABIC) {
      setEnglishSelected(false)
      setArabicSelected(true)
      setUrduSelected(false)
      setSpanishSelected(false)
      setFrenchSelected(false)
      setSelectedLanguage(APP_CONSTANTS.LANGUAGE_MAP.ARABIC)
    } else if (type === APP_CONSTANTS.SUPPORTED_LANGUAGE.URDU) {
      setEnglishSelected(false)
      setArabicSelected(false)
      setUrduSelected(true)
      setSpanishSelected(false)
      setFrenchSelected(false)
      setSelectedLanguage(APP_CONSTANTS.LANGUAGE_MAP.URDU)
    } else if (type === APP_CONSTANTS.SUPPORTED_LANGUAGE.SPANISH) {
      setEnglishSelected(false)
      setArabicSelected(false)
      setUrduSelected(false)
      setSpanishSelected(true)
      setFrenchSelected(false)
      setSelectedLanguage(APP_CONSTANTS.LANGUAGE_MAP.SPANISH)
    } else if (type === APP_CONSTANTS.SUPPORTED_LANGUAGE.FRENCH) {
      setEnglishSelected(false)
      setArabicSelected(false)
      setUrduSelected(false)
      setSpanishSelected(false)
      setFrenchSelected(true)
      setSelectedLanguage(APP_CONSTANTS.LANGUAGE_MAP.FRENCH)
    }
  }

  const PoppinsFontFamily = 'Poppins, sans-serif';
  const UrduFontFamily = 'NotoNastaliqUrdu, serif';

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw', direction: (selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC || selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU) ? 'rtl' : 'ltr', fontFamily: (selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC || selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU) ? UrduFontFamily : PoppinsFontFamily }}>
        <div className='language-parent-container'>
          <div className='language-container-label'>{getContentLanguage(selectedLanguage, null, 'SELECT_LANGUAGE', null, true)}</div>
          <Select
            menuPlacement="auto"
            value={languageDropdown}
            getOptionLabel={e => removeSpecialCharAndChangeToPascal(e.label)}
            getOptionValue={e => e}
            options={LANGUAGE_LIST}
            placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_STATE', null, false, true)}
            onChange={e => {
              setLanguageDropdown(e);
              setSelectedLanguage(e.value)
            }} />
          {/* <label className="ap-choice-label-container">
            <input type='radio' value={APP_CONSTANTS.SUPPORTED_LANGUAGE.ENGLISH} checked={englishSelected} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
            <span className="ap-choice-label">{removeSpecialCharAndChangeToPascal(APP_CONSTANTS.SUPPORTED_LANGUAGE.ENGLISH)}</span>
          </label>
          <label className="ap-choice-label-container">
            <input type='radio' value={APP_CONSTANTS.SUPPORTED_LANGUAGE.ARABIC} checked={arabicSelected} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
            <span className="ap-choice-label">{removeSpecialCharAndChangeToPascal(APP_CONSTANTS.SUPPORTED_LANGUAGE.ARABIC)}</span>
          </label>
          <label className="ap-choice-label-container">
            <input type='radio' value={APP_CONSTANTS.SUPPORTED_LANGUAGE.URDU} checked={urduSelected} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
            <span className="ap-choice-label">{removeSpecialCharAndChangeToPascal(APP_CONSTANTS.SUPPORTED_LANGUAGE.URDU)}</span>
          </label>
          <label className="ap-choice-label-container">
            <input type='radio' value={APP_CONSTANTS.SUPPORTED_LANGUAGE.SPANISH} checked={spanishSelected} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
            <span className="ap-choice-label">{removeSpecialCharAndChangeToPascal(APP_CONSTANTS.SUPPORTED_LANGUAGE.SPANISH)}</span>
          </label>
          <label className="ap-choice-label-container">
            <input type='radio' value={APP_CONSTANTS.SUPPORTED_LANGUAGE.FRENCH} checked={frenchSelected} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
            <span className="ap-choice-label">{removeSpecialCharAndChangeToPascal(APP_CONSTANTS.SUPPORTED_LANGUAGE.FRENCH)}</span>
          </label> */}
        </div>
        <div className={isLogin ? "content-area" : displaySideBar ? "content-area" : "content-area-full"}>
          <Routes>
            <Route path={ROUTES.home} element={<SignInScreen />} />
            <Route path={ROUTES.signUp} element={<Registration />} />
            <Route path={ROUTES.updatProfile} element={
              <RouteGuard>
                <UpdateProfile />
              </RouteGuard>
            } />
            <Route path={ROUTES.placeOrder} element={
              <RouteGuard>
                <PlaceOrder />
              </RouteGuard>
            } />
            <Route path={ROUTES.productList} element={
              <RouteGuard>
                <ProductList />
              </RouteGuard>
            } />
            <Route path={ROUTES.addProduct} element={
              <RouteGuard>
                <AddProduct />
              </RouteGuard>
            } />
            <Route path={ROUTES.updateProduct} element={
              <RouteGuard>
                <UpdateProduct />
              </RouteGuard>
            } />
            <Route path={ROUTES.menuProductCustomize} element={
              <RouteGuard>
                <MenuProductCustomize />
              </RouteGuard>
            } />
          </Routes>
        </div>
        {isLogin && displaySideBar === true && user && isLoggedInUserBusiness(user) && <div className='bottom-tab-nav'>
          <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.placeOrder)}>
            <div className='bottom-tab-nav-icon'>
              <img src={require('./assets/ic_nav_cart.png')} className='img' />
            </div>
            <div className='bottom-tab-nav-label'>{getContentLanguage(selectedLanguage, null, 'PLACE_ORDER_TAB', null, true)}</div>
          </div>
          <div className='bottom-tab-nav-container' onClick={() => navigate(ROUTES.productList)}>
            <div className='bottom-tab-nav-icon'>
              <img src={require('./assets/ic_nav_product_list.png')} className='img' />
            </div>
            <div className='bottom-tab-nav-label'>{getContentLanguage(selectedLanguage, null, 'PRODUCTS_TAB', null, true)}</div>
          </div>
          <div className='bottom-tab-nav-container' onClick={() => {
            clearLocalStorage()
            dispatch({ type: APP_CONSTANTS.LOGOUT })
          }}>
            <div className='bottom-tab-nav-icon'>
              <img src={require('./assets/ic_nav_logout.png')} className='img' />
            </div>
            <div className='bottom-tab-nav-label'>{getContentLanguage(selectedLanguage, null, 'LOGOUT_TAB', null, true)}</div>
          </div>

        </div>
        }
      </div>
    </ThemeProvider>
  );
}

export default App;
