import { APP_CONSTANTS, ROUTES } from "./utils/AppConstants";

const local = {
    baseUrl: 'http://localhost:3017/areabites/v1',
    imageUrl: 'http://localhost:3017/',
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.LOCAL,
    KEY: 'AREABITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

const dev = {
    baseUrl: 'https://api-dev.areabites.com/areabites/v1',
    imageUrl: 'https://api-dev.areabites.com/',
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.DEV,
    KEY: 'AREABITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

const prod = {
    baseUrl: 'https://api-prod.areabites.com/areabites/v1',
    imageUrl: 'https://api-prod.areabites.com/',
    cid: '911c6649-5930-4357-9afa-b558675a7c6c',
    countryStateCity: 'dnRveHpxQk1Dc1hWVWtvUlZBVDZxWWh4SDFDQ0dVSVhJSWtybFhIUQ==',
    environment: APP_CONSTANTS.ENVIRONMENT.PROD,
    KEY: 'AREABITES_LLC_06_21_2022_CALIFORNIA_UNITED_STATES_OF_AMERICA',
    IP_KEY: 'ee4db5c8b4251c'
};

export const config = prod