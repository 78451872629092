// require('dotenv').config()
import CryptoJS from 'crypto-js'
import { config } from '../config'

export const decryptResponseData = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, config.KEY, {
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.AnsiX923
    })
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData
}

export const decryptStringData = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, config.KEY, {
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.AnsiX923
    })
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData
}

export const encryptData = (data) => {
    var encryptedResponse = CryptoJS.AES.encrypt(JSON.stringify(data), config.KEY, {
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.AnsiX923
    }).toString()
    return encryptedResponse
}

export const encryptStringData = (data) => {
    var encryptedResponse = CryptoJS.AES.encrypt(data, config.KEY, {
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.AnsiX923
    }).toString()
    return encryptedResponse
}