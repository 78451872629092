import { APP_CONSTANTS } from "./AppConstants"
import { removeSpecialCharAndChangeToPascal } from "./Utils"

export const ReservationType = [
    {
        key: 'TABLE',
        value: 'Table'
    },
    {
        key: 'ROOM',
        value: 'Room'
    },
    {
        key: 'EVENT',
        value: 'Event'
    }
]

export const CONTACT_US_STATUS_TYPE = [
    {
        key: APP_CONSTANTS.CONTACT_US_MESSAGE_STATUS.OPEN,
        value: 'Open'
    },
    {
        key: APP_CONSTANTS.CONTACT_US_MESSAGE_STATUS.IN_PROCESS,
        value: 'In Process'
    },
    {
        key: APP_CONSTANTS.CONTACT_US_MESSAGE_STATUS.ON_HOLD,
        value: 'On Hold'
    },
    {
        key: APP_CONSTANTS.CONTACT_US_MESSAGE_STATUS.CLOSED,
        value: 'Closed'
    }
]

export const FEATURE_REQUEST_TYPE = [
    {
        key: 'GOOD_TO_HAVE',
        value: 'Good To Have'
    },
    {
        key: 'REQUIRED',
        value: 'Required'
    },
    {
        key: 'CRITICAL',
        value: 'Critical'
    }
]

export const ORDER_DELIVERY_TYPE_LIST = [
    {
        key: APP_CONSTANTS.ORDER_DELIVERY_TYPE.NOT_APPLICABLE,
        value: 'Not Applicable'
    },
    {
        key: APP_CONSTANTS.ORDER_DELIVERY_TYPE.DINE_IN,
        value: 'Dine In'
    },
    {
        key: APP_CONSTANTS.ORDER_DELIVERY_TYPE.CURBSIDE,
        value: 'Curbside'
    },
    {
        key: APP_CONSTANTS.ORDER_DELIVERY_TYPE.ROOM,
        value: 'Room'
    }
]

export const PRODUCT_MEASUREMENT_UNIT_LIST = [
    {
        key: 'KG',
        value: 'Killogram (Kg)'
    },
    {
        key: 'GRAM',
        value: 'Gram (g)'
    },
    {
        key: 'MILLIGRAM',
        value: 'Milligram (mg)'
    },
    {
        key: 'POUNDS',
        value: 'Pounds (lbs)'
    },
    {
        key: 'OUNCES',
        value: 'Ounces (oz)'
    },
    {
        key: 'LITER',
        value: 'liter (l)'
    },
    {
        key: 'MILLILITER',
        value: 'milliliters (ml)'
    },
    {
        key: 'FLUID_OUNCE',
        value: 'Fluid Ounce (fl oz)'
    },
    {
        key: 'CUP',
        value: 'Cup (c)'
    },
    {
        key: 'PINT',
        value: 'Pint (pt)'
    },
    {
        key: 'QUART',
        value: 'Quart (qt)'
    },
    {
        key: 'GALLON',
        value: 'Gallon (gal)'
    },
    {
        key: 'PIECE',
        value: 'Piece'
    },
    {
        key: 'PLATE',
        value: 'Plate'
    },
    {
        key: 'UNIT',
        value: 'Unit'
    },
    {
        key: 'PACKAGE',
        value: 'Package'
    }
]

export const PRODUCT_MEASUREMENT_UNIT_LIST_SYMBOL = [
    {
        key: 'KG',
        value: 'Kg'
    },
    {
        key: 'GRAM',
        value: 'g'
    },
    {
        key: 'MILLIGRAM',
        value: 'mg'
    },
    {
        key: 'POUNDS',
        value: 'lbs'
    },
    {
        key: 'OUNCES',
        value: 'oz'
    },
    {
        key: 'LITER',
        value: 'ltr'
    },
    {
        key: 'MILLILITER',
        value: 'ml'
    },
    {
        key: 'FLUID_OUNCE',
        value: 'fl oz'
    },
    {
        key: 'CUP',
        value: 'cup (c)'
    },
    {
        key: 'PINT',
        value: 'pint (pt)'
    },
    {
        key: 'QUART',
        value: 'quart (qt)'
    },
    {
        key: 'GALLON',
        value: 'gal'
    },
    {
        key: 'PIECE',
        value: 'piece'
    },
    {
        key: 'PLATE',
        value: 'plate'
    },
    {
        key: 'UNIT',
        value: 'unit'
    },
    {
        key: 'PACKAGE',
        value: 'package'
    }
]

export const EMPLOYEE_TYPE_LIST = [
    { key: APP_CONSTANTS.EMPLOYEE_TYPE.OPERATIONS, value: 'Operations' },
    // { key: APP_CONSTANTS.EMPLOYEE_TYPE.CAPTAIN, value: 'Captain' },
    { key: APP_CONSTANTS.EMPLOYEE_TYPE.WAITER, value: 'Waiter' },
    { key: APP_CONSTANTS.EMPLOYEE_TYPE.OTHER, value: 'Other' },
]