import React from "react";
import './styles.css'

const CommonCheckbox = ({ label, value, onChange, id }) => {
    return (
        <div className="common-checkbox-container">
            <input
                type="checkbox"
                className="form-check-input common-checkbox"
                id={id}
                checked={value}
                value={value}
                onChange={onChange}
                required />
            <label for="name" className="common-checkbox-label">{label}</label>
        </div>
    )
}

export default CommonCheckbox