export const userSchema = {
    title: 'userSchema',
    version: 1, // Increment the version number
    type: 'object',
    primaryKey: "userIdText",
    properties: {
        userId: {
            type: 'number',
            "maxLength": 99999
        },
        userIdText: {
            type: 'string',
            "maxLength": 99999
        },
        email: {
            type: 'string',
        },
        password: {
            type: 'string',
        },
        mobile: {
            type: 'number',
        },
        firstName: {
            type: 'string',
        },
        lastName: {
            type: 'string',
        },
        productCode: {
            type: 'string',
        },
        userType: {
            type: 'string',
        },
        status: {
            type: 'number',
        },
        subscriptionId: {
            type: 'number',
        },
        subscriptionStartDate: {
            type: 'string',
            format: 'date-time',
        },
        subscriptionEndDate: {
            type: 'string',
            format: 'date-time',
        },
        subscriptionStatus: {
            type: 'number',
        },
        isTrailSubscription: {
            type: 'boolean',
        },
        image: {
            type: 'string',
        },
        createdOn: {
            type: 'string',
            format: 'date-time',
        },
        updatedOn: {
            type: 'string',
            format: 'date-time',
        }
    },
    required: [],
};