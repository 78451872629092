import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.css'
import Loader from '../../../components/loader/loader.component'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { displayConsoleLog, displayErrorToast, displaySuccessToast, getCategoryLocalText, getOptionItemLabelLocalText, getOptionLabelLocalText, isEmpty, isEnglishText, isLoggedInUserBusiness, isLoggedInUserEmployee, isNull, screenCaptureAnalytics } from "../../../utils/Utils";
import { API_CONSTANTS, APP_CONSTANTS, ROUTES } from "../../../utils/AppConstants";
import { CurrencyLabel } from '../../../utils/CurrencyLabel'
import Select from 'react-select'
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../../../components/button/common-button/commonButton'
import ScreenLabel from "../../../components/label/screen-label/screenLabel";
import SubTextLabel from "../../../components/label/sub-text-label/subTextLabel";
import CommonForm from "../../../components/form/common-form/commonForm";
import { useNavigate } from 'react-router-dom';
import TopButton from "../../../components/button/top-button/topButton";
import CommonInputFull from "../../../components/input/common-input-full/commonInputFull";
import FormFieldLabel from "../../../components/label/form-field-label/formFieldLabel";
import CommonFormBorder from "../../../components/form/common-form-border/commonFormBorder";
import CommonInputRow from "../../../components/input/common-input-row/commonInputRow";
import CommonInputHalf from "../../../components/input/common-input-half/commonInputHalf";
import ButtonRow from "../../../components/button/button-row/buttonRow";
import { PRODUCT_MEASUREMENT_UNIT_LIST } from "../../../utils/ReservationType";
import CommonScreenContent from "../../../components/screen/common-screen-content/commonScreenContent";
import DatabaseContext from "../../../database/dabaseContext";
import { addCategoryRecord, getAllCategoryRecordByBusinessId } from "../../../database/operation/categoryOperation";
import { addProductRecord } from "../../../database/operation/productOperation";
import { getContentLanguage } from "../../../utils/ContentLanguage";

const AddProduct = props => {
    const { database, selectedLanguage } = useContext(DatabaseContext)
    const [categoryName, setCategoryName] = useState('')
    const [categoryNameArabic, setCategoryNameArabic] = useState('')
    const [categoryNameUrdu, setCategoryNameUrdu] = useState('')
    const [categoryNameSpanish, setCategoryNameSpanish] = useState('')
    const [categoryNameFrench, setCategoryNameFrench] = useState('')
    const [name, setName] = useState('')
    const [nameArabic, setNameArabic] = useState('')
    const [nameUrdu, setNameUrdu] = useState('')
    const [nameSpanish, setNameSpanish] = useState('')
    const [nameFrench, setNameFrench] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState('')
    const [tax, setTax] = useState('')
    const [isTaxable, setIsTaxable] = useState(false)
    const [selectedCat, setSelectedCat] = useState()
    const [image, setImage] = useState(null)
    const [imagePath, setImagePath] = useState(null)
    const [imageName, setImageName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    // const [selectedCurrency, setSelectedCurrency] = useState()
    const [showModal, setShowModal] = useState(false)
    const [isBreakfast, setIsBreakfast] = useState(false)
    const [isLunch, setIsLunch] = useState(false)
    const [isDinner, setIsDinner] = useState(false)
    const [is24Hour, setIs24Hour] = useState(false)
    let navigate = useNavigate();
    const [optionModalShow, setOptionModalShow] = useState(false)
    const [optionLabel, setOptionLabel] = useState('')
    const [optionLabelArabic, setOptionLabelArabic] = useState('')
    const [optionLabelUrdu, setOptionLabelUrdu] = useState('')
    const [optionLabelSpanish, setOptionLabelSpanish] = useState('')
    const [optionLabelFrench, setOptionLabelFrench] = useState('')
    const [optionList, setOptionList] = useState([])
    const [optionTypeModalShow, setOptionTypeModalShow] = useState(false)
    const [optionTypeName, setOptionTypeName] = useState('')
    const [optionTypeNameArabic, setOptionTypeNameArabic] = useState('')
    const [optionTypeNameUrdu, setOptionTypeNameUrdu] = useState('')
    const [optionTypeNameSpanish, setOptionTypeNameSpanish] = useState('')
    const [optionTypeNameFrench, setOptionTypeNameFrench] = useState('')
    const [optionTypeDescription, setOptionTypeDescription] = useState('')
    const [optionTypePrice, setOptionTypePrice] = useState(0)
    const [optionTypeDefault, setOptionTypeDefault] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')

    const [addOnModalShow, setAddOnModalShow] = useState(false)
    const [addOnLabel, setAddOnLabel] = useState('')
    const [addOnLabelArabic, setAddOnLabelArabic] = useState('')
    const [addOnLabelUrdu, setAddOnLabelUrdu] = useState('')
    const [addOnLabelSpanish, setAddOnLabelSpanish] = useState('')
    const [addOnLabelFrench, setAddOnLabelFrench] = useState('')
    const [addOnList, setAddOnList] = useState([])
    const [addOnTypeModalShow, setAddOnTypeModalShow] = useState(false)
    const [addOnTypeName, setAddOnTypeName] = useState('')
    const [addOnTypeNameArabic, setAddOnTypeNameArabic] = useState('')
    const [addOnTypeNameUrdu, setAddOnTypeNameUrdu] = useState('')
    const [addOnTypeNameSpanish, setAddOnTypeNameSpanish] = useState('')
    const [addOnTypeNameFrench, setAddOnTypeNameFrench] = useState('')
    const [addOnTypeDescription, setAddOnTypeDescription] = useState('')
    const [addOnTypePrice, setAddOnTypePrice] = useState(0)
    const [addOnTypeDefault, setAddOnTypeDefault] = useState(false)
    const [selectedAddOn, setSelectedAddOn] = useState('')

    const [itemVeg, setItemVeg] = useState(false)
    const [itemNonVeg, setItemNonVeg] = useState(false)
    const [itemVegan, setItemVegan] = useState(false)

    const [optionTypeVeg, setOptionTypeVeg] = useState(false)
    const [optionTypeNonVeg, setOptionTypeNonVeg] = useState(false)
    const [optionTypeVegan, setOptionTypeVegan] = useState(false)

    const [addOnTypeVeg, setAddOnTypeVeg] = useState(false)
    const [addOnTypeNonVeg, setAddOnTypeNonVeg] = useState(false)
    const [addOnTypeVegan, setAddOnTypeVegan] = useState(false)
    const [counter, setCounter] = useState(0)

    const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState()
    const [measurementUnitQty, setMeasurementUnitQty] = useState()

    const [isLimitPerOrder, setIsLimitPerOrder] = useState(false)
    const [limitCount, setLimitCount] = useState(0)

    const [isOptionBasedPrice, setIsOptionBasedPrice] = useState(false)
    const [optionBasedPriceList, setOptionBasedPriceList] = useState([])
    const [optionBasedName, setOptionBasedName] = useState('')
    const [optionBasedPrice, setOptionBasedPrice] = useState('')

    const [categoryRemarks, setCategoryRemarks] = useState('')
    const [productPoints, setProductPoints] = useState('')
    const [categoryList, setCatgoryList] = useState([])

    const productImageRef = useRef(null)
    const categoryImageRef = useRef(null)

    useEffect(() => {
        const data = {
            screen: 'ADD_PRODUCT',
            screenClass: 'ADD_PRODUCT'
        }
        screenCaptureAnalytics(data)
        fetchCategoryList()
    }, [])

    const fetchCategoryList = () => {
        getAllCategoryRecordByBusinessId(database, user.data.userDetails.businessDetails.businessId).then(data => {
            setCatgoryList(data)
        }).catch(error => {
            displayConsoleLog('error', error)
        })
    }

    const clearCategoryFields = () => {
        setShowModal(false)
        setCategoryName('')
        setCategoryNameArabic('')
        setCategoryNameUrdu('')
        setCategoryNameSpanish('')
        setCategoryNameFrench('')
        setCategoryRemarks('')
        if (categoryImageRef.current) {
            categoryImageRef.current.value = null
        }
    }

    const clearFields = () => {
        setName('')
        setNameArabic('')
        setNameUrdu('')
        setNameSpanish('')
        setNameFrench('')
        setDescription('')
        setPrice('')
        setDiscount('')
        setTax('')
        setIsTaxable(false)
        setImage(null)
        setImagePath(null)
        setImageName('')
        setIs24Hour(false)
        setIsBreakfast(false)
        setIsLunch(false)
        setIsDinner(false)
        setAddOnList([])
        setOptionList([])
        setIsOptionBasedPrice(false)
        setOptionBasedPriceList([])
        setOptionBasedPrice('')
        setOptionBasedName('')
        setProductPoints('')
        if (productImageRef.current) {
            productImageRef.current.value = null
        }
    }

    const nameHandle = e => {
        const name = e.target.value
        setName(name)
    }

    const descriptionHandle = e => {
        setDescription(e.target.value)
    }

    const priceHandle = e => {
        setPrice(e.target.value)
    }

    const discountHandle = e => {
        setDiscount(e.target.value)
    }

    const taxHandle = e => {
        setTax(e.target.value)
    }

    const handleImageChange = event => {
        if (!isEmpty(event.target.files)) {
            setImagePath(event.target.files[0])
            setImageName(event.target.files[0].name)
            const file = event.target.files[0];
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result)
            }
            reader.readAsDataURL(file)
        } else {
            setImagePath(null)
            setImageName('')
            setImage(null)
        }
    }

    const handleSelectedCatChange = event => {
        setSelectedCat(event)
    }

    const submitHandler = () => {
        const numberRegex = new RegExp('^(?:\d+|\d+\.\d{1,2})$')
        if (isEmpty(name) && isEmpty(nameArabic) && isEmpty(nameUrdu) && isEmpty(nameSpanish) && isEmpty(nameFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME_BLANK', null, false, false, true))
        } else if (!isEmpty(name) && !isEnglishText(name)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (!isEmpty(productPoints) && Number(productPoints) < 0) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_PRODUCT_POINT', null, false, false, true))
        } else if (isOptionBasedPrice === false && isNull(price)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PRODUCT_PRICE_BLANK', null, false, false, true))
        } else if (isOptionBasedPrice === false && !numberRegex.test(price)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_PRODUCT_PRICE', null, false, false, true))
        } else if (isOptionBasedPrice === false && !isNull(discount) && !numberRegex.test(discount)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_PRODUCT_DISCOUNT', null, false, false, true))
        } else if (isOptionBasedPrice === false && isTaxable === true && isNull(tax)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_PRODUCT_TAX', null, false, false, true))
        } else if (isOptionBasedPrice === false && isTaxable === true && !numberRegex.test(tax)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'INVALID_PRODUCT_TAX', null, false, false, true))
        } else if (isOptionBasedPrice === true && isEmpty(optionBasedPriceList)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PRODUCT_OPTION_PRICE_BLANK', null, false, false, true))
        } else if (isNull(selectedCat)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PRODUCT_CATEGORY_ERROR', null, false, false, true))
        } else if (isBreakfast === false && isLunch === false && isDinner === false && is24Hour === false) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'PRODUCT_AVAILABILITY_ERROR', null, false, false, true))
        } else if (itemVeg === false && itemNonVeg === false && itemVegan === false) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ITEM_OPTION_TYPE_SELECT_ERROR', null, false, false, true))
        } else if (isNull(selectedMeasurementUnit)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ITEM_SERVING_TYPE_SELECT_ERROR', null, false, false, true))
        } else if (isNull(measurementUnitQty)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ITEM_SERVING_TYPE_QUANTITY_ERROR', null, false, false, true))
        } else {
            const data = {
                name: !isEmpty(name) ? name : '',
                nameArabic: !isEmpty(nameArabic) ? nameArabic : '',
                nameUrdu: !isEmpty(nameUrdu) ? nameUrdu : '',
                nameSpanish: !isEmpty(nameSpanish) ? nameSpanish : '',
                nameFrench: !isEmpty(nameFrench) ? nameFrench : '',
                description: !isEmpty(description) ? description : '',
                currencyLabel: user.data.userDetails.locationDetails[0].currencyLabel,
                currencySymbol: user.data.userDetails.locationDetails[0].currencySymbol,
                currencyRegion: user.data.userDetails.locationDetails[0].currencyRegion,
                categoryId: selectedCat.categoryId,
                categoryName: !isEmpty(selectedCat.name) ? selectedCat.name : '',
                categoryNameArabic: !isEmpty(selectedCat.nameArabic) ? selectedCat.nameArabic : '',
                categoryNameUrdu: !isEmpty(selectedCat.nameUrdu) ? selectedCat.nameUrdu : '',
                categoryNameSpanish: !isEmpty(selectedCat.nameSpanish) ? selectedCat.nameSpanish : '',
                categoryNameFrench: !isEmpty(selectedCat.nameFrench) ? selectedCat.nameFrench : '',
                points: !isEmpty(productPoints) ? parseFloat(productPoints) : 0,
                measurementUnit: selectedMeasurementUnit.key,
                measurementQuantity: measurementUnitQty,
                isVeg: itemVeg,
                isNonVeg: itemNonVeg,
                isVegan: itemVegan,
                businessId: user.data.userDetails.businessDetails.businessId,
                locationId: [parseFloat(user.data.userDetails.locationDetails[0].locationId)],
                productCode: user.data.userDetails.productCode,
                options: !isEmpty(optionList) ? optionList : [],
                addOns: !isEmpty(addOnList) ? addOnList : [],
                isOptionBasedPricing: isOptionBasedPrice,
                optionBasedPricing: isOptionBasedPrice === true ? optionBasedPriceList : []
            }
            if (isOptionBasedPrice === false) {
                data.discount = !isEmpty(discount) ? parseFloat(discount) : 0
                data.price = !isEmpty(price) ? parseFloat(price) : 0
                data.isTaxable = isTaxable
                if (isTaxable === true)
                    data.tax = !isEmpty(tax) ? parseFloat(tax) : 0
                else data.tax = 0
            }

            if (is24Hour === true) {
                data.isBreakfast = true
                data.isDinner = true
                data.isLunch = true
                data.is24Hour = true
            } else {
                data.is24Hour = false
                data.isBreakfast = isBreakfast
                data.isDinner = isDinner
                data.isLunch = isLunch
            }
            if (!isNull(imagePath) && !isEmpty(imageName) && !isEmpty(image)) {
                data.image = image
            }

            addProductRecord(database, data).then(record => {
                displaySuccessToast(getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'PRODUCT_ADD_SUCCESS', 'PRODUCT'))
                clearFields()
            }).catch(error => {
                displayErrorToast(getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'PRODUCT_ADD_ERROR', 'PRODUCT'))
            })
        }
    }

    const addCategoryHandle = () => {
        if (isEmpty(categoryName) && isEmpty(categoryNameArabic) && isEmpty(categoryNameUrdu) && isEmpty(categoryNameSpanish) && isEmpty(categoryNameFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME_BLANK_ERROR', null, false, false, true))
        } else if (!isEmpty(categoryName) && !isEnglishText(categoryName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else {
            const data = {
                status: 1,
                businessId: user.data.userDetails.businessDetails.businessId
            }
            if (!isEmpty(categoryName)) {
                data.name = categoryName
            }
            if (!isEmpty(categoryNameArabic)) {
                data.nameArabic = categoryNameArabic
            }
            if (!isEmpty(categoryNameUrdu)) {
                data.nameUrdu = categoryNameUrdu
            }
            if (!isEmpty(categoryNameSpanish)) {
                data.nameSpanish = categoryNameSpanish
            }
            if (!isEmpty(categoryNameFrench)) {
                data.nameFrench = categoryNameFrench
            }
            if (!isEmpty(categoryRemarks)) {
                data.remarks = categoryRemarks
            }
            addCategoryRecord(database, data).then((record) => {
                clearCategoryFields()
                displaySuccessToast(getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'CATEGORY_ADD_SUCCESS', 'PRODUCT'))
                fetchCategoryList()
            }).catch(error => {
                displayConsoleLog('error', error)
                displayErrorToast(getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'CATEGORY_ADD_ERROR', 'PRODUCT'))
            })
        }
    }

    const handleAddOptionClick = () => {
        if (isEmpty(optionLabel) && isEmpty(optionLabelArabic) && isEmpty(optionLabelUrdu) && isEmpty(optionLabelSpanish) && isEmpty(optionLabelFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_LABEL_BLANK_ERROR', null, false, false, true))
        } else if (!isEmpty(optionLabel) && !isEnglishText(optionLabel)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else {
            setOptionModalShow(false)
            const optionObj = optionList.find(e => {
                let element = null
                if (!isEmpty(e.title) && e.title === optionLabel) {
                    element = e
                }
                if (!isEmpty(e.titleArabic) && e.titleArabic === optionLabelArabic) {
                    element = e
                }
                if (!isEmpty(e.titleUrdu) && e.titleUrdu === optionLabelUrdu) {
                    element = e
                }
                if (!isEmpty(e.titleSpanish) && e.titleSpanish === optionLabelSpanish) {
                    element = e
                }
                if (!isEmpty(e.titleFrench) && e.titleFrench === optionLabelFrench) {
                    element = e
                }
                return element
            })
            if (!isNull(optionObj)) {
                displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_ALREADY_EXISTS_ERROR', null, false, false, true))
            } else {
                let currentList = optionList
                const data = {
                    id: Date.now(),
                    title: !isEmpty(optionLabel) ? optionLabel : '',
                    titleArabic: !isEmpty(optionLabelArabic) ? optionLabelArabic : '',
                    titleUrdu: !isEmpty(optionLabelUrdu) ? optionLabelUrdu : '',
                    titleSpanish: !isEmpty(optionLabelSpanish) ? optionLabelSpanish : '',
                    titleFrench: !isEmpty(optionLabelFrench) ? optionLabelFrench : '',
                    items: []
                }
                currentList.push(data)
                setOptionList(currentList)
                console.log('currentList', currentList)
                setOptionLabel('')
                setOptionLabelArabic('')
                setOptionLabelUrdu('')
                setOptionLabelSpanish('')
                setOptionLabelFrench('')
            }
        }
    }

    const handleAddOptionDetailClick = () => {
        if (isEmpty(optionTypeName) && isEmpty(optionTypeNameArabic) && isEmpty(optionTypeNameUrdu) && isEmpty(optionTypeNameSpanish) && isEmpty(optionTypeNameFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_NAME_ERROR', null, false, false, true))
        } else if (!isEmpty(optionTypeName) && !isEnglishText(optionTypeName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isEmpty(optionTypePrice) || optionTypePrice < 0) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_PRICE_ERROR', null, false, false, true))
        } else {
            setOptionTypeModalShow(false)
            const optionObj = optionList.find(e => {
                let element = null
                if (!isEmpty(e.title) && e.title === selectedOption.title) {
                    element = e
                }
                if (!isEmpty(e.titleArabic) && e.titleArabic === selectedOption.titleArabic) {
                    element = e
                }
                if (!isEmpty(e.titleUrdu) && e.titleUrdu === selectedOption.titleUrdu) {
                    element = e
                }
                if (!isEmpty(e.titleSpanish) && e.titleSpanish === selectedOption.titleSpanish) {
                    element = e
                }
                if (!isEmpty(e.titleFrench) && e.titleFrench === selectedOption.titleFrench) {
                    element = e
                }
                return element
            })
            if (isNull(optionObj)) {
                displayErrorToast(getContentLanguage(selectedLanguage, null, 'SOMETHING_WENT_WRONG', null, true))
            } else {
                const optionItemObj = optionObj.items.find(e => {
                    let element = null
                    if (!isEmpty(e.name) && e.name === optionTypeName) {
                        element = e
                    }
                    if (!isEmpty(e.nameArabic) && e.nameArabic === optionTypeNameArabic) {
                        element = e
                    }
                    if (!isEmpty(e.nameUrdu) && e.nameUrdu === optionTypeNameUrdu) {
                        element = e
                    }
                    if (!isEmpty(e.nameSpanish) && e.nameSpanish === optionTypeNameSpanish) {
                        element = e
                    }
                    if (!isEmpty(e.nameFrench) && e.nameFrench === optionTypeNameFrench) {
                        element = e
                    }
                    return element
                })
                if (!isNull(optionItemObj)) {
                    displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_NAME_ALREADY_EXISTS_ERROR', null, false, false, true))
                } else {
                    let newList = []
                    optionList.forEach(e => {
                        let itemInList = false
                        if (!isEmpty(e.title) && e.title === selectedOption.title) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleArabic) && e.titleArabic === selectedOption.titleArabic) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleUrdu) && e.titleUrdu === selectedOption.titleUrdu) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleSpanish) && e.titleSpanish === selectedOption.titleSpanish) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleFrench) && e.titleFrench === selectedOption.titleFrench) {
                            itemInList = true
                        }
                        if (itemInList === true) {
                            let currentItemList = e.items
                            currentItemList.push({
                                id: Date.now(),
                                name: !isEmpty(optionTypeName) ? optionTypeName : '',
                                nameArabic: !isEmpty(optionTypeNameArabic) ? optionTypeNameArabic : '',
                                nameUrdu: !isEmpty(optionTypeNameUrdu) ? optionTypeNameUrdu : '',
                                nameSpanish: !isEmpty(optionTypeNameSpanish) ? optionTypeNameSpanish : '',
                                nameFrench: !isEmpty(optionTypeNameFrench) ? optionTypeNameFrench : '',
                                description: optionTypeDescription ? optionTypeDescription : '',
                                price: optionTypePrice,
                                isDefault: optionTypeDefault,
                                isVeg: optionTypeVeg === true ? true : false,
                                isNonVeg: optionTypeNonVeg === true ? true : false,
                                isVegan: optionTypeVegan === true ? true : false
                            })
                            const data = {
                                ...e,
                                items: currentItemList
                            }
                            // const data = {
                            //     title: e.title,
                            //     titleArabic: e.titleArabic,
                            //     titleUrdu: e.titleUrdu,
                            //     titleSpanish: e.titleSpanish,
                            //     titleFrench: e.titleFrench,
                            //     items: currentItemList
                            // }
                            newList.push(data)
                        } else {
                            newList.push(e)
                        }
                    })
                    setOptionList(newList)
                    setOptionTypeName('')
                    setOptionTypeNameArabic('')
                    setOptionTypeNameUrdu('')
                    setOptionTypeNameSpanish('')
                    setOptionTypeNameFrench('')
                    setOptionTypeDescription('')
                    setOptionTypePrice(0)
                    setOptionTypeDefault(false)

                }
            }
        }
    }

    const handleAddAddOnClick = () => {
        if (isEmpty(addOnLabel) && isEmpty(addOnLabelArabic) && isEmpty(addOnLabelUrdu) && isEmpty(addOnLabelSpanish) && isEmpty(addOnLabelFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL_ERROR', null, false, false, true))
        } else if (!isEmpty(addOnLabel) && !isEnglishText(addOnLabel)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else {
            setAddOnModalShow(false)
            const addOnObj = addOnList.find(e => {
                let element = null
                if (!isEmpty(e.title) && e.title === addOnLabel) {
                    element = e
                }
                if (!isEmpty(e.titleArabic) && e.titleArabic === addOnLabelArabic) {
                    element = e
                }
                if (!isEmpty(e.titleUrdu) && e.titleUrdu === addOnLabelUrdu) {
                    element = e
                }
                if (!isEmpty(e.titleSpanish) && e.titleSpanish === addOnLabelSpanish) {
                    element = e
                }
                if (!isEmpty(e.titleFrench) && e.titleFrench === addOnLabelFrench) {
                    element = e
                }
                return element
            })
            if (!isNull(addOnObj)) {
                displayErrorToast(getContentLanguage(selectedLanguage, null, 'ADD_ON_ALREADY_EXISTS', null, false, false, true))
            } else {
                let currentList = addOnList
                const data = {
                    id: Date.now(),
                    title: !isEmpty(addOnLabel) ? addOnLabel : '',
                    titleArabic: !isEmpty(addOnLabelArabic) ? addOnLabelArabic : '',
                    titleUrdu: !isEmpty(addOnLabelUrdu) ? addOnLabelUrdu : '',
                    titleSpanish: !isEmpty(addOnLabelSpanish) ? addOnLabelSpanish : '',
                    titleFrench: !isEmpty(addOnLabelFrench) ? addOnLabelFrench : '',
                    items: []
                }
                currentList.push(data)
                setAddOnList(currentList)
                setAddOnLabel('')
                setAddOnLabelArabic('')
                setAddOnLabelUrdu('')
                setAddOnLabelSpanish('')
                setAddOnLabelFrench('')
            }
        }
    }

    const handleAddAddOnDetailClick = () => {
        if (isEmpty(addOnTypeName) && isEmpty(addOnTypeNameArabic) && isEmpty(addOnTypeNameUrdu) && isEmpty(addOnTypeNameSpanish) && isEmpty(addOnTypeNameFrench)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ADD_ON_NAME_ERROR', null, false, false, true))
        } else if (!isEmpty(addOnTypeName) && !isEnglishText(addOnTypeName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ENGLISH_LANGUAGE_ONLY', null, true))
        } else if (isEmpty(addOnTypePrice) || addOnTypePrice < 0) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'ADD_ON_PRICE_ERROR', null, false, false, true))
        } else {
            setAddOnTypeModalShow(false)
            const addOnObj = addOnList.find(e => {
                let element = null
                if (!isEmpty(e.title) && e.title === selectedAddOn.title) {
                    element = e
                }
                if (!isEmpty(e.titleArabic) && e.titleArabic === selectedAddOn.titleArabic) {
                    element = e
                }
                if (!isEmpty(e.titleUrdu) && e.titleUrdu === selectedAddOn.titleUrdu) {
                    element = e
                }
                if (!isEmpty(e.titleSpanish) && e.titleSpanish === selectedAddOn.titleSpanish) {
                    element = e
                }
                if (!isEmpty(e.titleFrench) && e.titleFrench === selectedAddOn.titleFrench) {
                    element = e
                }
                return element
            })
            if (isNull(addOnObj)) {
                displayErrorToast(getContentLanguage(selectedLanguage, null, 'SOMETHING_WENT_WRONG', null, true))
            } else {
                const addOnItemObj = addOnObj.items.find(e => {
                    let element = null
                    if (!isEmpty(e.name) && e.name === addOnTypeName.name) {
                        element = e
                    }
                    if (!isEmpty(e.nameArabic) && e.nameArabic === addOnTypeName.nameArabic) {
                        element = e
                    }
                    if (!isEmpty(e.nameUrdu) && e.nameUrdu === addOnTypeName.nameUrdu) {
                        element = e
                    }
                    if (!isEmpty(e.nameSpanish) && e.nameSpanish === addOnTypeName.nameSpanish) {
                        element = e
                    }
                    if (!isEmpty(e.nameFrench) && e.nameFrench === addOnTypeName.nameFrench) {
                        element = e
                    }
                    return element
                })
                if (!isNull(addOnItemObj)) {
                    displayErrorToast(getContentLanguage(selectedLanguage, null, 'ADD_ON_NAME_ALREADY_EXISTS_ERROR', null, false, false, true))
                } else {
                    let newList = []
                    addOnList.forEach(e => {
                        let itemInList = false
                        if (!isEmpty(e.title) && e.title === selectedAddOn.title) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleArabic) && e.titleArabic === selectedAddOn.titleArabic) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleUrdu) && e.titleUrdu === selectedAddOn.titleUrdu) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleSpanish) && e.titleSpanish === selectedAddOn.titleSpanish) {
                            itemInList = true
                        }
                        if (!isEmpty(e.titleFrench) && e.titleFrench === selectedAddOn.titleFrench) {
                            itemInList = true
                        }
                        if (itemInList === true) {
                            let currentItemList = e.items
                            currentItemList.push({
                                id: Date.now(),
                                name: !isEmpty(addOnTypeName) ? addOnTypeName : '',
                                nameArabic: !isEmpty(addOnTypeNameArabic) ? addOnTypeNameArabic : '',
                                nameUrdu: !isEmpty(addOnTypeNameUrdu) ? addOnTypeNameUrdu : '',
                                nameSpanish: !isEmpty(addOnTypeNameSpanish) ? addOnTypeNameSpanish : '',
                                nameFrench: !isEmpty(addOnTypeNameFrench) ? addOnTypeNameFrench : '',
                                description: addOnTypeDescription ? addOnTypeDescription : '',
                                price: addOnTypePrice,
                                isVeg: addOnTypeVeg === true ? true : false,
                                isNonVeg: addOnTypeNonVeg === true ? true : false,
                                isVegan: addOnTypeVegan === true ? true : false
                            })
                            const data = {
                                ...e,
                                items: currentItemList
                            }
                            // const data = {
                            //     title: e.title,
                            //     titleArabic: e.titleArabic,
                            //     titleUrdu: e.titleUrdu,
                            //     titleSpanish: e.titleSpanish,
                            //     titleFrench: e.titleFrench,
                            //     items: currentItemList
                            // }
                            newList.push(data)
                        } else {
                            newList.push(e)
                        }
                    })
                    setAddOnList(newList)
                    setAddOnTypeName('')
                    setAddOnTypeNameArabic('')
                    setAddOnTypeNameUrdu('')
                    setAddOnTypeNameSpanish('')
                    setAddOnTypeNameFrench('')
                    setAddOnTypeDescription('')
                    setAddOnTypePrice(0)

                }
            }
        }
    }

    const handleItemTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setItemVeg(true)
            setItemNonVeg(false)
            setItemVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setItemVeg(false)
            setItemNonVeg(true)
            setItemVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setItemVeg(false)
            setItemNonVeg(false)
            setItemVegan(true)
        }
    }

    const handleOptionTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setOptionTypeVeg(true)
            setOptionTypeNonVeg(false)
            setOptionTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setOptionTypeVeg(false)
            setOptionTypeNonVeg(true)
            setOptionTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setOptionTypeVeg(false)
            setOptionTypeNonVeg(false)
            setOptionTypeVegan(true)
        }
    }

    const handleAddOnTypeChoiceChange = type => {
        if (type === APP_CONSTANTS.ITEM_TYPE.VEG) {
            setAddOnTypeVeg(true)
            setAddOnTypeNonVeg(false)
            setAddOnTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.NON_VEG) {
            setAddOnTypeVeg(false)
            setAddOnTypeNonVeg(true)
            setAddOnTypeVegan(false)
        } else if (type === APP_CONSTANTS.ITEM_TYPE.VEGAN) {
            setAddOnTypeVeg(false)
            setAddOnTypeNonVeg(false)
            setAddOnTypeVegan(true)
        }
    }

    const removeCompleteOption = (element, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            // const filteredList = addOnList.filter(e => (!isEmpty(e.title) && !isEmpty(element.title) && e.title !== element.title) || (!isEmpty(e.titleArabic) && !isEmpty(element.titleArabic) && e.titleArabic !== element.titleArabic) || (!isEmpty(e.titleUrdu) && !isEmpty(element.titleUrdu) && e.titleUrdu !== element.titleUrdu) && (!isEmpty(e.titleSpanish) && !isEmpty(element.titleSpanish) && e.titleSpanish !== element.titleSpanish) || (!isEmpty(e.titleFrench) && !isEmpty(element.titleFrench) && e.titleFrench !== element.titleFrench))
            const filteredList = addOnList.filter(e => e.id !== element.id)
            setAddOnList(filteredList)
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            // const filteredList = optionList.filter(e => (!isEmpty(e.title) && !isEmpty(element.title) && e.title !== element.title) || (!isEmpty(e.titleArabic) && !isEmpty(element.titleArabic) && e.titleArabic !== element.titleArabic) || (!isEmpty(e.titleUrdu) && !isEmpty(element.titleUrdu) && e.titleUrdu !== element.titleUrdu) && (!isEmpty(e.titleSpanish) && !isEmpty(element.titleSpanish) && e.titleSpanish !== element.titleSpanish) || (!isEmpty(e.titleFrench) && !isEmpty(element.titleFrench) && e.titleFrench !== element.titleFrench))
            const filteredList = optionList.filter(e => e.id !== element.id)
            setOptionList(filteredList)
        }
    }

    //name = subItem element = mainList
    const removeSelectedItem = (subElement, element, type) => {
        if (type === APP_CONSTANTS.KEY_ADD_ON) {
            const addOnObj = addOnList.find(e => e.id === element.id)
            // const addOnObj = addOnList.find(e => {
            //     let element1 = null
            //     if (!isEmpty(e.title) && e.title === element.title) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleArabic) && e.titleArabic === element.titleArabic) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleUrdu) && e.titleUrdu === element.titleUrdu) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleSpanish) && e.titleSpanish === element.titleSpanish) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleFrench) && e.titleFrench === element.titleFrench) {
            //         element1 = e
            //     }
            //     return element1
            // })
            if (!isNull(addOnObj)) {
                // const filteredItemList = addOnObj.items.filter(e1 => (!isEmpty(e1.name) && !isEmpty(subElement.name) && e1.name !== subElement.name) || (!isEmpty(e1.nameArabic) && !isEmpty(subElement.nameArabic) && e1.nameArabic !== subElement.nameArabic) || (!isEmpty(e1.nameUrdu) && !isEmpty(subElement.nameUrdu) && e1.nameUrdu !== subElement.nameUrdu) || (!isEmpty(e1.nameSpanish) && !isEmpty(subElement.nameSpanish) && e1.nameSpanish !== subElement.nameSpanish) || (!isEmpty(e1.nameFrench) && !isEmpty(subElement.nameFrench) && e1.nameFrench !== subElement.nameFrench))
                const filteredItemList = addOnObj.items.filter(e1 => e1.id !== subElement.id)
                let tempList = addOnList
                // const objIndex = tempList.findIndex(item => (!isEmpty(item.title) && !isEmpty(element.title) && item.title === element.title) || (!isEmpty(item.titleArabic) && !isEmpty(element.titleArabic) && item.titleArabic === element.titleArabic) || (!isEmpty(item.titleUrdu) && !isEmpty(element.titleUrdu) && item.titleUrdu === element.titleUrdu) || (!isEmpty(item.titleSpanish) && !isEmpty(element.titleSpanish) && item.titleSpanish === element.titleSpanish) || (!isEmpty(item.titleFrench) && !isEmpty(element.titleFrench) && item.titleFrench === element.titleFrench))
                const objIndex = tempList.findIndex(item => item.id === element.id)
                if (objIndex > -1) {
                    const updatedTempList = tempList.map((item, index) => {
                        if (index === objIndex) {
                            return {
                                ...item,
                                items: filteredItemList
                            };
                        }
                        return item;
                    });
                    setAddOnList(updatedTempList)
                    let count = counter
                    setCounter(count + 1)
                }
            }
        } else if (type === APP_CONSTANTS.KEY_OPTION) {
            const optionObj = optionList.find(e => e.id === element.id)
            // const optionObj = optionList.find(e => {
            //     let element1 = null
            //     if (!isEmpty(e.title) && e.title === element.title) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleArabic) && e.titleArabic === element.titleArabic) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleUrdu) && e.titleUrdu === element.titleUrdu) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleSpanish) && e.titleSpanish === element.titleSpanish) {
            //         element1 = e
            //     }
            //     if (!isEmpty(e.titleFrench) && e.titleFrench === element.titleFrench) {
            //         element1 = e
            //     }
            //     return element1
            // })
            if (!isNull(optionObj)) {
                // const filteredItemList = optionObj.items.filter(e1 => (!isEmpty(e1.name) && !isEmpty(subElement.name) && e1.name !== subElement.name) || (!isEmpty(e1.nameArabic) && !isEmpty(subElement.nameArabic) && e1.nameArabic !== subElement.nameArabic) || (!isEmpty(e1.nameUrdu) && !isEmpty(subElement.nameUrdu) && e1.nameUrdu !== subElement.nameUrdu) || (!isEmpty(e1.nameSpanish) && !isEmpty(subElement.nameSpanish) && e1.nameSpanish !== subElement.nameSpanish) || (!isEmpty(e1.nameFrench) && !isEmpty(subElement.nameFrench) && e1.nameFrench !== subElement.nameFrench))
                const filteredItemList = optionObj.items.filter(e1 => e1.id !== subElement.id)
                let tempList = optionList
                // const objIndex = tempList.findIndex(item => (!isEmpty(item.title) && !isEmpty(element.title) && item.title === element.title) || (!isEmpty(item.titleArabic) && !isEmpty(element.titleArabic) && item.titleArabic === element.titleArabic) || (!isEmpty(item.titleUrdu) && !isEmpty(element.titleUrdu) && item.titleUrdu === element.titleUrdu) || (!isEmpty(item.titleSpanish) && !isEmpty(element.titleSpanish) && item.titleSpanish === element.titleSpanish) || (!isEmpty(item.titleFrench) && !isEmpty(element.titleFrench) && item.titleFrench === element.titleFrench))
                const objIndex = tempList.findIndex(item => item.id === element.id)
                if (objIndex > -1) {
                    const updatedTempList = tempList.map((item, index) => {
                        if (index === objIndex) {
                            return {
                                ...item,
                                items: filteredItemList
                            };
                        }
                        return item;
                    });
                    setOptionList(updatedTempList)
                    let count = counter
                    setCounter(count + 1)
                }
            }
        }
    }

    const handleOptionBasedPriceAddClick = () => {
        if (isEmpty(optionBasedName)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_NAME_ERROR', null, false, false, true))
        } else if (isEmpty(optionBasedPrice)) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_PRICE_ERROR', null, false, false, true))
        } else if (Number(optionBasedPrice) < 0) {
            displayErrorToast(getContentLanguage(selectedLanguage, null, 'OPTION_PRICE_ERROR', null, false, false, true))
        } else {
            const data = {
                label: optionBasedName,
                price: Number(optionBasedPrice)
            }
            let currentList = optionBasedPriceList
            currentList.push(data)
            setOptionBasedPriceList(currentList)
            setOptionBasedName('')
            setOptionBasedPrice('')
        }
    }

    return (
        <>
            <CommonScreenContent>
                <CommonInputRow className='margin-top-10'>
                    {/* <ScreenLabel label='Add Product' /> */}
                    {(!isEmpty(user) && !isEmpty(user.data.userDetails.businessDetails)) ? (
                        <div className="cmc-label-container mt-2">
                            <div className="mcp-logo-container">
                                <img className="img" src={require('../../../assets/logoSingle.png')} alt="img" />
                            </div>
                            {/* <div className="mcp-restaurant-label">{user.data.userDetails.businessDetails.businessName}</div> */}
                            <div className="mcp-restaurant-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_MENU_ITEM', 'PRODUCT')}</div>
                        </div>
                    ) :
                        (<div className="cmc-label-container">
                            <ScreenLabel label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_MENU_ITEM', 'PRODUCT')} />
                        </div>)
                    }
                </CommonInputRow>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'PRODUCT_DETAIL', 'PRODUCT')} />
                    <CommonInputFull
                        id='name'
                        onChange={nameHandle}
                        value={name}
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME', null, false, true)}
                        type='text' />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull
                        id='nameArabic'
                        onChange={e => setNameArabic(e.target.value)}
                        value={nameArabic}
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME_ARABIC', null, false, true)}
                        type='text' />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull
                        id='nameUrdu'
                        onChange={e => setNameUrdu(e.target.value)}
                        value={nameUrdu}
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME_URDU', null, false, true)}
                        type='text' />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull
                        id='nameSpanish'
                        onChange={e => setNameSpanish(e.target.value)}
                        value={nameSpanish}
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME_SPANISH', null, false, true)}
                        type='text' />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull
                        id='nameFrench'
                        onChange={e => setNameFrench(e.target.value)}
                        value={nameFrench}
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRODUCT_NAME_FRENCH', null, false, true)}
                        type='text' />}
                    <CommonInputRow>
                        <CommonInputHalf
                            type="text"
                            id="description"
                            placeholder={getContentLanguage(selectedLanguage, null, 'DESCRIPTION', null, false, true)}
                            value={description}
                            onChange={descriptionHandle} />
                        <CommonInputHalf
                            type='number'
                            id='points'
                            onChange={e => setProductPoints(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'POINTS', null, false, true)}
                            value={productPoints}
                        />
                    </CommonInputRow>
                    <CommonInputRow>
                        <div className="select-half-width">
                            <FormFieldLabel className='align-left mt-0' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SERVING_TYPE', 'PRODUCT')} />
                            {PRODUCT_MEASUREMENT_UNIT_LIST && <Select
                                className='select-full-width-no-border'
                                value={selectedMeasurementUnit}
                                getOptionLabel={e => e.value}
                                getOptionValue={e => e}
                                options={PRODUCT_MEASUREMENT_UNIT_LIST}
                                placeholder={getContentLanguage(selectedLanguage, null, 'SERVING_TYPE', null, false, true)}
                                onChange={e => setSelectedMeasurementUnit(e)} />}
                        </div>
                        <CommonInputHalf type='number'
                            id='measurementUnitQty'
                            onChange={e => setMeasurementUnitQty(e.target.value)}
                            placeholder={getContentLanguage(selectedLanguage, null, 'SERVING_QUANTITY', null, false, true)}
                            value={measurementUnitQty} />
                    </CommonInputRow>
                </CommonFormBorder>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'PRICING_INFORMATION', 'PRODUCT')} />
                    <div className="p-row-input mb-2">
                        <input
                            type="checkbox"
                            className="form-check-input p-input-text"
                            id="name"
                            checked={isOptionBasedPrice}
                            value={isOptionBasedPrice}
                            onChange={() => {
                                setIsOptionBasedPrice(!isOptionBasedPrice)
                            }}
                            required />
                        <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'IS_OPTION_BASED_PRICING', 'PRODUCT')}</label>
                    </div>
                    {isOptionBasedPrice && <div className="option-price-parent-container">
                        <CommonInputRow className='option-price-field-container'>
                            <CommonInputHalf
                                type="text"
                                id="optionName"
                                placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_NAME', null, false, true)}
                                value={optionBasedName}
                                onChange={e => setOptionBasedName(e.target.value)}
                            />
                            <CommonInputHalf
                                type="number"
                                id="optionNamePrice"
                                placeholder={getContentLanguage(selectedLanguage, null, 'PRICE', null, false, true)}
                                value={optionBasedPrice}
                                onChange={e => setOptionBasedPrice(e.target.value)}
                            />
                        </CommonInputRow>
                        <div className="ap-add-icon-container ms-2" onClick={() => { handleOptionBasedPriceAddClick() }}>
                            <img src={require('../../../assets/plus.png')} alt="plus" className='img' />
                        </div>
                    </div>}
                    {isOptionBasedPrice && !isEmpty(optionBasedPriceList) &&
                        <div className="option-price-list-container">
                            {optionBasedPriceList.map(e => (
                                <div className="option-price-list-item">
                                    <div>{e.label} - {e.price}</div>
                                </div>
                            ))}
                        </div>
                    }
                    {!isOptionBasedPrice && <div>
                        <CommonInputRow>
                            <CommonInputHalf
                                type="number"
                                id="name"
                                placeholder={getContentLanguage(selectedLanguage, null, 'PRICE', null, false, true)}
                                value={price}
                                onChange={priceHandle}
                                isRequired={true}
                            />
                            <CommonInputHalf
                                type="number"
                                id="name"
                                placeholder={getContentLanguage(selectedLanguage, null, 'DISCOUNT_PERCENTAGE', null, false, true)}
                                value={discount}
                                onChange={discountHandle}
                            />
                        </CommonInputRow>
                        <CommonInputRow>
                            <div className="p-row-input">
                                <input
                                    type="checkbox"
                                    className="form-check-input p-input-text"
                                    id="name"
                                    checked={isTaxable}
                                    value={isTaxable}
                                    onChange={() => {
                                        setIsTaxable(!isTaxable)
                                    }}
                                    required />
                                <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'IS_TAXABLE', null, false, true)}</label>
                            </div>
                            {isTaxable === true ? (
                                <CommonInputHalf
                                    type="number"
                                    className="form-control p-input-text"
                                    id="name"
                                    placeholder={getContentLanguage(selectedLanguage, null, 'TAX_PERCENTAGE', null, false, true)}
                                    value={tax}
                                    onChange={taxHandle}
                                />) : null}
                        </CommonInputRow>
                    </div>}
                </CommonFormBorder>
                <CommonFormBorder>
                    <CommonInputRow>
                        <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'OPTION_DETAILS', 'PRODUCT')} />
                        <div className="ap-add-icon-container" onClick={() => { setOptionModalShow(true) }}>
                            <img src={require('../../../assets/plus.png')} alt="plus" className='img' />
                        </div>
                    </CommonInputRow>
                    {!isEmpty(optionList) && optionList.map((element, index) => (
                        <div className="ap-option-list">
                            <CommonInputRow>
                                <div className="ep-option-with-icon-container">
                                    {/* <div className="ap-option-item-name">{index + 1}. {element.title}</div> */}
                                    <div className="ap-option-item-name">{index + 1}. {getOptionLabelLocalText(selectedLanguage, element)}</div>
                                    <div className="ep-remove-image-container" onClick={() => { removeCompleteOption(element, APP_CONSTANTS.KEY_OPTION) }}>
                                        <img src={require('../../../assets/ic_remove_circle.png')} alt="remove" className="img" />
                                    </div>
                                </div>
                                <div className="ap-add-icon-container" onClick={() => {
                                    setSelectedOption(element)
                                    setOptionTypeModalShow(true)
                                }}>
                                    <img src={require('../../../assets/plus.png')} alt="plus" className='img' />
                                </div>
                            </CommonInputRow>
                            {!isEmpty(element.items) && <div className="ap-option-item-container">
                                {element.items.map(e => (
                                    <div className="ap-option-item">
                                        <div className="ap-option-item-default-container">{e.isDefault === true ? <span className="ap-option-item-default">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'DEFAULT', 'PRODUCT')}</span> : <span className="ap-option-item-default"> &nbsp;</span>}</div>
                                        {/* <div className="ap-option-item-name">{e.name}</div> */}
                                        <div className="ap-option-item-name">{getOptionItemLabelLocalText(selectedLanguage, e)}</div>
                                        <div className="ap-option-item-description">{!isEmpty(e.description) ? <span>{e.description}</span> : <span>&nbsp;</span>}</div>
                                        <div className="ap-option-item-description">{e.isVeg === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEG', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isNonVeg === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NON_VEG', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isVegan === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGAN', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-price">{e.price === 0 ? 'Free' : `Price ${e.price}`}</div>
                                        <div className="ep-item-remove-container" onClick={() => {
                                            removeSelectedItem(e, element, APP_CONSTANTS.KEY_OPTION)
                                        }}>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'REMOVE', 'PRODUCT')}</div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </CommonFormBorder>
                <CommonFormBorder>
                    <CommonInputRow>
                        <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_ON_DETAILS', 'PRODUCT')} />
                        <div className="ap-add-icon-container" onClick={() => { setAddOnModalShow(true) }}>
                            <img src={require('../../../assets/plus.png')} alt="plus" className='img' />
                        </div>
                    </CommonInputRow>
                    {!isEmpty(addOnList) && addOnList.map((element, index) => (
                        <div className="ap-option-list">
                            <CommonInputRow>
                                <div className="ep-option-with-icon-container">
                                    {/* <div className="ap-option-item-name">{index + 1}. {element.title}</div> */}
                                    <div className="ap-option-item-name">{index + 1}. {getOptionLabelLocalText(selectedLanguage, element)}</div>
                                    <div className="ep-remove-image-container" onClick={() => { removeCompleteOption(element, APP_CONSTANTS.KEY_ADD_ON) }}>
                                        <img src={require('../../../assets/ic_remove_circle.png')} alt="remove" className="img" />
                                    </div>
                                </div>
                                <div className="ap-add-icon-container" onClick={() => {
                                    setSelectedAddOn(element)
                                    setAddOnTypeModalShow(true)
                                }}>
                                    <img src={require('../../../assets/plus.png')} alt="plus" className='img' />
                                </div>
                            </CommonInputRow>
                            {!isEmpty(element.items) && <div className="ap-option-item-container">
                                {element.items.map(e => (
                                    <div className="ap-option-item">
                                        {/* <div className="ap-option-item-name">{e.name}</div> */}
                                        <div className="ap-option-item-name">{getOptionItemLabelLocalText(selectedLanguage, e)}</div>
                                        <div className="ap-option-item-description">{!isEmpty(e.description) ? <span>{e.description}</span> : <span>&nbsp;</span>}</div>
                                        <div className="ap-option-item-description">{e.isVeg === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEG', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isNonVeg === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NON_VEG', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-description">{e.isVegan === true ? <span>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGAN', 'PRODUCT')}</span> : null}</div>
                                        <div className="ap-option-item-price">{e.price === 0 ? 'Free' : `Price ${e.price}`}</div>
                                        <div className="ep-item-remove-container" onClick={() => {
                                            removeSelectedItem(e, element, APP_CONSTANTS.KEY_ADD_ON)
                                        }}>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'REMOVE', 'PRODUCT')}</div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </CommonFormBorder>
                <CommonFormBorder>
                    <FormFieldLabel className='label-bold' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'OTHER_INFORMATION', 'PRODUCT')} />
                    <CommonInputRow>
                        <div className="p-select-container-full me-2">
                            <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_CATEGORY', 'PRODUCT')} />
                            {!isEmpty(categoryList) && <Select
                                className='p-select'
                                value={selectedCat}
                                getOptionLabel={e => getCategoryLocalText(selectedLanguage, e)}
                                getOptionValue={e => e}
                                options={categoryList}
                                placeholder={getContentLanguage(selectedLanguage, null, 'SELECT_CATEGORY', null, false, true)}
                                onChange={handleSelectedCatChange} />}
                            {isEmpty(categoryList) && <div className="p-text">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NO_CATEGORY_FOUND', 'PRODUCT')}</div>}
                        </div>
                        <div className="ap-cat-or-label">or</div>
                        <button type="button" className="btn p-button-small" onClick={() => setShowModal(true)}>{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_CATEGORY', 'PRODUCT')}</button>
                    </CommonInputRow>
                    <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_AVAILABILITY', 'PRODUCT')} />
                    <div className="p-input-row p-input-row-wrap">
                        <div className="p-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isBreakfast}
                                value={isBreakfast}
                                onChange={() => {
                                    setIsBreakfast(!isBreakfast)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'BREAKFAST', null, false, true)}</label>
                        </div>
                        <div className="p-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isLunch}
                                value={isLunch}
                                onChange={() => {
                                    setIsLunch(!isLunch)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'LUNCH', null, false, true)}</label>
                        </div>
                        <div className="p-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={isDinner}
                                value={isDinner}
                                onChange={() => {
                                    setIsDinner(!isDinner)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'DINNER', null, false, true)}</label>
                        </div>
                        <div className="p-check-box">
                            <input
                                type="checkbox"
                                className="form-check-input p-input-text"
                                id="name"
                                checked={is24Hour}
                                value={is24Hour}
                                onChange={() => {
                                    setIs24Hour(!is24Hour)
                                }}
                                required />
                            <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'ALL_DAY', null, false, true)}</label>
                        </div>
                    </div>
                    <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_OPTION_TYPE', 'PRODUCT')} />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={itemVeg} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={itemNonVeg} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NON_VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={itemVegan} onChange={e => handleItemTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGAN', 'PRODUCT')}</span>
                        </label>
                    </div>
                    <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_PRODUCT_IMAGE', 'PRODUCT')} />
                    <div className="input-group">
                        <input type="file" ref={productImageRef} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload Image" onChange={handleImageChange} />
                    </div>
                </CommonFormBorder>
                <ButtonRow>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => submitHandler()} />
                </ButtonRow>
            </CommonScreenContent>
            <Modal
                onHide={() => { setShowModal(!showModal) }}
                show={showModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_CATEGORY', 'PRODUCT')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFieldLabel label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'FILL_DETAILS_TO_ADD_CATEGORY', 'PRODUCT')} />
                    <CommonInputFull type='text'
                        id='categoryName'
                        placeholder={getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME', null, false, true)}
                        value={categoryName}
                        onChange={e => setCategoryName(e.target.value)} />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull type='text'
                        id='categoryNameArabic'
                        placeholder={getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME_ARABIC', null, false, true)}
                        value={categoryNameArabic}
                        onChange={e => setCategoryNameArabic(e.target.value)}
                        language="ar" />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull type='text'
                        id='categoryNameUrdu'
                        placeholder={getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME_URDU', null, false, true)}
                        value={categoryNameUrdu}
                        onChange={e => setCategoryNameUrdu(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull type='text'
                        id='categoryNameSpanish'
                        placeholder={getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME_SPANISH', null, false, true)}
                        value={categoryNameSpanish}
                        onChange={e => setCategoryNameSpanish(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull type='text'
                        id='categoryNameFrench'
                        placeholder={getContentLanguage(selectedLanguage, null, 'CATEGORY_NAME_FRENCH', null, false, true)}
                        value={categoryNameFrench}
                        onChange={e => setCategoryNameFrench(e.target.value)} />}
                    <FormFieldLabel label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_IMAGE', 'PRODUCT')} />
                    <CommonInputFull type='text'
                        id='categoryName'
                        placeholder={getContentLanguage(selectedLanguage, null, 'SPECIAL_REMARKS', null, false, true)}
                        value={categoryRemarks}
                        onChange={e => setCategoryRemarks(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => { addCategoryHandle() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setOptionModalShow(!optionModalShow) }}
                show={optionModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_OPTION_TYPE', 'PRODUCT')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='optionLabel'
                        placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_LABEL', null, false, true)}
                        value={optionLabel}
                        onChange={e => setOptionLabel(e.target.value)} />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull type='text'
                        id='optionLabelArabic'
                        placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_LABEL_ARABIC', null, false, true)}
                        value={optionLabelArabic}
                        onChange={e => setOptionLabelArabic(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull type='text'
                        id='optionLabelUrdu'
                        placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_LABEL_URDU', null, false, true)}
                        value={optionLabelUrdu}
                        onChange={e => setOptionLabelUrdu(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull type='text'
                        id='optionLabelSpanish'
                        placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_LABEL_SPANISH', null, false, true)}
                        value={optionLabelSpanish}
                        onChange={e => setOptionLabelSpanish(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull type='text'
                        id='optionLabelFrench'
                        placeholder={getContentLanguage(selectedLanguage, null, 'OPTION_LABEL_FRENCH', null, false, true)}
                        value={optionLabelFrench}
                        onChange={e => setOptionLabelFrench(e.target.value)} />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => { handleAddOptionClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setOptionTypeModalShow(!optionTypeModalShow) }}
                show={optionTypeModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_OPTION', 'PRODUCT')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-check-box">
                        <input
                            type="checkbox"
                            className="form-check-input p-input-text"
                            id="name"
                            checked={optionTypeDefault}
                            value={optionTypeDefault}
                            onChange={() => {
                                setOptionTypeDefault(!optionTypeDefault)
                            }}
                            required />
                        <label for="name" className="p-checkbox-label">{getContentLanguage(selectedLanguage, null, 'IS_DEFAULT', null, false, true)}</label>
                    </div>
                    <CommonInputFull type='text'
                        id='optionName'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME', null, false, true)}
                        value={optionTypeName}
                        onChange={e => setOptionTypeName(e.target.value)} />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull type='text'
                        id='optionNameArabic'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_ARABIC', null, false, true)}
                        value={optionTypeNameArabic}
                        onChange={e => setOptionTypeNameArabic(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull type='text'
                        id='optionNameUrdu'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_URDU', null, false, true)}
                        value={optionTypeNameUrdu}
                        onChange={e => setOptionTypeNameUrdu(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull type='text'
                        id='optionNameSpanish'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_SPANISH', null, false, true)}
                        value={optionTypeNameSpanish}
                        onChange={e => setOptionTypeNameSpanish(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull type='text'
                        id='optionNameFrench'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_FRENCH', null, false, true)}
                        value={optionTypeNameFrench}
                        onChange={e => setOptionTypeNameFrench(e.target.value)} />}
                    <CommonInputFull type='text'
                        id='optionDescription'
                        placeholder={getContentLanguage(selectedLanguage, null, 'DESCRIPTION', null, false, true)}
                        value={optionTypeDescription}
                        onChange={e => setOptionTypeDescription(e.target.value)} />
                    <CommonInputFull type='number'
                        id='optionPrice'
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRICE', null, false, true)}
                        value={optionTypePrice}
                        onChange={e => setOptionTypePrice(e.target.value)} />
                    <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_OPTION_TYPE', 'PRODUCT')} />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={optionTypeVeg} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={optionTypeNonVeg} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NON_VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={optionTypeVegan} onChange={e => handleOptionTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGAN', 'PRODUCT')}</span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => { handleAddOptionDetailClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setAddOnModalShow(!addOnModalShow) }}
                show={addOnModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_ADD_ON_TYPE', 'PRODUCT')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='addOnLabel'
                        placeholder={getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL', null, false, true)}
                        value={addOnLabel}
                        onChange={e => setAddOnLabel(e.target.value)} />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull type='text'
                        id='addOnLabelArabic'
                        placeholder={getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL_ARABIC', null, false, true)}
                        value={addOnLabelArabic}
                        onChange={e => setAddOnLabelArabic(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull type='text'
                        id='addOnLabelUrdu'
                        placeholder={getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL_URDU', null, false, true)}
                        value={addOnLabelUrdu}
                        onChange={e => setAddOnLabelUrdu(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull type='text'
                        id='addOnLabelSpanish'
                        placeholder={getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL_SPANISH', null, false, true)}
                        value={addOnLabelSpanish}
                        onChange={e => setAddOnLabelSpanish(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull type='text'
                        id='addOnLabelFrench'
                        placeholder={getContentLanguage(selectedLanguage, null, 'ADD_ON_LABEL_FRENCH', null, false, true)}
                        value={addOnLabelFrench}
                        onChange={e => setAddOnLabelFrench(e.target.value)} />}
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => { handleAddAddOnClick() }} />
                </Modal.Footer>
            </Modal>
            <Modal
                onHide={() => { setAddOnTypeModalShow(!addOnTypeModalShow) }}
                show={addOnTypeModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'ADD_ADD_ON_ITEM', 'PRODUCT')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommonInputFull type='text'
                        id='addOnName'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME', null, false, true)}
                        value={addOnTypeName}
                        onChange={e => setAddOnTypeName(e.target.value)} />
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.ARABIC && <CommonInputFull type='text'
                        id='addOnNameArabic'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_ARABIC', null, false, true)}
                        value={addOnTypeNameArabic}
                        onChange={e => setAddOnTypeNameArabic(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.URDU && <CommonInputFull type='text'
                        id='addOnNameUrdu'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_URDU', null, false, true)}
                        value={addOnTypeNameUrdu}
                        onChange={e => setAddOnTypeNameUrdu(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.SPANISH && <CommonInputFull type='text'
                        id='addOnNameSpanish'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_SPANISH', null, false, true)}
                        value={addOnTypeNameSpanish}
                        onChange={e => setAddOnTypeNameSpanish(e.target.value)} />}
                    {selectedLanguage === APP_CONSTANTS.LANGUAGE_MAP.FRENCH && <CommonInputFull type='text'
                        id='addOnNameFrench'
                        placeholder={getContentLanguage(selectedLanguage, null, 'NAME_FRENCH', null, false, true)}
                        value={addOnTypeNameFrench}
                        onChange={e => setAddOnTypeNameFrench(e.target.value)} />}
                    <CommonInputFull type='text'
                        id='addOnDescription'
                        placeholder={getContentLanguage(selectedLanguage, null, 'DESCRIPTION', null, false, true)}
                        value={addOnTypeDescription}
                        onChange={e => setAddOnTypeDescription(e.target.value)} />
                    <CommonInputFull type='number'
                        id='addOnPrice'
                        placeholder={getContentLanguage(selectedLanguage, null, 'PRICE', null, false, true)}
                        value={addOnTypePrice}
                        onChange={e => setAddOnTypePrice(e.target.value)} />
                    <FormFieldLabel className='align-left' label={getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'SELECT_OPTION_TYPE', 'PRODUCT')} />
                    <div className="ap-option-item-container">
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEG} checked={addOnTypeVeg} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.NON_VEG} checked={addOnTypeNonVeg} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'NON_VEGETERIAN', 'PRODUCT')}</span>
                        </label>
                        <label className="ap-choice-label-container">
                            <input type='radio' value={APP_CONSTANTS.ITEM_TYPE.VEGAN} checked={addOnTypeVegan} onChange={e => handleAddOnTypeChoiceChange(e.target.value)} />
                            <span className="ap-choice-label">{getContentLanguage(selectedLanguage, 'ADD_PRODUCT', 'VEGAN', 'PRODUCT')}</span>
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton label={getContentLanguage(selectedLanguage, null, 'SUBMIT', null, true)}
                        onClick={() => { handleAddAddOnDetailClick() }} />
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            <Loader active={isLoading} text={getContentLanguage(selectedLanguage, null, 'PLEASE_WAIT', null, true)} />
        </>
    )
}

export default AddProduct